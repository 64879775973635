import ingredientsTypes from './ingredients.types';
import ingredientTypes from './ingredients.types';

const INITIAL_STATE = {
  ingredients: [],
  ingredient: {},
};

const ingredientsReducer = (state=INITIAL_STATE, action) => {
  //console.log(action.payload);
  switch(action.type) {
    case ingredientsTypes.FETCH_INGREDIENT_START:
      return {
        ...state,
        ingredient: action.payload
        
      }
    case ingredientsTypes.FETCH_INGREDIENTS_START:
      //console.log(ingredientsTypes.FETCH_INGREDIENTS_START)
      return {
        ...state,
        ingredients: action.payload
      }
    case ingredientsTypes.SET_INGREDIENTS:
      return {
        ...state,
        ingredients: action.payload
        
      }
    default:
      return state;
  }
};



export default ingredientsReducer;