import React from 'react';
import { styled, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Card, CardContent, Typography, CardMedia } from'@material-ui/core';
import './styles.scss';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  }
});

export default function Cards(props) {
  const classes = useStyles();
  return (
      <Grid container item xs={12} spacing={ 3 }>
        {props.data.map((arrItem, index) => (
          <Grid style={{ padding: '0.5rem' }} item xs={6} sm={4} key={index}>
            <Card>
              <CardContent>
              {/* <Item> */}
              <CardMedia
                className={classes.media}
                image={arrItem.imgLoc}
                title={arrItem.imgTitle}
                style={{ backgroundSize: 'contain' }}
              />
                <Typography gutterBottom variant="h5" component="h2">
                  {arrItem.header}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {arrItem.contents}
                </Typography>
              {/* </Item> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
  );
}