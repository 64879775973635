import axios from 'axios';

export const checkUserIsAdmin = currentUser => {
  if (!currentUser || !Array.isArray(currentUser.userRoles)) return false;
  const { userRoles } = currentUser;
  if (userRoles.includes('admin')) return true;

  return false;
}

export const apiInstance = axios.create({
  //test
  //baseURL: 'http://localhost:5001/mybrewmate/us-central1/api'
  //prod
  baseURL: 'https://us-central1-mybrewmate.cloudfunctions.net/api'
});