import { auth } from './../../firebase/utils';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setIngredients, fetchIngredientsStart } from './ingredients.actions';
import { handleFetchIngredients } from './ingredients.helpers';
import ingredientsTypes from './ingredients.types';

export function* fetchIngredients({ payload }) {
  
  //const ingredients = yield handleFetchIngredients(payload);
  //console.log(ingredients);
  try {
    
    const ingredients = yield handleFetchIngredients(payload);
    
    yield put(
      setIngredients(ingredients)
    );
    
  } catch (err) {
     console.log(err);
  }
}

export function* onFetchIngredientsStart() {
  //console.log(ingredientsTypes.FETCH_INGREDIENTS_START);
  yield takeLatest(ingredientsTypes.FETCH_INGREDIENTS_START, fetchIngredients);
}

export function* deleteIngredient({ payload }) {
  try {
    //yield handleDeleteProduct(payload);
    yield put (
      fetchIngredientsStart()
    );

  } catch (err) {
     console.log(err);
  }
}

export default function* ingredientsSagas() {
  yield all([
    call(onFetchIngredientsStart),
    //call(deleteIngredient),
  ])
}