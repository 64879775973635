import React from 'react';
import Paper from '@material-ui/core/Paper';
import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paperFullHeight: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
    height: "100%",
  }
}));

const AuthWrapper = ({ headline, children }) => {
  const classes = useStyles();
  return (
    <div className="authWrapper">
      <Paper className={classes.paperFullHeight}>
        <div className="wrap">
          {headline && <h2>{headline}</h2>}

          <div className="children">
            {children && children}
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default AuthWrapper;