import ingredientsTypes from './ingredients.types';


export const fetchIngredientsStart = (filters={}) => ({
  type: ingredientsTypes.FETCH_INGREDIENTS_START,
  payload: filters
});

export const setIngredients = ingredients => ({
  type: ingredientsTypes.SET_INGREDIENTS,
  payload: ingredients
});
