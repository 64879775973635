import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addProductStart,fetchProductStart, setProduct } from './../../redux/Products/products.actions';
import { addProduct } from './../../redux/Cart/cart.actions';
import './styles.scss';

import ImageUploader from '../ImageUploader/ImageUploader';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import grind from '../../assets/grind.jpg';
import beanfull from '../../assets/beanfull.jpg';
import beanempty from '../../assets/beanempty.jpg';
import driptower from '../../assets/driptower.jpg';
import coldpour from '../../assets/coldpour.jpg';
import toddy from '../../assets/toddy.jpg';
import pourover from '../../assets/pourover.jpeg';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const mapState = state => ({
    product: state.productsData.product
  });

const baseState = {
    productThumbnail: [],
    productId: "",
    productName: "My Home Brew",
    productPrice: {
        brewing: 0.00,
        packaging: 0.00,
        postage: 19.00,
        extras: 0.00,
        total: 19.00
    },
    productCategory: "coffee",
    productRecipe: {
        batchsize: 0,
        grind: [
            {
                blend: '',
                qty: 0,
                roast: 0,
                strength: 0,
                description: '',
                price: 0
            }
        ],
        brew: '',
        flavouring: [
            {
                strength: '',
                flavour: ''
            }
        ],
        nitro: false,
        packaging: 'no label'
    }
}

export const Coffee = props => {
    const history = useHistory();
    const historicalOrder = history.location.state?.order
    const dispatch = useDispatch()
    const { productID } = useParams();
    const { product } = useSelector(mapState);
    const [recipe, setRecipe] = useState(productID ? product.productRecipe : historicalOrder ? historicalOrder.productRecipe : baseState.productRecipe);
    const [productName, setProductName] = useState(productID ? product.productName : historicalOrder ? historicalOrder.productName : baseState.productName);
    const [customId, setCustomId] = useState(productID ? product.productId : historicalOrder?.productId ? historicalOrder.productId : baseState.productId);
    const [productPrice, setProductPrice] = useState(productID ? product.productPrice : historicalOrder ? historicalOrder.productPrice : baseState.productPrice);
    const [productImage, setProductImage] = useState(productID ? product.productThumbnail : historicalOrder ? historicalOrder.productThumbnail : baseState.productThumbnail);
    const [strength, setStrength] = useState(0);
    const [roast, setRoast] = useState(0);

    //add product items
    //add produc items finish

    useEffect(() => {
        if (productID){
            dispatch(
                fetchProductStart(productID)
            )

            return () => {
                dispatch(
                setProduct({})
                )
            }
        }
    }, []);

    const [hasError, setHasError] = useState({
        batchSize: false,
        blend: false
    })

    const handleSubmit = async e => {
        e.preventDefault();
        var blendError = false
        if (!props.createReq){
            await new Promise(resolve => {
                for (let i = 0; i < recipe.grind.length; ++i){
                    if (recipe.grind[i].qty === 0){
                        blendError = true
                        resolve("error")
                    }
                    if (i + 1 === recipe.grind.length){
                        resolve("done")
                    }
                }
            })
            if (recipe.batchsize === 0 || hasError.batchSize || hasError.blend || blendError){
                if (recipe.batchsize === 0 || blendError){
                    setHasError(prevState => {
                        return ({
                            batchSize: recipe.batchsize === 0 ? true : prevState.batchSize,
                            blend: blendError ? true : prevState.blend
                        })
                    })
                }
            } else {
                const packedProd = {
                    productThumbnail: productImage,
                    productId: productID ?? "",
                    productName: productName,
                    productPrice: productPrice,
                    productRecipe: recipe,
                    productCategory: "coffee"
                };
                history.push({
                    pathname: '/create/review',
                    state: {order: packedProd}
                })
            }
        }
        if (props.createReq){
            if (recipe.batchsize === 0 || hasError.batchSize || hasError.blend || blendError){
                if (recipe.batchsize === 0 || blendError){
                    setHasError(prevState => {
                        return ({
                            batchSize: recipe.batchsize === 0 ? true : prevState.batchSize,
                            blend: blendError ? true : prevState.blend
                        })
                    })
                }
            } else {
                const packedProd = {
                    productThumbnail: productImage,
                    productId: customId,
                    productName: productName,
                    productPrice: productPrice,
                    productRecipe: recipe,
                    productCategory: "coffee"
                };
                dispatch(
                    addProductStart(packedProd)
                );
                props.setHideModal(true)
            }
        }
    }
    
    const updateBeanVals = (grind) => {
        const len = grind.filter(obj => obj.blend.length > 0).length
        const strength = grind.reduce((accum,item) => accum + item.strength, 0);
        const roast = grind.reduce((accum,item) => accum + item.roast, 0);
        // const grindQtyTtl = grind.reduce((accum,item) => accum + item.qty, 0);
        // const adjustedGrindTtl = recipe.batchsize/parseInt((Math.ceil((((1 / 0.75) * grindQtyTtl) / 8) / 0.05) * 0.05).toFixed(2))
        setStrength(parseInt(strength / (len > 0 ? len : 1)))
        setRoast(parseInt(roast / (len > 0 ? len : 1)))
    }
    const removeCoffeeGrind = (index) => {
        setProductPrice(prevState => {
            const subtract = recipe.grind[index].price * (Math.ceil(Number(recipe.grind[index].qty) * 1000) / 1000)
            const brewing = prevState.brewing - subtract
            const total = brewing + prevState.postage
            return {
                ...prevState,
                brewing,
                total
            }
        })
        setRecipe(prevState => {
            var grind = [...prevState.grind]
            grind.splice(index,1)
            updateBeanVals(grind)
            return {
                ...prevState,
                grind
            };
        })
    }
    const updateCoffeeChoice = ({event, index}) => {
        const blend = props
            .data
            .filter(item => {
                return item.name === event.target.value
            })
        setProductPrice(prevState => {
            const subtract = recipe.grind[index].price * (Math.ceil(Number(recipe.grind[index].qty) * 1000) / 1000)
            const brewing = (prevState.brewing - subtract) + (blend[0].price * (Math.ceil(Number(recipe.grind[index].qty) * 1000) / 1000))
            const total = brewing + prevState.postage
            return {
                ...prevState,
                brewing,
                total
            }
        })
        setRecipe(prevState => {
            const grind = [...prevState.grind];
            grind[index] = {
                ...grind[index],
                blend: event.target.value,
                strength: blend[0].strength,
                roast: blend[0].roast,
                description: `${blend[0].description}`,
                price: blend[0].price
            };
            updateBeanVals(grind)
            return {
                ...prevState,
                grind
            };
        })
    }
    const removeCoffeeFlavour = (index) => {
        setRecipe(prevState => {
            var flavouring = [...prevState.flavouring]
            flavouring.splice(index,1)
            return {
                ...prevState,
                flavouring
            };
        })
    }
    const updateFlavourChoice = ({event, index}) => {
        setRecipe(prevState => {
            const flavouring = [...prevState.flavouring];
            flavouring[index] = {
                ...flavouring[index],
                flavour: event.target.value,
            };
            return {
                ...prevState,
                flavouring
            };
        })
    }
    const updateFlavourStrength = ({event, index}) => {
        setRecipe(prevState => {
            const flavouring = [...prevState.flavouring];
            flavouring[index] = {
                ...flavouring[index],
                strength: event.target.value,
            };
            return {
                ...prevState,
                flavouring
            };
        })
    }
    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        style={{
                        width: "100%"
                    }}
                        id='brewname'
                        label='Brew Name'
                        variant="outlined"
                        value={productName}
                        onChange={e => {
                            setProductName(e.target.value)
                        }}
                    />
                    {props.createReq && <TextField
                        style={{
                        width: "100%"
                    }}
                        id='customId'
                        label='Custom ID'
                        variant="outlined"
                        value={customId}
                        onChange={e => {
                            setCustomId(e.target.value)
                        }}
                    />}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl
                        error={hasError.batchSize}
                        variant="outlined"
                        className={props.classes.formControl}
                        style={{
                        width: "100%"
                    }}>
                        <InputLabel id='label_for_batch_size'>Batch Size</InputLabel>
                        <Select
                            labelId='label_for_batch_size'
                            id="batchsize"
                            value={recipe.batchsize}
                            onChange={e => {
                            setHasError(prevState => {
                                return {
                                    batchSize: false,
                                    blend: true
                                }
                            })
                            setProductPrice(prevState => {
                                return {
                                    brewing: 0.00,
                                    packaging: 0.00,
                                    postage: 19.00,
                                    total: 19.00
                                }
                            })
                            setRecipe(prevState => ({
                                ...prevState,
                                batchsize: e.target.value,
                                grind: [
                                    {
                                        blend: '',
                                        qty: (Math.floor((Math.ceil((((1 / 0.75) * e.target.value) / 9) / 0.05) * 0.05) * 100) / 100),
                                        price: 0.00
                                    }
                                ]
                            }))
                        }}
                            label="Batch Size">
                            <MenuItem disabled key={0} value={0}>Please select a batch size</MenuItem>
                            <MenuItem key={1} value={3.96}>3.96L (12 cans)</MenuItem>
                            <MenuItem key={2} value={7.92}>7.92L (24 cans)</MenuItem>
                            <MenuItem key={3} value={18.15}>18.15L (55 cans)</MenuItem>
                        </Select>
                        {hasError.batchSize && <FormHelperText>Pleas select a batch size</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl
                        variant="outlined"
                        className={props.classes.formControl}
                        style={{
                        width: "100%"
                    }}>
                        <InputLabel id='label_for_packaging'>Packaging Style</InputLabel>
                        <Select
                            labelId='label_for_packaging'
                            id="packaging"
                            value={recipe.packaging}
                            onChange={e => {
                            setRecipe(prevState => ({
                                ...prevState,
                                packaging: e.target.value
                            }))
                        }}
                            label="Packaging Style">
                            <MenuItem key={1} value={"personalised label"}>Canned with personalised label</MenuItem>
                            <MenuItem key={2} value={"no label"}>Canned with no label</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Paper className={props.classes.paperFullHeight}>
                        <Typography id='recipedescription' variant="body1" component="p">
                            This is where you can build your own cold brew recipe. Use the cards below to
                            enter your choices and then submit for brewing. It's that simple. Start by
                            selecting the batch size from the drop down to the right; this will populate
                            other fields based on a coffee ratio of 1:9 (coffee:starting water vol). To make it stronger or weaker
                            simply increase or decrease the coffee quantity.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid container item xs={12} sm={4}>
                    <Grid item xs={6}>
                        <Typography
                            style={{
                            textAlign: 'center'
                        }}
                            variant="h2"
                            component="h1"
                            gutterBottom>Strength</Typography>
                        <div style={{ margin: "0 0 1rem 0",
                            textAlign: "center",
                            borderRadius: "inherit", 
                            display: "inline-flex" }}>
                            <div style={{ marginLeft: "auto",
                                marginRight: "auto",
                                display: "inline-flex",
                                maxWidth: "80%" }}>
                                {Array.from(Array(strength), (e, i) => {
                                    return <img key={`full strength ${i}`} style={{ margin: "1rem 0.25rem",
                                        overflow: "hidden",
                                        maxWidth: "100%",
                                        maxHeight: "12rem",
                                        objectFit: "contain", 
                                        display: "inline-flex" }} src={beanfull} alt="Coffee Bean"/>
                                })}
                                {Array.from(Array(5 - strength), (e, i) => {
                                    return <img key={`empty strength ${i}`} style={{ margin: "1rem 0.25rem",
                                        overflow: "hidden",
                                        maxWidth: "100%",
                                        maxHeight: "12rem",
                                        objectFit: "contain", 
                                        display: "inline-flex" }} src={beanempty} alt="Coffee Bean Outline"/>
                                })}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            style={{
                            textAlign: 'center'
                        }}
                            variant="h2"
                            component="h1"
                            gutterBottom>Roast</Typography>
                        <div style={{ margin: "0 0 1rem 0",
                            textAlign: "center",
                            borderRadius: "inherit", 
                            display: "inline-flex" }}>
                            <div style={{ marginLeft: "auto",
                                marginRight: "auto",
                                display: "inline-flex",
                                maxWidth: "80%" }}>
                                {Array.from(Array(roast), (e, i) => {
                                    return <img key={`full roast ${i}`} style={{ margin: "1rem 0.25rem",
                                        overflow: "hidden",
                                        maxWidth: "100%",
                                        maxHeight: "12rem",
                                        objectFit: "contain", 
                                        display: "inline-flex" }} src={beanfull} alt="Coffee Bean"/>
                                })}
                                {Array.from(Array(5 - roast), (e, i) => {
                                    return <img key={`empty roast ${i}`} style={{ margin: "1rem 0.25rem",
                                        overflow: "hidden",
                                        maxWidth: "100%",
                                        maxHeight: "12rem",
                                        objectFit: "contain", 
                                        display: "inline-flex" }} src={beanempty} alt="Coffee Bean Outline"/>
                                })}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{
                    position: "relative"
                }}>
                    {!props.createReq && <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={props.classes.submitBtn}>
                        Review & Brew
                    </Button>}
                    {props.createReq && <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={props.classes.submitBtn}>
                        Add Product
                    </Button>}
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Paper className={props.classes.paperFullHeight}>
                        <div className={props.classes.imageheader}><img src={grind} alt="Coffee grinder"/></div>
                        <Typography variant="h2" component="h1" gutterBottom>Grind</Typography>
                        {recipe
                            .grind
                            .map((blend, i) => (
                                <Box
                                    key={`Fragment Grind ${i}`}
                                    style={{
                                    margin: "1rem 0",
                                    padding: "0"
                                }}>
                                    {/* <InputLabel id='label_for_bean_qty'>Coffee Selection {i + 1}</InputLabel> */}
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        spacing={3}
                                        style={{
                                            padding: "0"
                                        }}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={10}
                                            lg={8}
                                            xl={6}
                                            style={{
                                                // margin: "0.5rem 0.5rem 0.5rem 0",
                                                marginBottom: "0.5rem",
                                                padding: "0"
                                            }}
                                        >
                                            <ButtonGroup
                                                disableFocusRipple
                                                disableRipple
                                                key={`btngroup${i}`}
                                                className={props.classes.buttonGroup}
                                                aria-label="small outlined button group">
                                                <Button
                                                    onClick={e => {
                                                    const newValue = (recipe.grind[i].qty * 1) + 0.25;
                                                    setHasError(prevState => {
                                                        return {
                                                            batchSize: prevState.batchSize,
                                                            blend: newValue > 0 && blend.blend.length > 0 ? false : true
                                                        }
                                                    })
                                                    setRecipe(prevState => {
                                                        const grind = [...prevState.grind];
                                                        grind[i] = {
                                                            ...grind[i],
                                                            qty: newValue.toFixed(3)
                                                        };
                                                        return {
                                                            ...prevState,
                                                            grind
                                                        };
                                                    })
                                                    setProductPrice(prevState => {
                                                        const subtract = recipe.grind[i].price * (Math.ceil(Number(recipe.grind[i].qty) * 1000) / 1000)
                                                        const brewing = (prevState.brewing - subtract) + (recipe.grind[i].price * (Math.ceil(Number(newValue) * 1000) / 1000))
                                                        const total = brewing + prevState.postage
                                                        return {
                                                            ...prevState,
                                                            brewing,
                                                            total
                                                        }
                                                    })
                                                }}>+</Button>
                                                <Button
                                                    style={{
                                                        cursor: "text",
                                                    }}
                                                    tabIndex={-1}><TextField
                                                    InputProps={{
                                                        inputMode: 'numeric',
                                            endAdornment: <InputAdornment position="end">kg</InputAdornment>
                                        }}
                                                    value={blend.qty}
                                                    style={{
                                            width: "inherit",
                                            border: "none"
                                        }}
                                                    onChange={e => {
                                            const caret = e.target.selectionStart
                                            const element = e.target
                                            window.requestAnimationFrame(() => {
                                                    element.selectionStart = caret
                                                    element.selectionEnd = caret
                                                })
                                                const newValue = e.target?.value ? e.target.value = '' || !e.target.value.match(/^\d+\.\d+$/) ? 0.00 : Number(e.target.value) : Number(recipe.grind[i].qty)
                                                setProductPrice(prevState => {
                                                    const subtract = recipe.grind[i].price * (Math.ceil(Number(recipe.grind[i].qty) * 1000) / 1000)
                                                    const brewing = (prevState.brewing - subtract) + (recipe.grind[i].price * (Math.ceil(newValue * 1000) / 1000))
                                                    const total = brewing + prevState.postage
                                                    return {
                                                        ...prevState,
                                                        brewing,
                                                        total
                                                    }
                                                })
                                                setHasError(prevState => {
                                                    return {
                                                        batchSize: prevState.batchSize,
                                                        blend: Number(newValue) > 0 && blend.blend.length > 0 ? false : true
                                                    }
                                                })
                                                setRecipe(prevState => {
                                                    const grind = [...prevState.grind];
                                                    grind[i] = {
                                                        ...grind[i],
                                                        qty: newValue.toFixed(3)
                                                    };
                                                    return {
                                                        ...prevState,
                                                        grind
                                                    };
                                                })
                                        }}/></Button>
                                                <Button
                                                    onClick={e => {
                                                    const newValue = ((recipe.grind[i].qty * 1) - 0.25) < 0
                                                        ? 0
                                                        : ((recipe.grind[i].qty * 1) - 0.25);
                                                        setHasError(prevState => {
                                                            return {
                                                                batchSize: prevState.batchSize,
                                                                blend: newValue > 0 && blend.blend.length > 0 ? false : true
                                                            }
                                                        })
                                                    setRecipe(prevState => {
                                                        const grind = [...prevState.grind];
                                                        grind[i] = {
                                                            ...grind[i],
                                                            qty: newValue.toFixed(3)
                                                        };
                                                        return {
                                                            ...prevState,
                                                            grind
                                                        };
                                                    })
                                                    setProductPrice(prevState => {
                                                        const subtract = recipe.grind[i].price * (Math.ceil(Number(recipe.grind[i].qty) * 1000) / 1000)
                                                        const brewing = (prevState.brewing - subtract) + (recipe.grind[i].price * (Math.ceil(Number(newValue) * 1000) / 1000))
                                                        const total = brewing + prevState.postage
                                                        return {
                                                            ...prevState,
                                                            brewing,
                                                            total
                                                        }
                                                    })
                                                }}>-</Button>
                                            </ButtonGroup>
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                marginBottom: "0.5rem",
                                                padding: "0"
                                            }}
                                        >
                                            <FormControl
                                                error={hasError.blend}
                                                key={`FormControl${i}`}
                                                variant="outlined"
                                                className={props.classes.formControl}
                                                style={{
                                                    margin: "0 0.5rem 0.5rem 0"
                                                }}>
                                                <InputLabel id='label_for_bean_choice'>Coffee</InputLabel>
                                                <Select
                                                    labelId='label_for_bean_choice'
                                                    key={`Select${i}`}
                                                    value={blend.blend}
                                                    onChange={e => {
                                                        setHasError(prevState => {
                                                            return {
                                                                batchSize: prevState.batchSize,
                                                                blend: Number(blend.qty) > 0 ? false : true
                                                            }
                                                        })
                                                        updateCoffeeChoice({event: e, index: i});
                                                    }}
                                                    label="Coffee Selection">
                                                    {props.data
                                                        ? (props.data.map((item, index) => {
                                                            if (item.store === 'coffee') 
                                                                return (
                                                                    <MenuItem key={index} value={item.name}>{item.origin}</MenuItem>
                                                                )
                                                        }))
                                                        : <MenuItem>No items found</MenuItem>}
                                                </Select>
                                                {hasError.blend && <FormHelperText>Please select coffee and enter qty</FormHelperText>}
                                            </FormControl>
                                            <IconButton style={{ margin: "1rem" }} aria-label="delete" onClick={e => {
                                                    removeCoffeeGrind(i)
                                                }}>
                                                <DeleteIcon fontSize="large"/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            margin: "0 0.5rem 0.5rem 0",
                                            padding: "0"
                                        }}
                                    >
                                        <Typography
                                            id={`coffeebatchdesc ${i}`}
                                            className={props.classes.carddescription}
                                            variant="body1"
                                            component="p">
                                            {blend.description}
                                        </Typography>
                                    </Grid>
                                    <Divider
                                        style={{
                                        margin: "1rem 0 2rem 0"
                                    }}/>
                                </Box>
                            ))}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={e => {
                                    setRecipe(prevState => {
                                        const grind = [...prevState.grind];
                                        grind.push({blend: '', qty: 0, strength: 0, roast: 0, description: '', price: 0.00});
                                        return {
                                            ...prevState,
                                            grind
                                        };
                                    })
                                }}>
                                +
                            </Button>
                        </Grid>
                        <Typography
                            id='coffeedescription'
                            className={props.classes.carddescription}
                            variant="body1"
                            component="p">
                            Brew Mates currently offers 3 coffee options. Guatemala blend, Colombia blend and single origin. 
                            Each bean offers subtle difference in flavour and balance; check the srength and roast readings at 
                            the top of the page with each selection.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper className={props.classes.paperFullHeight}>
                        <div className={props.classes.imageheader}><img src={recipe.brew === "Brew in Bag" ? toddy : recipe.brew === "Hot Brew" ? pourover : driptower} alt="Coffee brewer"/></div>
                        <Typography variant="h2" component="h1" gutterBottom>Brew</Typography>
                        <Box
                            key={`Fragment Brew`}
                            style={{
                            margin: "1rem 0",
                            padding: "0"
                        }}>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={3}
                                style={{
                                    padding: "0"
                                }}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        padding: "0"
                                    }}
                                >
                                    <FormControl
                                        variant="outlined"
                                        className={props.classes.formControl}
                                        style={{
                                        width: "100%"
                                    }}>
                                        <InputLabel id='label_for_brew_selection'>Brew Selection</InputLabel>
                                        <Select
                                            labelId='label_for_brew_selection'
                                            id="brewmethod"
                                            value={recipe.brew}
                                            onChange={e => {
                                                setRecipe(prevState => ({
                                                    ...prevState,
                                                    brew: e.target.value
                                                }))
                                            }}
                                            label="brew method">
                                            <MenuItem disabled key={0} value={0}>Please select a brew method</MenuItem>
                                            <MenuItem key={1} value="Drip Tower">Cold Brew Drip Tower</MenuItem>
                                            <MenuItem key={2} value="Brew in Bag">Toddy/Brew in Bag</MenuItem>
                                            {/* <MenuItem key={3} value="Hot Brew">Hot Pour Over</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography
                            id='brewdescription'
                            className={props.classes.carddescription}
                            variant="body1"
                            component="p">
                            {recipe.brew === "Drip Tower" && (
                                "Cold drip tower produces the cleanist & smoothest flavour of the 3 brewing options. The coffee is slowly filtered through a ceramic tile and paper filter before aerating and finally dripping into the glass beaker."
                            )}
                            {recipe.brew === "Brew in Bag" && (
                                "The Brew in Bag (BiB) method produces a strong flavoured almost syrupy brew. This method involves steeping the coffee grinds in cold water a refigerating for a minimum 12 hour period. At the end of steeping period the coffee is poured through a paper filter."
                            )}
                            {recipe.brew === "Hot Brew" && (
                                "Pour over produces the strongest flavour of the 3 options. Brewed with hot water to unlock the strong bold aromatic flavours of the coffee; then chilled fast to lock in the flavours"
                            )}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Paper className={props.classes.paperFullHeight}>
                        <div className={props.classes.imageheader}><img src={coldpour} alt="Cold pour coffee"/></div>
                        <Typography variant="h2" component="h1" gutterBottom>Flavouring & Nitro</Typography>
                        {recipe
                            .flavouring
                            .map((flav, i) => (
                                <Box
                                    key={`Fragment Flavour ${i}`}
                                    style={{
                                    margin: "1rem 0",
                                    padding: "0"
                                }}>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        spacing={3}
                                        >
                                        <Grid
                                            item
                                            style={{
                                                marginRight: "1rem",
                                                marginBottom: "0.5rem",
                                                padding: "0"
                                            }}
                                        >
                                            <FormControl
                                                key={`FormControl${i}`}
                                                variant="outlined"
                                                className={props.classes.formControl}
                                            >
                                                <InputLabel id='label_for_flav_strength'>Strength</InputLabel>
                                                <Select
                                                    labelId='label_for_flav_strength'
                                                    key={`Select${i}`}
                                                    value={flav.strength}
                                                    onChange={e => {
                                                        updateFlavourStrength({event: e, index: i});
                                                    }}
                                                    label="Flavour Strength Selection">
                                                    <MenuItem key={0} value="Mild">Mild (1:10)</MenuItem>
                                                    <MenuItem key={1} value="Strong">Strong (1:6)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                marginRight: "1rem",
                                                marginBottom: "0.5rem",
                                                padding: "0"
                                            }}
                                        >
                                            <FormControl
                                                key={`FormControl${i}`}
                                                variant="outlined"
                                                className={props.classes.formControl}
                                            >
                                                <InputLabel id='label_for_coffee_flavour'>Flavour</InputLabel>
                                                <Select
                                                    labelId='label_for_coffee_flavour'
                                                    key={`Select${i}`}
                                                    value={flav.flavour}
                                                    onChange={e => {
                                                        updateFlavourChoice({event: e, index: i});
                                                    }}
                                                    label="Flavour Selection">
                                                    {props.data
                                                        ? (props.data.map((item, index) => {
                                                            if (item.store === 'cofFlav'){
                                                                return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                            }
                                                        }))
                                                        : <MenuItem>No items found</MenuItem>}
                                                </Select>
                                            </FormControl>
                                            {/* <IconButton style={{ margin: "1rem" }} aria-label="delete" onClick={e => {
                                                    removeCoffeeFlavour(i)
                                                }}>
                                                <DeleteIcon fontSize="large"/>
                                            </IconButton> */}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        spacing={3}>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    style={{
                                                        transform: "scale(2)",
                                                        fontSize: "1.25rem"
                                                    }}
                                                    checked={recipe.nitro}
                                                    onChange={e => {
                                                        setRecipe(prevState => ({
                                                            ...prevState,
                                                            nitro: e.target.checked
                                                        }))
                                                    }}
                                                    name="Nitro"
                                                    color="primary"
                                                />
                                                }
                                                label="Nitro"
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* <Divider
                                        style={{
                                        margin: "1rem 0 2rem 0"
                                    }}/> */}
                                </Box>
                            ))}
                        {/* <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={e => {
                                    setRecipe(prevState => {
                                        const flavouring = [...prevState.flavouring];
                                        flavouring.push({strength: '', flavour: ''});
                                        return {
                                            ...prevState,
                                            flavouring
                                        };
                                    })
                                }}>
                                +
                            </Button>
                        </Grid> */}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3} style={{ marginLeft: "auto", marginRight: "auto" }}>
                <Grid item xs={12}>
                    <ImageUploader parentFunc={setProductImage} imgFiles={productImage}/>
                </Grid>
            </Grid>
        </form>
    );
}