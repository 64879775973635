import React from 'react';
import './styles.scss';
import EmailPassword from './../../components/EmailPassword';

const Recovery = props => (
  <section className='recoverypage'>
    <EmailPassword />
  </section>
)

export default Recovery;