import React from 'react';
import './styles.scss';

const Footer = props => {
  return (
    <footer className="footer">
      <div className="wrap">
        <div>
          Brewing your brew for you.
        </div>
      </div>
    </footer>
  );
}

export default Footer;