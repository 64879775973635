import React from 'react';
import './styles.scss';

const Errorpage = props => {
  return (
    <section className="errorpage">
      <h3>Oops... Looks like the keg's run dry. Use the menu to re-fill.</h3>
    </section>
  );
};

export default Errorpage;