import React, { Component } from 'react';
import Signup from './../../components/Signup';
import './styles.scss';

const Registration = props => (
  <section className='registerpage'>
    <Signup />
  </section>
);

export default Registration;
