import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductStart, setProduct } from '../../redux/Products/products.actions';
import { addProduct } from '../../redux/Cart/cart.actions';
import './styles.scss';

import ImageUploader from '../ImageUploader/ImageUploader';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { firebase } from "../../firebase/utils";

// uploading files to storage
export const uploadFromBlobAsync = async ({ blobUrl, name }) => {
  if (!blobUrl || !name) return null
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob())
      const snapshot = await firebase.storage().ref().child(name).put(blob)
      return await snapshot.ref.getDownloadURL()
    } catch (error) {
      throw error
    }
}

export const ReviewBrew = props => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [order, setOrder] = useState(history.location.state?.order ? history.location.state.order : undefined);
    const [productImage, setProductImage] = useState(order ? order.productThumbnail : []);

    const handleSubmit = e => {
        e.preventDefault();
        
        // for uploading to firebase
        async function sendFile() {
            try {
                await uploadFromBlobAsync({
                    blobUrl: productImage[0].blobUrl,
                    name: `${productImage[0].name}_${[
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                    ].join('_')}`,
                }).then((url) => {
                    const finalisedOrder = {
                        ...order,
                        productPrice: {
                            brewing: order.productPrice.brewing + canCost,
                            packaging: labelCost,
                            postage: 19.00,
                            extras: extrasCost + nitroCost,
                            total: order.productPrice.total + nitroCost + extrasCost + labelCost + canCost
                        },
                        productThumbnail: [{
                            blobUrl: url,
                            name: `${productImage[0].name}_${[
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate(),
                            ].join('_')}`,
                            }]
                    };
                    console.log(finalisedOrder)
                })
            } catch (e) {
            console.log(e)
            }
        }
        
        if (productImage.length === 0 || productImage[0]?.blobUrl?.startsWith("https://firebasestorage")) {
            console.log(order)
        } else {
            sendFile()
        }
    }

    if (!order){
        history.push('/create')
        return null
    }

    const nitroCost = !order.productRecipe.nitro || order.productId?.startsWith("MBM") ? 0.00 : Math.ceil(order.productRecipe.batchsize) * 2
    const extrasCost = order.productRecipe.flavouring[0].flavour.length > 0 ? Math.ceil(order.productRecipe.batchsize) * 0.20 : 0.00
    const labelCost = order.productRecipe.packaging === 'no label' || order.productId?.startsWith("MBM") ? 0.00 : (order.productRecipe.batchsize / 0.33) / 2
    const canCost = (order.productRecipe.batchsize / 0.33) / 2
    
    return (
        <>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Typography
                        style={{
                        width: "100%"
                    }}
                    variant="h1"
                    component="h1"
                    gutterBottom>
                        Order Summary
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={8}>
                    <Paper className={props.classes.paperFullHeight}>
                        <Box
                            style={{
                            margin: "1rem 0",
                            padding: "0"
                        }}>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={3}
                                >
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    style={{
                                        // marginRight: "1rem",
                                        // marginBottom: "0.5rem",
                                        padding: "0"
                                    }}
                                >
                                    {order.productCategory === 'coffee' &&
                                        <>
                                            <Typography variant="h2" component="h2" gutterBottom>Brewname</Typography>
                                            <Typography variant="body1" component="p" gutterBottom>{order.productName}</Typography>
                                            <Typography style={{marginTop: "2rem"}} variant="h2" component="h2" gutterBottom>Grind</Typography>
                                            {order.productRecipe.grind.map((grind, i) => (
                                                <Typography key={`grind${i}`} variant="body1" component="p" gutterBottom>{`${Math.ceil(Number(grind.qty) * 1000) / 1000}`}Kg: {grind.blend}</Typography>
                                            ))}
                                            <Typography style={{marginTop: "2rem"}} variant="h2" component="h2" gutterBottom>Brew</Typography>
                                            <Typography variant="body1" component="p" gutterBottom>{order.productRecipe.brew}</Typography>
                                            <Typography style={{marginTop: "2rem"}} variant="h2" component="h2" gutterBottom>Flavouring</Typography>
                                            {order.productRecipe.flavouring[0].flavour.length > 0 && order.productRecipe.flavouring.map((flav, i) => (
                                                <Typography key={`flav${i}`} variant="body1" component="p" gutterBottom>{flav.flavour}: {flav.strength}</Typography>
                                            ))}
                                            {order.productRecipe.nitro &&
                                                <Typography variant="body1" component="p" gutterBottom>Nitro</Typography>
                                            }
                                            <Typography style={{marginTop: "2rem"}} variant="h2" component="h2" gutterBottom>Packaging</Typography>
                                            <Typography variant="body1" component="p" gutterBottom>Canned with {order.productRecipe.packaging}</Typography>
                                        </>
                                    }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={e => {
                                            e.preventDefault();
                                            const packedProd = {
                                                ...order,
                                                productThumbnail: productImage
                                            };
                                            history.push({
                                                pathname: `/create/${order.productCategory}`,
                                                state: {order: packedProd}
                                            })
                                        }}
                                        style={{
                                            marginTop:'2rem!important'
                                        }}
                                        >
                                        Edit Brew
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={7}
                                    style={{
                                        // marginRight: "1rem",
                                        // marginBottom: "0.5rem",
                                        padding: "0"
                                    }}
                                >
                                    <Grid container item xs={12} style={{ marginLeft: "auto", marginRight: "auto" }}>
                                        <Grid item xs={12}>
                                            <ImageUploader parentFunc={setProductImage} imgFiles={productImage}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper className={props.classes.paperFullHeight}>
                    <Box
                            style={{
                            margin: "1rem 0",
                            padding: "0"
                        }}>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={3}
                                >
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        // marginRight: "1rem",
                                        // marginBottom: "0.5rem",
                                        padding: "0"
                                    }}
                                >
                                    {order.productCategory === 'coffee' &&
                                        <>
                                            <Typography variant="h2" component="h2" gutterBottom>Brewing</Typography>
                                            <Typography variant="body1" component="p" gutterBottom>{order.productRecipe.batchsize}L brew by (friendly) robots<Box component="span" style={{ float: "right", marginRight: "3rem", marginLeft: order.productPrice.brewing + canCost >= 100.00 ? "0.5rem" : order.productPrice.brewing + canCost >= 10 ? "1rem" : "1.5rem" }}>{`${((Math.ceil(order.productPrice.brewing * 1000) / 1000) + canCost).toFixed(2)}`}</Box><Box component="span" style={{ float: "right" }}>$</Box></Typography>
                                            <Typography style={{marginTop: "2rem"}} variant="h2" component="h2" gutterBottom>Packaging</Typography>
                                            <Typography variant="body1" component="p" gutterBottom>330ml can with {order.productRecipe.packaging}<Box component="span" style={{ float: "right", marginRight: "3rem", marginLeft: labelCost >= 100.00 ? "0.5rem" : labelCost >= 10 ? "1rem" : "1.5rem" }}>{`${(labelCost).toFixed(2)}`}</Box><Box component="span" style={{ float: "right" }}>$</Box></Typography>
                                            <Typography style={{marginTop: "2rem"}} variant="h2" component="h2" gutterBottom>Extras</Typography>
                                            {order.productRecipe.flavouring[0].flavour.length > 0 && order.productRecipe.flavouring.map((flav, i) => (
                                                i === order.productRecipe.flavouring.length - 1 ? <Typography key={`flav${i}`} variant="body1" component="p" gutterBottom>{flav.flavour}: {flav.strength}<Box component="span" style={{ float: "right", marginRight: "3rem", marginLeft: "1.5rem" }}>{`${extrasCost.toFixed(2)}`}</Box><Box component="span" style={{ float: "right" }}>$</Box></Typography>
                                                : <Typography key={`flav${i}`} variant="body1" component="p" gutterBottom>{flav.flavour}: {flav.strength}</Typography>
                                            ))}
                                            {order.productRecipe.nitro &&
                                                <Typography variant="body1" component="p" gutterBottom>Nitro<Box component="span" style={{ float: "right", marginRight: "3rem", marginLeft: nitroCost >= 100.00 ? "0.5rem" : nitroCost >= 10 ? "1rem" : "1.5rem" }}>{`${nitroCost.toFixed(2)}`}</Box><Box component="span" style={{ float: "right" }}>$</Box></Typography>
                                            }
                                            <Typography style={{marginTop: "2rem"}} variant="h2" component="h2" gutterBottom>Postage</Typography>
                                            <Typography variant="body1" component="p" gutterBottom>Standard Delivery (Flat Rate)<Box component="span" style={{ float: "right", marginRight: "3rem", marginLeft: "1rem" }}>{`${order.productPrice.postage.toFixed(2)}`}</Box><Box component="span" style={{ float: "right" }}>$</Box></Typography>
                                            <Divider />
                                            <Typography style={{marginTop: "2rem", fontWeight: "bold"}} variant="body1" component="p" gutterBottom>Total inc. GST<Box component="span" style={{ float: "right", marginRight: "3rem", marginLeft: (order.productPrice.total + nitroCost + extrasCost + labelCost + canCost) >= 100.00 ? "0.5rem" : (order.productPrice.total + nitroCost + extrasCost + labelCost + canCost) >= 10 ? "1rem" : "1.5rem" }}>{`${(Math.ceil((order.productPrice.total + nitroCost + extrasCost + labelCost + canCost) * 1000) / 1000).toFixed(2)}`}</Box><Box component="span" style={{ float: "right" }}>$</Box></Typography>
                                            <Divider />
                                        </>
                                    }
                                </Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={e => {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }}
                                    style={{
                                        width: "100%"
                                    }}
                                    >
                                    Brew It
                                </Button>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}