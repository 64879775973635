import { firestore } from './../../firebase/utils';

// export const handleAddProduct = product => {
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection('products')
//       .doc()
//       .set(product)
//       .then(() => {
//         resolve();
//       })
//       .catch(err => {
//         reject(err);
//       })
//   });
// }

export const handleFetchIngredients = ({ filterType, startAfterDoc, persistIngredients=[] }) => {
  return new Promise((resolve, reject) => {
    const pageSize = 100;

    //let ref = firestore.collection('ingredients').orderBy('createdDate').limit(pageSize);
    let ref = firestore.collection('ingredients');

    if (filterType) ref = ref.where('ingredient_type', '==', filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then(snapshot => {
        const totalCount = snapshot.size;

        const data = [
          ...persistIngredients,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

// export const handleDeleteProduct = documentID => {
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection('products')
//       .doc(documentID)
//       .delete()
//       .then(() => {
//         console.log(documentID, 2)
//         resolve();
//       })
//       .catch(err => {
//         reject(err);
//       })
//   });
// }

// export const handleFetchProduct = (productID) => {
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection('products')
//       .doc(productID)
//       .get()
//       .then(snapshot => {

//         if (snapshot.exists) {
//           resolve({
//             ...snapshot.data(),
//             documentID: productID
//           });
//         }
//       })
//       .catch(err => {
//         reject(err);
//       })
//   })
// }