import React from 'react';
//width="682px" height="1137px"
const GlassEbcIPA = ({beerEBC, height, width, className }) => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height} viewBox="50 0 384 512" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet">
<g>
	<path fill="#FFFFFF" d="M328.051,127.91H183.95c0,0,2.266-19.04-2.936-34.754c-2.52-7.613-13.075-29.291-13.075-29.291
		h176.124c0,0-10.784,19.21-14.046,32.203C328.075,103.804,328.051,127.91,328.051,127.91z"/>
	<path fill="#FFFFFF" d="M284.02,15.831c-8.552,0-16.508,2.478-23.263,6.696c-8.062-9.003-19.739-14.702-32.776-14.702
		c-24.317,0-44.031,19.713-44.031,44.031c0,24.317,19.714,44.031,44.031,44.031c8.552,0,16.508-2.478,23.263-6.697
		c8.062,9.003,19.739,14.703,32.776,14.703c24.317,0,44.031-19.714,44.031-44.031C328.051,35.544,308.338,15.831,284.02,15.831z"/>
</g>
<path fill="#7B7B53" d="M256.001,456.141c0,13.264,10.753,24.017,24.017,24.017h36.026c6.633,0,12.009,5.376,12.009,12.009
	l0,0c0,6.633-5.376,12.009-12.009,12.009H195.959c-6.633,0-12.009-5.376-12.009-12.009l0,0c0-6.633,5.376-12.009,12.009-12.009
	h36.026C245.248,480.158,256.001,469.405,256.001,456.141"/>
<path fill={beerEBC} d="M183.95,119.904c0,20.913-12.671,48.272-24.017,64.046l0,0
	c-15.114,20.084-24.017,44.979-24.017,72.05c0,66.32,53.764,120.085,120.085,120.085s120.084-53.765,120.084-120.085
	c0-27.073-8.903-51.966-24.017-72.05l0,0c-11.346-15.773-24.017-43.132-24.017-64.046L183.95,119.904L183.95,119.904z"/>
<path fill={beerEBC} d="M256.001,376.084c-66.321,0-120.085-53.764-120.085-120.085c0-27.073,8.903-51.966,24.017-72.05l0,0
	c11.346-15.773,24.017-43.132,24.017-64.046h72.051V376.084z"/>
<path fill="#FFFFFF" d="M307.659,334.67l-8.355-13.234c14.433-9.113,26.224-21.918,34.1-37.028l13.88,7.234
	C338.13,309.202,324.429,324.082,307.659,334.67z"/>
<path fill="#1D1D1B" d="M383.91,256.001c0-27.939-8.831-54.455-25.538-76.687c-14.769-20.562-22.496-45.442-22.496-59.408
	c0-18.615,5.725-36.413,16.555-51.469l8.914-12.391l-25.626-0.006c-1.966-26.813-24.393-48.033-51.701-48.033
	c-7.582,0-15.045,1.677-21.895,4.886C252.71,4.637,240.572,0,227.981,0c-28.594,0-51.856,23.262-51.856,51.856
	c0,1.396,0.072,2.774,0.18,4.143l-25.697-0.006l8.932,12.402c10.849,15.065,16.585,32.877,16.585,51.51
	c0,13.966-7.727,38.847-22.497,59.408c-16.707,22.233-25.538,48.749-25.538,76.687c0,67.902,53.186,123.605,120.085,127.663v72.478
	c0,8.929-7.264,16.193-16.193,16.193h-36.025c-10.936,0-19.834,8.897-19.834,19.833s8.897,19.834,19.834,19.834h120.085
	c10.936,0,19.834-8.898,19.834-19.834c0-10.936-8.897-19.833-19.834-19.833h-36.025c-8.929,0-16.192-7.264-16.192-16.193v-72.478
	C330.724,379.606,383.91,323.901,383.91,256.001z M227.981,15.65c10.24,0,20.063,4.409,26.948,12.098l4.391,4.904l5.583-3.487
	c5.771-3.604,12.381-5.509,19.117-5.509c19.964,0,36.206,16.242,36.206,36.206s-16.242,36.206-36.206,36.206
	c-10.239,0-20.061-4.409-26.946-12.098l-4.39-4.904l-5.584,3.486c-5.772,3.605-12.383,5.51-19.118,5.51
	c-19.964,0-36.206-16.242-36.206-36.206S208.017,15.65,227.981,15.65z M280.018,487.983h36.025c2.307,0,4.184,1.876,4.184,4.183
	c0,2.307-1.877,4.184-4.184,4.184H195.959c-2.307,0-4.184-1.877-4.184-4.184c0-2.307,1.877-4.183,4.184-4.183h36.025
	c9.575,0,18.175-4.247,24.018-10.956C261.843,483.736,270.443,487.983,280.018,487.983z M256.001,368.259
	c-61.9,0-112.26-50.359-112.26-112.26c0-24.546,7.76-47.833,22.444-67.346l0.099-0.136c12.278-17.066,25.491-45.819,25.491-68.615
	c0-15.718-3.476-30.941-10.145-44.839c8.532,16.969,26.098,28.647,46.352,28.647c7.582,0,15.045-1.677,21.896-4.886
	c9.414,8.255,21.553,12.892,34.144,12.892c15.902,0,30.147-7.2,39.667-18.505c-2.282,8.623-3.461,17.573-3.461,26.691
	c0,22.797,13.213,51.549,25.491,68.615l0.099,0.136c14.683,19.513,22.444,42.8,22.444,67.346
	C368.26,317.9,317.901,368.259,256.001,368.259z"/>

</svg>

);

export default GlassEbcIPA;