import React from 'react';
import { Link } from 'react-router-dom';
import ShopMen from './../../assets/shopMens.jpg';
import ShopWomen from './../../assets/shopWomens.jpg';
import diamondbackground from './../../assets/diamondbackground.png';
import Cards from '../../components/Cards';
import './styles.scss';

const Directory = props => {
  const cardType = {action: "Directory"}
  const cardData = [
    {
      imgTitle: 'Stage 1',
      imgLoc:"/static/images/Mash.jpg",
      header:'Mashing',
      contents:"Description about mash",
    },
    {
      imgTitle: 'Stage 2',
      imgLoc:"/static/images/Boil.jpg",
      header:'Boiling',
      contents:"Description about boil",
    },
    {
      imgTitle: 'Stage 3',
      imgLoc:"/static/images/Kegging.jpg",
      header:'Kegging',
      contents:"Description about kegging",
    },
    {
      imgTitle: 'Stage 4',
      imgLoc:"/static/images/Kegging.jpg",
      header:'Drinking',
      contents:"Description about drinking",
    },
    {
      imgTitle: 'Stage 1',
      imgLoc:"/static/images/Mash.jpg",
      header:'Mashing',
      contents:"Description about mash",
    },
    {
      imgTitle: 'Stage 2',
      imgLoc:"/static/images/Boil.jpg",
      header:'Boiling',
      contents:"Description about boil",
    },
    {
      imgTitle: 'Stage 3',
      imgLoc:"/static/images/Kegging.jpg",
      header:'Kegging',
      contents:"Description about kegging",
    },
    {
      imgTitle: 'Stage 4',
      imgLoc:"/static/images/Kegging.jpg",
      header:'Drinking',
      contents:"Description about drinking",
    },
    {
      imgTitle: 'Stage 1',
      imgLoc:"/static/images/Mash.jpg",
      header:'Mashing',
      contents:"Description about mash",
    },
    {
      imgTitle: 'Stage 2',
      imgLoc:"/static/images/Boil.jpg",
      header:'Boiling',
      contents:"Description about boil",
    },
    {
      imgTitle: 'Stage 3',
      imgLoc:"/static/images/Kegging.jpg",
      header:'Kegging',
      contents:"Description about kegging",
    },
    {
      imgTitle: 'Stage 4',
      imgLoc:"/static/images/Kegging.jpg",
      header:'Drinking',
      contents:"Description about drinking",
    }
  ]
  return (
    <div className="directory">    
    <div className="wrap">
        <div className="banner"
          // style={{
          //   backgroundImage: `url(${diamondbackground})`,
          //   backgroundSize: "contain"
          // }}
        >
          {/* <Link to="/search/womens"> */}
            {/* <div style={{backgroundImage: `url(${Header})`}} /> */}
            <h3>IT'S BREW DAY, LET'S BEGIN</h3>
          {/* </Link> */}

        </div>
        
        <div className="brewcards"
        >
          <Cards data={cardData}>

          </Cards>
          {/* <Link to="/search/womens" className='item1'>
            <div style={{backgroundImage: `url(${Header})`}} />

          </Link>
          <Link to="/search/womens" className='item2'>
            <div style={{backgroundImage: `url(${Header})`}} />

          </Link>
          <Link to="/search/womens" className='item3'>
            <div style={{backgroundImage: `url(${Header})`}} />

          </Link> */}

        </div>
    </div>
    </div>
  );
};

export default Directory;

//this was inside the return
/* <div className="directory">
      <div className="wrap">
        <div
          className="item"
          style={{
            backgroundImage: `url(${ShopWomen})`
          }}
        >
          <Link to="/search/womens">
            Shop Womens
          </Link>
        </div>
        <div
          className="item"
          style={{
            backgroundImage: `url(${ShopMen})`
          }}
        >
          <Link to="/search/mens">
            Shop Mens
          </Link>
        </div>
      </div>
    </div> */