import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import './styles.scss';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Coffee } from "./Coffee";
import { Beer } from "./Beer";
import { ReviewBrew } from './ReviewBrew';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: "10ch"
    },
    '& .MuiInput-underline': {
      // Remove the ripple effect on input
      '&:after': {
        borderBottom: 'initial',
      },
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
  paperFullHeight: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
    height: "100%",
  },
  buttonGroup: {
    color: `${theme.palette.text.primary}!important`,
    marginRight: "1.5rem",
    "& > *": {
      color: `${theme.palette.text.primary}!important`,
    },
  },
  formControl: {
    minWidth: 150,
  },
  carddescription: {
    margin: `1rem 0 1rem 0`
  },
  imageheader: {
    textAlign: "center",
    border: "1px solid rgba(0, 0, 0, 0.87)",
    margin: "-8px -8px 1rem -8px",
    borderRadius: "inherit",
    "& > *": {
      // maxWidth: "12rem",
      maxHeight: "12rem",
      objectFit: "contain",
      overflow: "hidden",
      margin: "1rem",
      maxWidth: "100%",
    }
  },
  submitBtn: {
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: "12px!important"
  }
}));

const Designer = props => {
  const classes = useStyles();

  return (
    <div className='designer'>
      <div className='wrap'>
        {/* <div className='banner'>
          <h3>This is where you can build your own cold brew recipe. Use the cards below to enter your choices and then submit for brewing. It's that simple.</h3>
        </div> */}
        <div className="brewcards">
          {/* <form noValidate autoComplete="off" onSubmit={handleSubmit}> */}
            {props.brewType === 'beer' && <Beer {...props} classes={classes}/>}
            {props.brewType === 'coffee' && <Coffee {...props} classes={classes}/>}
            {props.brewType === 'review' && <ReviewBrew {...props} classes={classes}/>}
            {/* <div className={classes.root}>
            </div> */}
          {/* </form> */}
        </div>
      </div>
      
    </div>
    
  );
}

export default Designer;
