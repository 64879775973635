import React from 'react';
import ProductCard from './../../components/ProductCard';

const ProductDetails = ({}) => {
  return (
    <div>
      <ProductCard />
    </div>
  );
}

export default ProductDetails;
