import React from 'react';
//width="682px" height="1137px"
const HopIBU = ({colours, height, width, className }) => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height} viewBox="0 0 800 600" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet">
<path fill="#FFFFFF" d="M0 0L0 865L596 865L596 0L0 0z"/>
<path fill={colours[2]} d="M270 21L270 22L280 22L270 21z"/>
<path fill={colours[0]} d="M280 21L280 22L283 22L280 21z"/>
<path fill={colours[2]} d="M283 21L284 22L283 21M255 22L255 23L258 23L255 22z"/>
<path fill={colours[0]} d="M258 22L258 23L265 23L258 22z"/>
<path fill="#020202" d="M225 134C176.837 136.068 126.191 162.542 87 188.667C75.6253 196.249 53.0646 207.542 48.2677 221C45.7838 227.969 53.0625 232.902 50.9483 239C47.8703 247.878 41.6973 256.315 37.8603 265C28.3435 286.541 20.8729 311.313 20.0108 335C19.5274 348.283 14.6209 382.779 39 370C33.6244 411.626 31.4151 451.267 38.4136 493C40.1491 503.349 40.583 526.16 51.0154 531.543C55.9668 534.098 61.7824 532.659 67 532C66.9041 566.73 66.9287 598.776 79.3194 632C82.8261 641.403 88.343 660.015 99.0039 663.346C107.641 666.046 115.318 658.431 123 656C128.124 694.02 152.315 734.339 175.424 764C182.053 772.508 192.504 790.125 204 791.782C215 793.368 225.609 781.527 234 776C242.269 795.166 256.382 813.271 267.424 831C271.391 837.37 275.488 846.742 284 847.577C291.58 848.321 295.593 841.658 298.85 836C307.581 820.834 320.72 804.684 326 788C331.685 792.332 337.045 800.431 345 799.772C355.719 798.883 367.893 784.618 374.421 777C390.458 758.284 403.243 738.007 414.247 716C418.599 707.295 424.287 697.642 426 688C432.359 694.166 435.112 707.02 445 708.771C458.258 711.118 467.095 693.24 472.947 684C491.888 654.093 503.873 620.546 511 586C529.8 597.423 541.283 570.02 546.575 556C560.815 518.274 561.401 469.035 552 430C573.409 429.809 566.626 406.127 563.8 392C556.606 356.033 538.345 317.074 516 288C528.553 290.225 535.619 280.628 530.663 269C521.966 248.597 508.575 229.087 494.576 212C461.846 172.053 422.096 149.619 372 139.6C353.858 135.972 334.513 133.03 316 133C310.123 132.991 296.962 135.469 292.702 130.682C288.981 126.5 287.814 118.32 286.662 113C282.604 94.2601 284.242 74.6361 288.211 56C289.907 48.036 296.269 37.0229 292.772 29.0401C289.826 22.316 283.387 22.0024 277 22C264.027 21.995 250.811 23.153 238 25.2462C232.291 26.1789 225.601 26.3443 222.457 32.0941C217.535 41.0955 226.567 55.404 227.715 65C230.398 87.4402 227.815 111.724 225 134z"/>
<path fill={colours[0]} d="M286 22L287 23L286 22z"/>
<path fill={colours[2]} d="M246 23L246 24L249 24L246 23z"/>
<path fill={colours[0]} d="M249 23L249 24L252 24L249 23M288 23L289 24L288 23z"/>
<path fill={colours[2]} d="M239.667 24.3333L240.333 24.6667L239.667 24.3333z"/>
<path fill={colours[0]} d="M241.667 24.3333L242.333 24.6667L241.667 24.3333M289 24L290 25L289 24z"/>
<path fill={colours[2]} d="M233 25L234 26L233 25z"/>
<path fill={colours[0]} d="M234 25L234 26L237 26L234 25z"/>
<path fill={colours[2]} d="M228 26L229 27L228 26z"/>
<path fill={colours[0]} d="M229 26L230 27L229 26M291 26L292 27L291 26z"/>
<path fill={colours[2]} d="M226 27L227 28L226 27M292 27L293 28L292 27z"/>
<path fill={colours[0]} d="M225 28L226 29L225 28z"/>
<path fill={colours[2]} d="M293 29L294 30L293 29z"/>
<path fill={colours[0]} d="M293 30L294 31L293 30M222 31L223 32L222 31z"/>
<path fill={colours[2]} d="M221 32L222 33L221 32z"/>
<path fill={colours[0]} d="M221 33L222 34L221 33z"/>
<path fill={colours[2]} d="M220 35L221 36L220 35z"/>
<path fill={colours[0]} d="M220 36L220 41L221 41L220 36M293 37L294 38L293 37M292 40L293 41L292 40z"/>
<path fill={colours[2]} d="M220 41L221 42L220 41M292 41L293 42L292 41z"/>
<path fill={colours[0]} d="M221 43L222 44L221 43M291 43L292 44L291 43z"/>
<path fill={colours[2]} d="M291 44L292 45L291 44M222 45L223 46L222 45M223 47L224 48L223 47z"/>
<path fill={colours[0]} d="M256 47L256 48L260 48L256 47z"/>
<path fill={colours[2]} d="M260 47L260 48L263 48L260 47z"/>
<path fill={colours[0]} d="M250 49C250.901 69.9907 253.357 89.873 251.91 111C251.15 122.106 245.24 139.145 248.318 149.811C250.391 156.995 264.462 156.065 268.682 151.697C274.47 145.707 265.111 129.072 263.374 122C257.077 96.3551 261.568 72.4874 265 47L250 49z"/>
<path fill={colours[0]} d="M265 47L266 48L265 47M290 47L291 48L290 47M249.667 48.3333L250.333 48.6667L249.667 48.3333z"/>
<path fill={colours[2]} d="M251.667 48.3333L252.333 48.6667L251.667 48.3333M290 48L291 49L290 48M224 49L225 50L224 49z"/>
<path fill={colours[3]} d="M249 49L250 50L249 49z"/>
<path fill={colours[2]} d="M249 50L250 51L249 50M264 50L265 51L264 50z"/>
<path fill={colours[0]} d="M289.333 50.6667L289.667 51.3333L289.333 50.6667M249 51L250 52L249 51M264 51L265 52L264 51M225 52L226 53L225 52z"/>
<path fill={colours[2]} d="M289 52L290 53L289 52M225 53L226 54L225 53z"/>
<path fill={colours[3]} d="M250 54L251 55L250 54z"/>
<path fill={colours[2]} d="M263.333 54.6667L263.667 55.3333L263.333 54.6667z"/>
<path fill={colours[0]} d="M288.333 54.6667L288.667 55.3333L288.333 54.6667z"/>
<path fill={colours[2]} d="M250 55L251 56L250 55z"/>
<path fill={colours[0]} d="M226.333 56.6667L226.667 57.3333L226.333 56.6667M250 56L250 59L251 59L250 56M263 56L264 57L263 56z"/>
<path fill={colours[2]} d="M288 56L289 57L288 56M226.333 58.6667L226.667 59.3333L226.333 58.6667z"/>
<path fill={colours[3]} d="M262 59L263 60L262 59z"/>
<path fill={colours[0]} d="M287.333 59.6667L287.667 60.3333L287.333 59.6667z"/>
<path fill={colours[2]} d="M262 60L263 61L262 60z"/>
<path fill={colours[0]} d="M262 61L262 64L263 64L262 61z"/>
<path fill={colours[2]} d="M287 61L288 62L287 61M251.333 62.6667L251.667 63.3333L251.333 62.6667z"/>
<path fill={colours[0]} d="M227 63L227 68L228 68L227 63M251 64L251 70L252 70L251 64M286.333 65.6667L286.667 66.3333L286.333 65.6667z"/>
<path fill={colours[2]} d="M261.333 66.6667L261.667 67.3333L261.333 66.6667M286.333 67.6667L286.667 68.3333L286.333 67.6667M227 68L227 72L228 72L227 68z"/>
<path fill={colours[0]} d="M261 68L261 71L262 71L261 68M285 72L285 76L286 76L285 72z"/>
<path fill={colours[3]} d="M260 74L261 75L260 74z"/>
<path fill={colours[2]} d="M252 75L252 97L253 97L252 75M260.333 75.6667L260.667 76.3333L260.333 75.6667M285 76L285 79L286 79L285 76z"/>
<path fill={colours[0]} d="M260 77L260 88L261 88L260 77M284 84L284 96L285 96L284 84M260 92L260 103L261 103L260 92z"/>
<path fill={colours[2]} d="M227 97L227 102L228 102L227 97M285.333 101.667L285.667 102.333L285.333 101.667z"/>
<path fill={colours[0]} d="M227 102L227 110L228 110L227 102z"/>
<path fill={colours[2]} d="M260.333 103.667L260.667 104.333L260.333 103.667z"/>
<path fill={colours[0]} d="M285 103L285 107L286 107L285 103M251 104L251 111L252 111L251 104M261 108L261 111L262 111L261 108z"/>
<path fill={colours[2]} d="M286.333 109.667L286.667 110.333L286.333 109.667M251 111L251 115L252 115L251 111M261.333 111.667L261.667 112.333L261.333 111.667z"/>
<path fill={colours[0]} d="M286.333 111.667L286.667 112.333L286.333 111.667z"/>
<path fill={colours[2]} d="M226 114L226 117L227 117L226 114z"/>
<path fill={colours[0]} d="M262.333 115.667L262.667 116.333L262.333 115.667z"/>
<path fill={colours[2]} d="M287 115L288 116L287 115z"/>
<path fill={colours[0]} d="M287 116L288 117L287 116M226 117L226 122L227 122L226 117z"/>
<path fill={colours[2]} d="M262.333 117.667L262.667 118.333L262.333 117.667z"/>
<path fill={colours[0]} d="M250 118L250 123L251 123L250 118z"/>
<path fill={colours[2]} d="M288 119L289 120L288 119z"/>
<path fill={colours[0]} d="M263.333 120.667L263.667 121.333L263.333 120.667M288 120L289 121L288 120z"/>
<path fill={colours[2]} d="M263 122L264 123L263 122M250 123L250 126L251 126L250 123z"/>
<path fill={colours[0]} d="M289 123L290 124L289 123z"/>
<path fill={colours[2]} d="M225.333 125.667L225.667 126.333L225.333 125.667z"/>
<path fill={colours[0]} d="M264 125L265 126L264 125z"/>
<path fill={colours[2]} d="M264 126L265 127L264 126M290 126L291 127L290 126z"/>
<path fill={colours[0]} d="M225 127L225 131L226 131L225 127M249 129L249 133L250 133L249 129M265 129L266 130L265 129M291 129L292 130L291 129z"/>
<path fill={colours[2]} d="M265 130L266 131L265 130M292 131L293 132L292 131z"/>
<path fill={colours[0]} d="M266 132L267 133L266 132M293 132L293 133L314 133L293 132z"/>
<path fill={colours[2]} d="M314 132L314 133L320 133L314 132M223 133L224 134L223 133z"/>
<path fill={colours[0]} d="M224 133L225 134L224 133z"/>
<path fill={colours[2]} d="M249.333 133.667L249.667 134.333L249.333 133.667M266 133L267 134L266 133z"/>
<path fill={colours[0]} d="M324 133L324 134L330 134L324 133z"/>
<path fill={colours[2]} d="M330 133L330 134L333 134L330 133M216 134L217 135L216 134z"/>
<path fill={colours[0]} d="M217 134L217 135L220 135L217 134M336 134L336 135L340 135L336 134z"/>
<path fill={colours[2]} d="M340 134L340 135L343 135L340 134M210.667 135.333L211.333 135.667L210.667 135.333z"/>
<path fill={colours[0]} d="M212.667 135.333L213.333 135.667L212.667 135.333M267 135L268 136L267 135M345 135L345 136L348 136L345 135z"/>
<path fill={colours[2]} d="M348.667 135.333L349.333 135.667L348.667 135.333M205.667 136.333L206.333 136.667L205.667 136.333z"/>
<path fill={colours[0]} d="M207 136L208 137L207 136z"/>
<path fill={colours[3]} d="M267 136L268 137L267 136z"/>
<path fill={colours[0]} d="M353 136L353 137L356 137L353 136z"/>
<path fill={colours[2]} d="M356 136L357 137L356 136M201 137L202 138L201 137z"/>
<path fill={colours[0]} d="M202.667 137.333L203.333 137.667L202.667 137.333M360.667 137.333L361.333 137.667L360.667 137.333z"/>
<path fill={colours[2]} d="M362 137L363 138L362 137M197 138L198 139L197 138z"/>
<path fill={colours[0]} d="M198 138L199 139L198 138M248 138L248 141L249 141L248 138M268 138L269 139L268 138M366.667 138.333L367.333 138.667L366.667 138.333z"/>
<path fill={colours[2]} d="M368 138L369 139L368 138M193 139L194 140L193 139z"/>
<path fill={colours[0]} d="M194 139L195 140L194 139M371.667 139.333L372.333 139.667L371.667 139.333z"/>
<path fill={colours[2]} d="M373 139L374 140L373 139M189 140L190 141L189 140z"/>
<path fill={colours[0]} d="M190 140L191 141L190 140M269 140L270 141L269 140M376.667 140.333L377.333 140.667L376.667 140.333z"/>
<path fill={colours[2]} d="M378 140L379 141L378 140z"/>
<path fill={colours[0]} d="M186 141L187 142L186 141z"/>
<path fill={colours[2]} d="M248 141L249 142L248 141M269 141L270 142L269 141z"/>
<path fill={colours[0]} d="M381.667 141.333L382.333 141.667L381.667 141.333z"/>
<path fill={colours[2]} d="M383 141L384 142L383 141M182 142L183 143L182 142z"/>
<path fill={colours[0]} d="M183 142L184 143L183 142M386 142L387 143L386 142z"/>
<path fill={colours[2]} d="M387 142L388 143L387 142M179 143L180 144L179 143z"/>
<path fill={colours[0]} d="M180 143L181 144L180 143M247 143L248 144L247 143z"/>
<path fill={colours[2]} d="M270 143L271 144L270 143z"/>
<path fill={colours[0]} d="M390 143L391 144L390 143z"/>
<path fill={colours[2]} d="M391 143L392 144L391 143M176 144L177 145L176 144M247 144L247 149L248 149L247 144z"/>
<path fill={colours[0]} d="M394 144L395 145L394 144z"/>
<path fill={colours[2]} d="M395 144L396 145L395 144M173 145L174 146L173 145z"/>
<path fill={colours[0]} d="M271 145L272 146L271 145M398 145L399 146L398 145z"/>
<path fill={colours[2]} d="M399 145L400 146L399 145M170 146L171 147L170 146M271 146L272 147L271 146z"/>
<path fill={colours[0]} d="M402 146L403 147L402 146z"/>
<path fill={colours[2]} d="M167 147L168 148L167 147z"/>
<path fill={colours[0]} d="M168 147L169 148L168 147M271.333 147.667L271.667 148.333L271.333 147.667M405 147L406 148L405 147z"/>
<path fill={colours[2]} d="M164 148L165 149L164 148z"/>
<path fill={colours[0]} d="M165 148L166 149L165 148M408 148L409 149L408 148z"/>
<path fill={colours[2]} d="M409 148L410 149L409 148z"/>
<path fill={colours[0]} d="M162 149L163 150L162 149M247 149L248 150L247 149M411 149L412 150L411 149z"/>
<path fill={colours[2]} d="M159 150L160 151L159 150z"/>
<path fill={colours[0]} d="M270 150L271 151L270 150M413 150L414 151L413 150z"/>
<path fill={colours[2]} d="M414 150L415 151L414 150z"/>
<path fill={colours[0]} d="M157 151L158 152L157 151z"/>
<path fill={colours[3]} d="M269 151L270 152L269 151z"/>
<path fill={colours[0]} d="M416 151L417 152L416 151z"/>
<path fill={colours[2]} d="M154 152L155 153L154 152M249 152L250 153L249 152M268 152L269 153L268 152z"/>
<path fill={colours[0]} d="M418 152L419 153L418 152z"/>
<path fill={colours[2]} d="M419 152L420 153L419 152z"/>
<path fill={colours[0]} d="M152 153L153 154L152 153M250 153L251 154L250 153M267 153L268 154L267 153z"/>
<path fill={colours[2]} d="M421 153L422 154L421 153M149 154L150 155L149 154z"/>
<path fill={colours[0]} d="M150 154L151 155L150 154M252 154L253 155L252 154z"/>
<path fill={colours[2]} d="M253.667 154.333L254.333 154.667L253.667 154.333z"/>
<path fill={colours[3]} d="M263 154L264 155L263 154z"/>
<path fill={colours[2]} d="M264 154L265 155L264 154z"/>
<path fill={colours[0]} d="M265 154L266 155L265 154M423 154L424 155L423 154z"/>
<path fill={colours[2]} d="M147 155L148 156L147 155z"/>
<path fill={colours[0]} d="M259.667 155.333L260.333 155.667L259.667 155.333M425 155L426 156L425 155M145 156L146 157L145 156M427 156L428 157L427 156z"/>
<path fill={colours[2]} d="M142 157L143 158L142 157z"/>
<path fill={colours[0]} d="M143 157L144 158L143 157z"/>
<path fill={colours[1]} d="M488 246C482.816 233.676 471.328 222.343 462 213C429.415 180.362 392.882 167.109 348 160.87C334.626 159.01 321.409 158.829 308 157.911C301.154 157.442 294.377 154.906 291.372 163.005C289.268 168.675 294.431 173.226 298.039 177C306.882 186.249 317.333 194.307 329 199.691C351.907 210.26 378.728 213.578 403 220.151C431.472 227.863 459.329 239.367 488 246z"/>
<path fill={colours[2]} d="M304 157L304 158L312 158L304 157z"/>
<path fill={colours[0]} d="M312 157L312 158L321 158L312 157M429 157L430 158L429 157z"/>
<path fill={colours[2]} d="M140 158L141 159L140 158z"/>
<path fill={colours[0]} d="M141 158L142 159L141 158M221.667 158.333L222.333 158.667L221.667 158.333z"/>
<path fill={colours[2]} d="M223 158L224 159L223 158z"/>
<path fill={colours[0]} d="M224 158L225 159L224 158M293 158L294 159L293 158z"/>
<path fill={colours[2]} d="M325 158L325 159L329 159L325 158z"/>
<path fill={colours[0]} d="M329 158L329 159L333 159L329 158M431 158L432 159L431 158z"/>
<path fill={colours[2]} d="M138 159L139 160L138 159z"/>
<path fill={colours[0]} d="M215.667 159.333L216.333 159.667L215.667 159.333z"/>
<path fill={colours[2]} d="M217.667 159.333L218.333 159.667L217.667 159.333z"/>
<path fill={colours[1]} d="M91 216L91 217C137.193 217 206.212 215.287 236 173C230.924 169.414 226.91 164.982 225 159C177.629 159.978 128.84 190.472 91 216z"/>
<path fill={colours[2]} d="M336 159L336 160L339 160L336 159z"/>
<path fill={colours[0]} d="M339 159L339 160L342 160L339 159z"/>
<path fill={colours[2]} d="M433 159L434 160L433 159z"/>
<path fill={colours[0]} d="M136 160L137 161L136 160M210.667 160.333L211.333 160.667L210.667 160.333z"/>
<path fill={colours[2]} d="M212 160L213 161L212 160z"/>
<path fill={colours[0]} d="M225 160L226 161L225 160M292 160L293 161L292 160z"/>
<path fill={colours[2]} d="M345.667 160.333L346.333 160.667L345.667 160.333z"/>
<path fill={colours[0]} d="M347 160L347 161L350 161L347 160M434 160L435 161L434 160z"/>
<path fill={colours[2]} d="M435 160L436 161L435 160z"/>
<path fill={colours[0]} d="M134 161L135 162L134 161M206 161L207 162L206 161z"/>
<path fill={colours[2]} d="M207 161L208 162L207 161z"/>
<path fill={colours[3]} d="M208 161L209 162L208 161z"/>
<path fill={colours[2]} d="M225 161L226 162L225 161M352.667 161.333L353.333 161.667L352.667 161.333z"/>
<path fill={colours[0]} d="M354.667 161.333L355.333 161.667L354.667 161.333M436 161L437 162L436 161M132 162L133 163L132 162M202 162L203 163L202 162z"/>
<path fill={colours[2]} d="M203 162L204 163L203 162z"/>
<path fill={colours[0]} d="M226 162L227 163L226 162M291 162L292 163L291 162z"/>
<path fill={colours[3]} d="M358 162L359 163L358 162z"/>
<path fill={colours[2]} d="M359 162L360 163L359 162z"/>
<path fill={colours[0]} d="M360.667 162.333L361.333 162.667L360.667 162.333M438 162L439 163L438 162M130 163L131 164L130 163M198 163L199 164L198 163z"/>
<path fill={colours[2]} d="M199 163L200 164L199 163z"/>
<path fill={colours[3]} d="M226 163L227 164L226 163M364 163L365 164L364 163z"/>
<path fill={colours[2]} d="M365 163L366 164L365 163z"/>
<path fill={colours[0]} d="M366.667 163.333L367.333 163.667L366.667 163.333z"/>
<path fill={colours[2]} d="M440 163L441 164L440 163z"/>
<path fill={colours[0]} d="M128 164L129 165L128 164M194 164L195 165L194 164z"/>
<path fill={colours[2]} d="M195 164L196 165L195 164z"/>
<path fill={colours[0]} d="M227 164L228 165L227 164z"/>
<path fill={colours[2]} d="M290 164L291 165L290 164z"/>
<path fill={colours[3]} d="M369 164L370 165L369 164z"/>
<path fill={colours[2]} d="M370 164L371 165L370 164z"/>
<path fill={colours[0]} d="M371.667 164.333L372.333 164.667L371.667 164.333M441 164L442 165L441 164M126 165L127 166L126 165M191 165L192 166L191 165z"/>
<path fill={colours[3]} d="M192 165L193 166L192 165z"/>
<path fill={colours[0]} d="M289 165L290 166L289 165z"/>
<path fill={colours[2]} d="M375 165L376 166L375 165z"/>
<path fill={colours[0]} d="M376 165L377 166L376 165z"/>
<path fill={colours[2]} d="M443 165L444 166L443 165z"/>
<path fill={colours[0]} d="M124 166L125 167L124 166z"/>
<path fill={colours[2]} d="M188 166L189 167L188 166M228 166L229 167L228 166M289 166L290 167L289 166M379 166L380 167L379 166z"/>
<path fill={colours[0]} d="M380.667 166.333L381.333 166.667L380.667 166.333M444 166L445 167L444 166z"/>
<path fill={colours[2]} d="M122 167L123 168L122 167z"/>
<path fill={colours[0]} d="M184 167L185 168L184 167z"/>
<path fill={colours[2]} d="M185 167L186 168L185 167z"/>
<path fill={colours[0]} d="M229 167L230 168L229 167z"/>
<path fill={colours[2]} d="M384 167L385 168L384 167z"/>
<path fill={colours[0]} d="M385 167L386 168L385 167z"/>
<path fill={colours[2]} d="M446 167L447 168L446 167M120 168L121 169L120 168z"/>
<path fill={colours[0]} d="M181 168L182 169L181 168z"/>
<path fill={colours[2]} d="M182 168L183 169L182 168z"/>
<path fill={colours[0]} d="M230 168L231 169L230 168M290 168L291 169L290 168z"/>
<path fill={colours[2]} d="M388 168L389 169L388 168z"/>
<path fill={colours[0]} d="M389 168L390 169L389 168M447 168L448 169L447 168z"/>
<path fill={colours[2]} d="M118 169L119 170L118 169M179 169L180 170L179 169z"/>
<path fill={colours[0]} d="M231 169L232 170L231 169M291 169L292 170L291 169z"/>
<path fill={colours[2]} d="M392 169L393 170L392 169z"/>
<path fill={colours[0]} d="M393 169L394 170L393 169z"/>
<path fill={colours[2]} d="M449 169L450 170L449 169z"/>
<path fill={colours[0]} d="M117 170L118 171L117 170z"/>
<path fill={colours[2]} d="M176 170L177 171L176 170z"/>
<path fill={colours[0]} d="M232 170L233 171L232 170z"/>
<path fill={colours[2]} d="M292 170L293 171L292 170z"/>
<path fill={colours[3]} d="M395 170L396 171L395 170z"/>
<path fill={colours[0]} d="M396 170L397 171L396 170M450 170L451 171L450 170M115 171L116 172L115 171M173 171L174 172L173 171M233 171L234 172L233 171z"/>
<path fill={colours[3]} d="M293 171L294 172L293 171z"/>
<path fill={colours[2]} d="M399 171L400 172L399 171M452 171L453 172L452 171z"/>
<path fill={colours[0]} d="M113 172L114 173L113 172M170 172L171 173L170 172z"/>
<path fill={colours[2]} d="M171 172L172 173L171 172M234 172L235 173L234 172z"/>
<path fill={colours[3]} d="M401 172L402 173L401 172z"/>
<path fill={colours[0]} d="M402 172L403 173L402 172M453 172L454 173L453 172z"/>
<path fill={colours[2]} d="M111 173L112 174L111 173M168 173L169 174L168 173z"/>
<path fill={colours[0]} d="M236 173L237 174L236 173M294 173L295 174L294 173z"/>
<path fill={colours[2]} d="M404 173L405 174L404 173z"/>
<path fill={colours[0]} d="M405 173L406 174L405 173z"/>
<path fill={colours[2]} d="M109 174L110 175L109 174z"/>
<path fill={colours[0]} d="M165 174L166 175L165 174z"/>
<path fill={colours[2]} d="M166 174L167 175L166 174z"/>
<path fill={colours[3]} d="M235 174L236 175L235 174z"/>
<path fill={colours[0]} d="M295 174L296 175L295 174M407 174L408 175L407 174z"/>
<path fill={colours[2]} d="M456 174L457 175L456 174z"/>
<path fill={colours[0]} d="M108 175L109 176L108 175z"/>
<path fill={colours[2]} d="M163 175L164 176L163 175z"/>
<path fill={colours[3]} d="M234 175L235 176L234 175z"/>
<path fill={colours[0]} d="M296 175L297 176L296 175z"/>
<path fill={colours[2]} d="M409 175L410 176L409 175M457 175L458 176L457 175z"/>
<path fill={colours[0]} d="M106 176L107 177L106 176M160 176L161 177L160 176z"/>
<path fill={colours[2]} d="M161 176L162 177L161 176z"/>
<path fill={colours[3]} d="M233 176L234 177L233 176z"/>
<path fill={colours[0]} d="M297 176L298 177L297 176z"/>
<path fill={colours[2]} d="M411 176L412 177L411 176z"/>
<path fill={colours[0]} d="M412 176L413 177L412 176M458 176L459 177L458 176z"/>
<path fill={colours[2]} d="M104 177L105 178L104 177z"/>
<path fill={colours[0]} d="M158 177L159 178L158 177z"/>
<path fill={colours[3]} d="M232 177L233 178L232 177z"/>
<path fill={colours[2]} d="M298 177L299 178L298 177z"/>
<path fill={colours[0]} d="M414 177L415 178L414 177M103 178L104 179L103 178z"/>
<path fill={colours[2]} d="M156 178L157 179L156 178z"/>
<path fill={colours[3]} d="M231 178L232 179L231 178z"/>
<path fill={colours[2]} d="M299 178L300 179L299 178z"/>
<path fill={colours[0]} d="M416 178L417 179L416 178z"/>
<path fill={colours[2]} d="M461 178L462 179L461 178z"/>
<path fill={colours[0]} d="M101 179L102 180L101 179z"/>
<path fill={colours[2]} d="M154 179L155 180L154 179z"/>
<path fill={colours[3]} d="M230 179L231 180L230 179M263 179L264 180L263 179z"/>
<path fill={colours[1]} d="M264 180.086C258.349 182.649 254.361 190.503 249.91 194.714C244.303 200.021 233.906 205.177 230.434 212.09C226.043 220.832 226.934 234.623 227.83 244C230.768 274.75 241.807 300.067 264 321.996C275.312 333.173 288.799 342.606 303 349.752C306.938 351.734 313.487 356.41 317.996 354.187C325.272 350.6 331.721 339.493 336.279 333C351.272 311.644 360.01 289.164 360 263C359.997 255.136 359.688 241.786 354.157 235.513C350.552 231.425 341.053 230.818 336 229.279C325.375 226.044 315.426 221.205 306 215.388C294.539 208.315 284.127 199.296 275.439 189C272.94 186.039 268.838 177.893 264 180.086z"/>
<path fill={colours[2]} d="M300 179L301 180L300 179z"/>
<path fill={colours[0]} d="M418 179L419 180L418 179z"/>
<path fill={colours[2]} d="M462 179L463 180L462 179M99 180L100 181L99 180z"/>
<path fill={colours[0]} d="M151 180L152 181L151 180z"/>
<path fill={colours[2]} d="M152 180L153 181L152 180M229 180L230 181L229 180M262 180L263 181L262 180z"/>
<path fill={colours[3]} d="M268 180L269 181L268 180z"/>
<path fill={colours[2]} d="M301 180L302 181L301 180z"/>
<path fill={colours[0]} d="M420 180L421 181L420 180M463 180L464 181L463 180M98 181L99 182L98 181M149 181L150 182L149 181z"/>
<path fill={colours[2]} d="M150 181L151 182L150 181M228 181L229 182L228 181z"/>
<path fill={colours[0]} d="M261 181L262 182L261 181M269 181L270 182L269 181z"/>
<path fill={colours[2]} d="M302 181L303 182L302 181M421 181L422 182L421 181z"/>
<path fill={colours[0]} d="M464 181L465 182L464 181z"/>
<path fill={colours[2]} d="M96 182L97 183L96 182z"/>
<path fill={colours[0]} d="M147 182L148 183L147 182M227 182L228 183L227 182M270 182L271 183L270 182M303 182L304 183L303 182z"/>
<path fill={colours[2]} d="M423 182L424 183L423 182z"/>
<path fill={colours[0]} d="M145 183L146 184L145 183M304 183L305 184L304 183M425 183L426 184L425 183M93 184L94 185L93 184M143 184L144 185L143 184z"/>
<path fill={colours[3]} d="M224 184L225 185L224 184M259 184L260 185L259 184z"/>
<path fill={colours[2]} d="M271 184L272 185L271 184z"/>
<path fill={colours[0]} d="M305 184L306 185L305 184z"/>
<path fill={colours[3]} d="M426 184L427 185L426 184z"/>
<path fill={colours[0]} d="M427 184L428 185L427 184z"/>
<path fill={colours[2]} d="M468 184L469 185L468 184M91 185L92 186L91 185z"/>
<path fill={colours[0]} d="M141 185L142 186L141 185z"/>
<path fill={colours[2]} d="M223 185L224 186L223 185M258 185L259 186L258 185M272 185L273 186L272 185M428 185L429 186L428 185M469 185L470 186L469 185z"/>
<path fill={colours[0]} d="M90 186L91 187L90 186M139 186L140 187L139 186M222 186L223 187L222 186M257 186L258 187L257 186M273 186L274 187L273 186z"/>
<path fill={colours[3]} d="M308 186L309 187L308 186z"/>
<path fill={colours[0]} d="M430 186L431 187L430 186z"/>
<path fill={colours[2]} d="M470 186L471 187L470 186M88 187L89 188L88 187z"/>
<path fill={colours[0]} d="M137 187L138 188L137 187M256 187L257 188L256 187z"/>
<path fill={colours[2]} d="M309 187L310 188L309 187M431 187L432 188L431 187z"/>
<path fill={colours[0]} d="M471 187L472 188L471 187M87 188L88 189L87 188M135 188L136 189L135 188z"/>
<path fill={colours[2]} d="M219 188L220 189L219 188z"/>
<path fill={colours[0]} d="M310 188L311 189L310 188M433 188L434 189L433 188M472 188L473 189L472 188z"/>
<path fill={colours[2]} d="M85 189L86 190L85 189z"/>
<path fill={colours[0]} d="M133 189L134 190L133 189M218 189L219 190L218 189z"/>
<path fill={colours[3]} d="M275 189L276 190L275 189M312 189L313 190L312 189z"/>
<path fill={colours[2]} d="M434 189L435 190L434 189z"/>
<path fill={colours[0]} d="M473 189L474 190L473 189M84 190L85 191L84 190M131 190L132 191L131 190z"/>
<path fill={colours[2]} d="M132 190L133 191L132 190M216 190L217 191L216 190M276 190L277 191L276 190M313 190L314 191L313 190z"/>
<path fill={colours[0]} d="M436 190L437 191L436 190M474 190L475 191L474 190z"/>
<path fill={colours[2]} d="M82 191L83 192L82 191M130 191L131 192L130 191z"/>
<path fill={colours[0]} d="M215 191L216 192L215 191z"/>
<path fill={colours[2]} d="M277 191L278 192L277 191z"/>
<path fill={colours[0]} d="M314 191L315 192L314 191z"/>
<path fill={colours[2]} d="M437 191L438 192L437 191z"/>
<path fill={colours[0]} d="M81 192L82 193L81 192z"/>
<path fill={colours[2]} d="M128 192L129 193L128 192M213 192L214 193L213 192z"/>
<path fill={colours[3]} d="M252 192L253 193L252 192z"/>
<path fill={colours[0]} d="M278 192L279 193L278 192z"/>
<path fill={colours[2]} d="M316 192L317 193L316 192z"/>
<path fill={colours[3]} d="M438 192L439 193L438 192z"/>
<path fill={colours[2]} d="M79 193L80 194L79 193z"/>
<path fill={colours[0]} d="M126 193L127 194L126 193M212 193L213 194L212 193z"/>
<path fill={colours[3]} d="M251 193L252 194L251 193z"/>
<path fill={colours[0]} d="M279 193L280 194L279 193M317 193L318 194L317 193M440 193L441 194L440 193M78 194L79 195L78 194M124 194L125 195L124 194M210 194L211 195L210 194z"/>
<path fill={colours[3]} d="M250 194L251 195L250 194z"/>
<path fill={colours[0]} d="M280 194L281 195L280 194M319 194L320 195L319 194z"/>
<path fill={colours[2]} d="M441 194L442 195L441 194z"/>
<path fill={colours[0]} d="M122 195L123 196L122 195z"/>
<path fill={colours[2]} d="M123 195L124 196L123 195M208 195L209 196L208 195z"/>
<path fill={colours[3]} d="M249 195L250 196L249 195z"/>
<path fill={colours[0]} d="M281 195L282 196L281 195z"/>
<path fill={colours[2]} d="M321 195L322 196L321 195M442 195L443 196L442 195z"/>
<path fill={colours[0]} d="M479 195L480 196L479 195z"/>
<path fill={colours[2]} d="M75 196L76 197L75 196M121 196L122 197L121 196z"/>
<path fill={colours[0]} d="M207 196L208 197L207 196M282 196L283 197L282 196M322 196L323 197L322 196M480 196L481 197L480 196M74 197L75 198L74 197M119 197L120 198L119 197M205 197L206 198L205 197M283 197L284 198L283 197M324 197L325 198L324 197M445 197L446 198L445 197M481 197L482 198L481 197z"/>
<path fill={colours[2]} d="M72 198L73 199L72 198z"/>
<path fill={colours[0]} d="M117 198L118 199L117 198M203 198L204 199L203 198M245 198L246 199L245 198M284 198L285 199L284 198M326 198L327 199L326 198z"/>
<path fill={colours[2]} d="M446 198L447 199L446 198z"/>
<path fill={colours[0]} d="M482 198L483 199L482 198M71 199L72 200L71 199z"/>
<path fill={colours[2]} d="M116 199L117 200L116 199z"/>
<path fill={colours[0]} d="M201 199L202 200L201 199M244 199L245 200L244 199M285 199L286 200L285 199M328 199L329 200L328 199z"/>
<path fill={colours[2]} d="M447 199L448 200L447 199z"/>
<path fill={colours[0]} d="M483 199L484 200L483 199M70 200L71 201L70 200M114 200L115 201L114 200M199 200L200 201L199 200M243 200L244 201L243 200M286 200L287 201L286 200M330 200L331 201L330 200z"/>
<path fill={colours[3]} d="M448 200L449 201L448 200z"/>
<path fill={colours[2]} d="M484 200L485 201L484 200M68 201L69 202L68 201z"/>
<path fill={colours[0]} d="M112 201L113 202L112 201z"/>
<path fill={colours[2]} d="M196 201L197 202L196 201z"/>
<path fill={colours[0]} d="M197 201L198 202L197 201z"/>
<path fill={colours[2]} d="M242 201L243 202L242 201M287 201L288 202L287 201z"/>
<path fill={colours[0]} d="M332 201L333 202L332 201z"/>
<path fill={colours[2]} d="M333 201L334 202L333 201M485 201L486 202L485 201z"/>
<path fill={colours[0]} d="M67 202L68 203L67 202z"/>
<path fill={colours[2]} d="M111 202L112 203L111 202z"/>
<path fill={colours[0]} d="M194 202L195 203L194 202z"/>
<path fill={colours[3]} d="M241 202L242 203L241 202z"/>
<path fill={colours[2]} d="M288 202L289 203L288 202z"/>
<path fill={colours[0]} d="M335 202L336 203L335 202z"/>
<path fill={colours[2]} d="M486 202L487 203L486 202z"/>
<path fill={colours[0]} d="M109 203L110 204L109 203z"/>
<path fill={colours[2]} d="M191 203L192 204L191 203z"/>
<path fill={colours[0]} d="M192 203L193 204L192 203z"/>
<path fill={colours[2]} d="M289 203L290 204L289 203z"/>
<path fill={colours[0]} d="M337 203L338 204L337 203z"/>
<path fill={colours[2]} d="M338 203L339 204L338 203z"/>
<path fill={colours[0]} d="M452 203L453 204L452 203z"/>
<path fill={colours[2]} d="M487 203L488 204L487 203M64 204L65 205L64 204M108 204L109 205L108 204z"/>
<path fill={colours[3]} d="M188 204L189 205L188 204z"/>
<path fill={colours[0]} d="M189 204L190 205L189 204M238 204L239 205L238 204z"/>
<path fill={colours[3]} d="M290 204L291 205L290 204z"/>
<path fill={colours[0]} d="M340 204L341 205L340 204z"/>
<path fill={colours[2]} d="M341 204L342 205L341 204z"/>
<path fill={colours[0]} d="M453 204L454 205L453 204M63 205L64 206L63 205M106 205L107 206L106 205z"/>
<path fill={colours[3]} d="M185 205L186 206L185 205z"/>
<path fill={colours[0]} d="M186 205L187 206L186 205z"/>
<path fill={colours[2]} d="M237 205L238 206L237 205z"/>
<path fill={colours[0]} d="M343 205L344 206L343 205z"/>
<path fill={colours[2]} d="M344 205L345 206L344 205M454 205L455 206L454 205z"/>
<path fill={colours[0]} d="M62 206L63 207L62 206z"/>
<path fill={colours[3]} d="M105 206L106 207L105 206M182 206L183 207L182 206z"/>
<path fill={colours[0]} d="M183 206L184 207L183 206M235 206L236 207L235 206M293 206L294 207L293 206M347 206L348 207L347 206z"/>
<path fill={colours[2]} d="M348 206L349 207L348 206M455 206L456 207L455 206z"/>
<path fill={colours[0]} d="M103 207L104 208L103 207z"/>
<path fill={colours[2]} d="M179 207L180 208L179 207z"/>
<path fill={colours[0]} d="M180 207L181 208L180 207M234 207L235 208L234 207z"/>
<path fill={colours[2]} d="M294 207L295 208L294 207z"/>
<path fill={colours[0]} d="M350.667 207.333L351.333 207.667L350.667 207.333z"/>
<path fill={colours[2]} d="M352 207L353 208L352 207M456 207L457 208L456 207z"/>
<path fill={colours[0]} d="M490 207L491 208L490 207z"/>
<path fill={colours[2]} d="M59 208L60 209L59 208z"/>
<path fill={colours[0]} d="M101 208L102 209L101 208z"/>
<path fill={colours[3]} d="M102 208L103 209L102 208M174 208L175 209L174 208z"/>
<path fill={colours[2]} d="M175 208L176 209L175 208z"/>
<path fill={colours[0]} d="M176 208L177 209L176 208z"/>
<path fill={colours[3]} d="M233 208L234 209L233 208M295 208L296 209L295 208z"/>
<path fill={colours[0]} d="M354.667 208.333L355.333 208.667L354.667 208.333z"/>
<path fill={colours[2]} d="M356 208L357 209L356 208M457 208L458 209L457 208z"/>
<path fill={colours[0]} d="M491 208L492 209L491 208M58 209L59 210L58 209z"/>
<path fill={colours[2]} d="M100 209L101 210L100 209M170 209L171 210L170 209z"/>
<path fill={colours[0]} d="M171.667 209.333L172.333 209.667L171.667 209.333M231 209L232 210L231 209M297 209L298 210L297 209M359 209L360 210L359 209z"/>
<path fill={colours[2]} d="M360 209L361 210L360 209M458 209L459 210L458 209M492 209L493 210L492 209z"/>
<path fill={colours[0]} d="M57 210L58 211L57 210z"/>
<path fill={colours[3]} d="M99 210L100 211L99 210z"/>
<path fill={colours[2]} d="M166 210L167 211L166 210z"/>
<path fill={colours[0]} d="M167 210L168 211L167 210z"/>
<path fill={colours[2]} d="M230 210L231 211L230 210M298 210L299 211L298 210z"/>
<path fill={colours[0]} d="M363 210L364 211L363 210z"/>
<path fill={colours[2]} d="M364 210L365 211L364 210z"/>
<path fill={colours[3]} d="M365 210L366 211L365 210z"/>
<path fill={colours[2]} d="M459 210L460 211L459 210M493 210L494 211L493 210z"/>
<path fill={colours[0]} d="M97 211L98 212L97 211z"/>
<path fill={colours[2]} d="M161 211L162 212L161 211z"/>
<path fill={colours[0]} d="M162.667 211.333L163.333 211.667L162.667 211.333M229.333 211.667L229.667 212.333L229.333 211.667M367.667 211.333L368.333 211.667L367.667 211.333z"/>
<path fill={colours[2]} d="M369 211L370 212L369 211M460 211L461 212L460 211M54 212L55 213L54 212z"/>
<path fill={colours[3]} d="M96 212L97 213L96 212M155 212L156 213L155 212z"/>
<path fill={colours[2]} d="M156 212L157 213L156 212z"/>
<path fill={colours[0]} d="M157.667 212.333L158.333 212.667L157.667 212.333M301 212L302 213L301 212M371.667 212.333L372.333 212.667L371.667 212.333z"/>
<path fill={colours[2]} d="M373 212L374 213L373 212M461 212L462 213L461 212M53 213L54 214L53 213z"/>
<path fill={colours[0]} d="M94 213L95 214L94 213z"/>
<path fill={colours[2]} d="M149.667 213.333L150.333 213.667L149.667 213.333z"/>
<path fill={colours[0]} d="M151.667 213.333L152.333 213.667L151.667 213.333z"/>
<path fill={colours[2]} d="M229.333 213.667L229.667 214.333L229.333 213.667z"/>
<path fill={colours[3]} d="M302 213L303 214L302 213z"/>
<path fill={colours[0]} d="M375.667 213.333L376.333 213.667L375.667 213.333z"/>
<path fill={colours[2]} d="M377 213L378 214L377 213M462 213L463 214L462 213z"/>
<path fill={colours[0]} d="M495 213L496 214L495 213M52 214L53 215L52 214z"/>
<path fill={colours[2]} d="M93 214L94 215L93 214M142.667 214.333L143.333 214.667L142.667 214.333z"/>
<path fill={colours[0]} d="M144 214L144 215L147 215L144 214M304 214L305 215L304 214M379.667 214.333L380.333 214.667L379.667 214.333z"/>
<path fill={colours[2]} d="M381 214L382 215L381 214M463 214L464 215L463 214z"/>
<path fill={colours[0]} d="M496 214L497 215L496 214M51 215L52 216L51 215M91 215L92 216L91 215z"/>
<path fill={colours[3]} d="M133 215L134 216L133 215z"/>
<path fill={colours[2]} d="M134.667 215.333L135.333 215.667L134.667 215.333z"/>
<path fill={colours[0]} d="M136 215L136 216L140 216L136 215M306 215L307 216L306 215M383.667 215.333L384.333 215.667L383.667 215.333z"/>
<path fill={colours[2]} d="M385 215L386 216L385 215M464 215L465 216L464 215M497 215L498 216L497 215z"/>
<path fill={colours[0]} d="M50 216L51 217L50 216z"/>
<path fill={colours[2]} d="M90 216L91 217L90 216M121 216L121 217L125 217L121 216z"/>
<path fill={colours[0]} d="M125 216L125 217L130 217L125 216z"/>
<path fill={colours[2]} d="M307 216L308 217L307 216z"/>
<path fill={colours[0]} d="M387 216L388 217L387 216z"/>
<path fill={colours[2]} d="M388 216L389 217L388 216z"/>
<path fill={colours[3]} d="M389 216L390 217L389 216z"/>
<path fill={colours[2]} d="M465 216L466 217L465 216z"/>
<path fill={colours[0]} d="M49 217L50 218L49 217M92 217L92 218L115 218L92 217M228.333 217.667L228.667 218.333L228.333 217.667M309 217L310 218L309 217M391 217L392 218L391 217z"/>
<path fill={colours[2]} d="M392 217L393 218L392 217z"/>
<path fill={colours[3]} d="M393 217L394 218L393 217z"/>
<path fill={colours[0]} d="M466 217L467 218L466 217M311 218L312 219L311 218M395 218L396 219L395 218z"/>
<path fill={colours[2]} d="M396 218L397 219L396 218z"/>
<path fill={colours[0]} d="M467 218L468 219L467 218M499 218L500 219L499 218M48 219L49 220L48 219z"/>
<path fill={colours[2]} d="M228 219L229 220L228 219M312 219L313 220L312 219z"/>
<path fill={colours[0]} d="M313 219L314 220L313 219M399 219L400 220L399 219z"/>
<path fill={colours[2]} d="M400 219L401 220L400 219z"/>
<path fill={colours[0]} d="M468 219L469 220L468 219z"/>
<path fill={colours[2]} d="M500 219L501 220L500 219z"/>
<path fill={colours[3]} d="M228 220L229 221L228 220z"/>
<path fill={colours[2]} d="M314 220L315 221L314 220z"/>
<path fill={colours[0]} d="M402 220L403 221L402 220z"/>
<path fill={colours[2]} d="M403 220L404 221L403 220z"/>
<path fill={colours[3]} d="M404 220L405 221L404 220z"/>
<path fill={colours[2]} d="M47 221L48 222L47 221M316 221L317 222L316 221z"/>
<path fill={colours[0]} d="M317 221L318 222L317 221M406 221L407 222L406 221z"/>
<path fill={colours[2]} d="M407 221L408 222L407 221z"/>
<path fill={colours[0]} d="M47.3333 222.667L47.6667 223.333L47.3333 222.667z"/>
<path fill={colours[2]} d="M318 222L319 223L318 222z"/>
<path fill={colours[0]} d="M319 222L320 223L319 222M410 222L411 223L410 222z"/>
<path fill={colours[2]} d="M411 222L412 223L411 222z"/>
<path fill={colours[3]} d="M470 222L471 223L470 222z"/>
<path fill={colours[0]} d="M502 222L503 223L502 222z"/>
<path fill={colours[2]} d="M320 223L321 224L320 223z"/>
<path fill={colours[0]} d="M321 223L322 224L321 223M413 223L414 224L413 223z"/>
<path fill={colours[2]} d="M414 223L415 224L414 223z"/>
<path fill={colours[3]} d="M471 223L472 224L471 223z"/>
<path fill={colours[2]} d="M503 223L504 224L503 223z"/>
<path fill={colours[0]} d="M203 224L204 225L203 224M227 224L227 227L228 227L227 224M323 224L324 225L323 224M417 224L418 225L417 224z"/>
<path fill={colours[2]} d="M418 224L419 225L418 224M472 224L473 225L472 224z"/>
<path fill={colours[0]} d="M201 225L202 226L201 225z"/>
<path fill={colours[1]} d="M202 225C176.807 238.275 142.853 240.003 115 241.911C105.536 242.559 88.6885 237.919 80.3256 241.603C74.3243 244.247 69.8942 255.532 66.8611 261C55.752 281.029 46.8167 305.013 45 328C58.1424 320.712 70.4116 313.591 85 309.427C115.413 300.749 144.612 299.811 173 283.573C182.583 278.091 197.073 271.096 203.357 261.714C205.212 258.944 204.213 255.109 203.925 252C203.179 243.939 205.083 232.348 202 225z"/>
<path fill={colours[2]} d="M203 225L203 229L204 229L203 225M325 225L326 226L325 225z"/>
<path fill={colours[0]} d="M420 225L421 226L420 225z"/>
<path fill={colours[2]} d="M421 225L422 226L421 225z"/>
<path fill={colours[0]} d="M473 225L474 226L473 225M198 226L199 227L198 226z"/>
<path fill={colours[2]} d="M199 226L200 227L199 226z"/>
<path fill={colours[0]} d="M328 226L329 227L328 226M424 226L425 227L424 226z"/>
<path fill={colours[2]} d="M425 226L426 227L425 226z"/>
<path fill={colours[0]} d="M474 226L475 227L474 226M505 226L506 227L505 226M47 227L48 228L47 227M195 227L196 228L195 227z"/>
<path fill={colours[2]} d="M196 227L197 228L196 227M227 227L227 231L228 231L227 227M330 227L331 228L330 227z"/>
<path fill={colours[0]} d="M331 227L332 228L331 227M427 227L428 228L427 227z"/>
<path fill={colours[2]} d="M428 227L429 228L428 227M506 227L507 228L506 227M47 228L48 229L47 228z"/>
<path fill={colours[0]} d="M192 228L193 229L192 228z"/>
<path fill={colours[2]} d="M193 228L194 229L193 228M333 228L334 229L333 228z"/>
<path fill={colours[0]} d="M334 228L335 229L334 228z"/>
<path fill={colours[2]} d="M431 228L432 229L431 228z"/>
<path fill={colours[3]} d="M432 228L433 229L432 228z"/>
<path fill={colours[0]} d="M189 229L190 230L189 229z"/>
<path fill={colours[2]} d="M190 229L191 230L190 229z"/>
<path fill={colours[0]} d="M203 229L203 245L204 245L203 229z"/>
<path fill={colours[2]} d="M336 229L337 230L336 229z"/>
<path fill={colours[0]} d="M337 229L338 230L337 229M434 229L435 230L434 229z"/>
<path fill={colours[2]} d="M435 229L436 230L435 229M476 229L477 230L476 229z"/>
<path fill={colours[0]} d="M507 229L508 230L507 229M48 230L49 231L48 230M186 230L187 231L186 230z"/>
<path fill={colours[2]} d="M187 230L188 231L187 230M340 230L341 231L340 230z"/>
<path fill={colours[0]} d="M341 230L342 231L341 230M437 230L438 231L437 230z"/>
<path fill={colours[2]} d="M438 230L439 231L438 230M477 230L478 231L477 230M508 230L509 231L508 230z"/>
<path fill={colours[0]} d="M182 231L183 232L182 231z"/>
<path fill={colours[2]} d="M183 231L184 232L183 231M343 231L344 232L343 231z"/>
<path fill={colours[0]} d="M344.667 231.333L345.333 231.667L344.667 231.333M440 231L441 232L440 231z"/>
<path fill={colours[2]} d="M441 231L442 232L441 231z"/>
<path fill={colours[0]} d="M478 231L479 232L478 231M178 232L179 233L178 232z"/>
<path fill={colours[2]} d="M179 232L180 233L179 232z"/>
<path fill={colours[3]} d="M347 232L348 233L347 232z"/>
<path fill={colours[2]} d="M348 232L349 233L348 232z"/>
<path fill={colours[0]} d="M349 232L350 233L349 232M444 232L445 233L444 232z"/>
<path fill={colours[3]} d="M445 232L446 233L445 232z"/>
<path fill={colours[0]} d="M509 232L510 233L509 232M173.667 233.333L174.333 233.667L173.667 233.333z"/>
<path fill={colours[2]} d="M175 233L176 234L175 233z"/>
<path fill={colours[3]} d="M351 233L352 234L351 233z"/>
<path fill={colours[2]} d="M352 233L353 234L352 233z"/>
<path fill={colours[0]} d="M353 233L354 234L353 233M447 233L448 234L447 233z"/>
<path fill={colours[2]} d="M448 233L449 234L448 233M510 233L511 234L510 233z"/>
<path fill={colours[0]} d="M169 234L170 235L169 234z"/>
<path fill={colours[2]} d="M170.667 234.333L171.333 234.667L170.667 234.333M354 234L355 235L354 234z"/>
<path fill={colours[0]} d="M450 234L451 235L450 234z"/>
<path fill={colours[2]} d="M451 234L452 235L451 234M480 234L481 235L480 234z"/>
<path fill={colours[0]} d="M164 235L165 236L164 235z"/>
<path fill={colours[2]} d="M165.667 235.333L166.333 235.667L165.667 235.333M454 235L455 236L454 235z"/>
<path fill={colours[0]} d="M481 235L482 236L481 235M511 235L512 236L511 235z"/>
<path fill={colours[2]} d="M52 236L53 237L52 236z"/>
<path fill={colours[0]} d="M158.667 236.333L159.333 236.667L158.667 236.333z"/>
<path fill={colours[2]} d="M160 236L161 237L160 236z"/>
<path fill={colours[3]} d="M161 236L162 237L161 236z"/>
<path fill={colours[0]} d="M355 236L356 237L355 236z"/>
<path fill={colours[2]} d="M457 236L458 237L457 236z"/>
<path fill={colours[0]} d="M482 236L483 237L482 236z"/>
<path fill={colours[2]} d="M512 236L513 237L512 236z"/>
<path fill={colours[0]} d="M152.667 237.333L153.333 237.667L152.667 237.333z"/>
<path fill={colours[2]} d="M154.667 237.333L155.333 237.667L154.667 237.333M355 237L356 238L355 237M460 237L461 238L460 237z"/>
<path fill={colours[0]} d="M51 238L52 239L51 238M144 238L144 239L148 239L144 238z"/>
<path fill={colours[2]} d="M148 238L149 239L148 238z"/>
<path fill={colours[3]} d="M149 238L150 239L149 238z"/>
<path fill={colours[2]} d="M463 238L464 239L463 238M483 238L484 239L483 238z"/>
<path fill={colours[0]} d="M513 238L514 239L513 238M136 239L136 240L140 240L136 239z"/>
<path fill={colours[2]} d="M140.667 239.333L141.333 239.667L140.667 239.333z"/>
<path fill={colours[0]} d="M356 239L357 240L356 239z"/>
<path fill={colours[2]} d="M466 239L467 240L466 239z"/>
<path fill={colours[3]} d="M467 239L468 240L467 239z"/>
<path fill={colours[0]} d="M484 239L485 240L484 239z"/>
<path fill={colours[2]} d="M514 239L515 240L514 239z"/>
<path fill={colours[0]} d="M50 240L51 241L50 240M79 240L79 241L84 241L79 240M124 240L124 241L130 241L124 240z"/>
<path fill={colours[2]} d="M130 240L130 241L133 241L130 240z"/>
<path fill={colours[3]} d="M356 240L357 241L356 240z"/>
<path fill={colours[0]} d="M383 240L384 241L383 240M469 240L470 241L469 240z"/>
<path fill={colours[3]} d="M470 240L471 241L470 240z"/>
<path fill={colours[2]} d="M49 241L50 242L49 241M88 241L88 242L93 242L88 241z"/>
<path fill={colours[0]} d="M93 241L93 242L113 242L93 241z"/>
<path fill={colours[2]} d="M113 241L113 242L119 242L113 241M227 241L227 247L228 247L227 241z"/>
<path fill={colours[1]} d="M383 241C385.748 255.169 386.749 268.654 384.715 283C383.759 289.735 378.553 299.503 381.242 306C387.751 321.726 405.594 334.016 419 343.411C455.339 368.877 498.118 386.831 540 401C538.431 364.549 512.326 324.694 491.859 296C485.143 286.584 477.631 272.337 467.91 265.843C460.32 260.772 448.689 258.887 440 256.28C421.57 250.751 402.092 243.571 383 241z"/>
<path fill={colours[2]} d="M386 241L387 242L386 241z"/>
<path fill={colours[0]} d="M387 241L388 242L387 241M472 241L473 242L472 241z"/>
<path fill={colours[3]} d="M473 241L474 242L473 241M485 241L486 242L485 241z"/>
<path fill={colours[0]} d="M515 241L516 242L515 241z"/>
<path fill={colours[2]} d="M78 242L79 243L78 242z"/>
<path fill={colours[0]} d="M357 242L358 243L357 242z"/>
<path fill={colours[2]} d="M390 242L391 243L390 242z"/>
<path fill={colours[0]} d="M391 242L392 243L391 242M475 242L476 243L475 242z"/>
<path fill={colours[3]} d="M476 242L477 243L476 242z"/>
<path fill={colours[2]} d="M486 242L487 243L486 242M516 242L517 243L516 242z"/>
<path fill={colours[0]} d="M48 243L49 244L48 243M77 243L78 244L77 243z"/>
<path fill={colours[2]} d="M357 243L358 244L357 243M383 243L384 244L383 243M394 243L395 244L394 243z"/>
<path fill={colours[0]} d="M395 243L396 244L395 243M478 243L479 244L478 243z"/>
<path fill={colours[3]} d="M479 243L480 244L479 243z"/>
<path fill={colours[0]} d="M487 243L488 244L487 243M383.333 244.667L383.667 245.333L383.333 244.667z"/>
<path fill={colours[3]} d="M397 244L398 245L397 244z"/>
<path fill={colours[2]} d="M398 244L399 245L398 244z"/>
<path fill={colours[0]} d="M399 244L400 245L399 244M481 244L482 245L481 244z"/>
<path fill={colours[3]} d="M482 244L483 245L482 244z"/>
<path fill={colours[0]} d="M517 244L518 245L517 244M47 245L48 246L47 245z"/>
<path fill={colours[2]} d="M76 245L77 246L76 245M203 245L203 249L204 249L203 245z"/>
<path fill={colours[0]} d="M358.333 245.667L358.667 246.333L358.333 245.667z"/>
<path fill={colours[2]} d="M401 245L402 246L401 245z"/>
<path fill={colours[0]} d="M402.667 245.333L403.333 245.667L402.667 245.333M484 245L485 246L484 245z"/>
<path fill={colours[3]} d="M485 245L486 246L485 245z"/>
<path fill={colours[2]} d="M488.333 245.667L488.667 246.333L488.333 245.667z"/>
<path fill={colours[0]} d="M75 246L76 247L75 246z"/>
<path fill={colours[2]} d="M405 246L406 247L405 246z"/>
<path fill={colours[0]} d="M406 246L407 247L406 246M487 246L488 247L487 246M489 246L490 247L489 246M518 246L519 247L518 246M46 247L47 248L46 247M227 247L227 250L228 250L227 247z"/>
<path fill={colours[2]} d="M358 247L359 248L358 247M409 247L410 248L409 247z"/>
<path fill={colours[0]} d="M410 247L411 248L410 247z"/>
<path fill={colours[2]} d="M519 247L520 248L519 247M45 248L46 249L45 248M74 248L75 249L74 248M384.333 248.667L384.667 249.333L384.333 248.667z"/>
<path fill={colours[3]} d="M412 248L413 249L412 248z"/>
<path fill={colours[2]} d="M413 248L414 249L413 248z"/>
<path fill={colours[0]} d="M414 248L415 249L414 248M45 249L46 250L45 249M73 249L74 250L73 249z"/>
<path fill={colours[2]} d="M416 249L417 250L416 249z"/>
<path fill={colours[0]} d="M417 249L418 250L417 249z"/>
<path fill={colours[2]} d="M520 249L521 250L520 249M44 250L45 251L44 250z"/>
<path fill={colours[0]} d="M359 250L360 251L359 250M384 250L384 253L385 253L384 250z"/>
<path fill={colours[2]} d="M420 250L421 251L420 250z"/>
<path fill={colours[0]} d="M421 250L422 251L421 250z"/>
<path fill={colours[2]} d="M72 251L73 252L72 251M359.333 251.667L359.667 252.333L359.333 251.667z"/>
<path fill={colours[3]} d="M423 251L424 252L423 251z"/>
<path fill={colours[0]} d="M424 251L425 252L424 251M521 251L522 252L521 251z"/>
<path fill={colours[2]} d="M43 252L44 253L43 252z"/>
<path fill={colours[0]} d="M71 252L72 253L71 252M204 252L204 256L205 256L204 252z"/>
<path fill={colours[2]} d="M427 252L428 253L427 252z"/>
<path fill={colours[0]} d="M428 252L429 253L428 252z"/>
<path fill={colours[2]} d="M228.333 253.667L228.667 254.333L228.333 253.667z"/>
<path fill={colours[3]} d="M359 253L360 254L359 253M430 253L431 254L430 253z"/>
<path fill={colours[0]} d="M431 253L432 254L431 253M522 253L523 254L522 253z"/>
<path fill={colours[2]} d="M42 254L43 255L42 254z"/>
<path fill={colours[0]} d="M70 254L71 255L70 254z"/>
<path fill={colours[2]} d="M434 254L435 255L434 254z"/>
<path fill={colours[0]} d="M435 254L436 255L435 254z"/>
<path fill={colours[2]} d="M523 254L524 255L523 254z"/>
<path fill={colours[0]} d="M228.333 255.667L228.667 256.333L228.333 255.667z"/>
<path fill={colours[2]} d="M437 255L438 256L437 255z"/>
<path fill={colours[0]} d="M438 255L439 256L438 255z"/>
<path fill={colours[2]} d="M41 256L42 257L41 256M69 256L70 257L69 256M204.333 256.667L204.667 257.333L204.333 256.667z"/>
<path fill={colours[0]} d="M360 256L360 260L361 260L360 256z"/>
<path fill={colours[2]} d="M385 256L385 259L386 259L385 256z"/>
<path fill={colours[3]} d="M440 256L441 257L440 256z"/>
<path fill={colours[0]} d="M441 256L442 257L441 256z"/>
<path fill={colours[2]} d="M524 256L525 257L524 256z"/>
<path fill={colours[0]} d="M68 257L69 258L68 257z"/>
<path fill={colours[2]} d="M444 257L445 258L444 257z"/>
<path fill={colours[0]} d="M445 257L446 258L445 257z"/>
<path fill={colours[2]} d="M40 258L41 259L40 258z"/>
<path fill={colours[3]} d="M68 258L69 259L68 258z"/>
<path fill={colours[2]} d="M447 258L448 259L447 258z"/>
<path fill={colours[0]} d="M448 258L449 259L448 258M525 258L526 259L525 258M40 259L41 260L40 259M67 259L68 260L67 259z"/>
<path fill={colours[2]} d="M229 259L230 260L229 259z"/>
<path fill={colours[0]} d="M385 259L385 273L386 273L385 259z"/>
<path fill={colours[2]} d="M450 259L451 260L450 259z"/>
<path fill={colours[0]} d="M451 259L452 260L451 259z"/>
<path fill={colours[2]} d="M39 260L40 261L39 260z"/>
<path fill={colours[0]} d="M229.333 260.667L229.667 261.333L229.333 260.667z"/>
<path fill={colours[2]} d="M360 260L360 272L361 272L360 260M453 260L454 261L453 260z"/>
<path fill={colours[0]} d="M454 260L455 261L454 260M526 260L527 261L526 260M39 261L40 262L39 261M66 261L67 262L66 261M204 261L205 262L204 261z"/>
<path fill={colours[3]} d="M456 261L457 262L456 261z"/>
<path fill={colours[0]} d="M457 261L458 262L457 261z"/>
<path fill={colours[3]} d="M459 262L460 263L459 262z"/>
<path fill={colours[0]} d="M460 262L461 263L460 262M527 262L528 263L527 262M38 263L39 264L38 263z"/>
<path fill={colours[2]} d="M65 263L66 264L65 263z"/>
<path fill={colours[3]} d="M201 263L202 264L201 263z"/>
<path fill={colours[2]} d="M462 263L463 264L462 263z"/>
<path fill={colours[0]} d="M463 263L464 264L463 263z"/>
<path fill={colours[3]} d="M200 264L201 265L200 264z"/>
<path fill={colours[2]} d="M230 264L231 265L230 264M465 264L466 265L465 264z"/>
<path fill={colours[0]} d="M466 264L467 265L466 264M528 264L529 265L528 264M37 265L38 266L37 265z"/>
<path fill={colours[2]} d="M64 265L65 266L64 265M199 265L200 266L199 265z"/>
<path fill={colours[0]} d="M230 265L231 266L230 265M468 265L469 266L468 265M198 266L199 267L198 266M469 266L470 267L469 266M529 266L530 267L529 266z"/>
<path fill={colours[2]} d="M36 267L37 268L36 267M63 267L64 268L63 267z"/>
<path fill={colours[0]} d="M36 268L37 269L36 268z"/>
<path fill={colours[2]} d="M195 268L196 269L195 268M231 268L232 269L231 268z"/>
<path fill={colours[0]} d="M530 268L531 269L530 268z"/>
<path fill={colours[2]} d="M62 269L63 270L62 269M194 269L195 270L194 269z"/>
<path fill={colours[0]} d="M231 269L232 270L231 269z"/>
<path fill={colours[3]} d="M471 269L472 270L471 269z"/>
<path fill={colours[0]} d="M35 270L36 271L35 270M193 270L194 271L193 270z"/>
<path fill={colours[2]} d="M472 270L473 271L472 270M531 270L532 271L531 270M61 271L62 272L61 271M191 271L192 272L191 271M232 271L233 272L232 271z"/>
<path fill={colours[0]} d="M473 271L474 272L473 271z"/>
<path fill={colours[2]} d="M34 272L35 273L34 272z"/>
<path fill={colours[0]} d="M190 272L191 273L190 272M232 272L233 273L232 272M360 272L360 276L361 276L360 272M474 272L475 273L474 272M60 273L61 274L60 273z"/>
<path fill={colours[2]} d="M385 273L385 277L386 277L385 273z"/>
<path fill={colours[0]} d="M532.333 273.667L532.667 274.333L532.333 273.667z"/>
<path fill={colours[2]} d="M187 274L188 275L187 274z"/>
<path fill={colours[3]} d="M233 274L234 275L233 274M475 274L476 275L475 274z"/>
<path fill={colours[0]} d="M33 275L34 276L33 275M59 275L60 276L59 275M186 275L187 276L186 275M233 275L234 276L233 275z"/>
<path fill={colours[2]} d="M476 275L477 276L476 275M184 276L185 277L184 276z"/>
<path fill={colours[0]} d="M477 276L478 277L477 276z"/>
<path fill={colours[2]} d="M32 277L33 278L32 277z"/>
<path fill={colours[0]} d="M58 277L59 278L58 277M183 277L184 278L183 277z"/>
<path fill={colours[2]} d="M234 277L235 278L234 277z"/>
<path fill={colours[0]} d="M32 278L33 279L32 278z"/>
<path fill={colours[2]} d="M58 278L59 279L58 278z"/>
<path fill={colours[0]} d="M181 278L182 279L181 278M234 278L235 279L234 278z"/>
<path fill={colours[2]} d="M179 279L180 280L179 279M359.333 279.667L359.667 280.333L359.333 279.667z"/>
<path fill={colours[0]} d="M384 279L384 283L385 283L384 279z"/>
<path fill={colours[2]} d="M479 279L480 280L479 279z"/>
<path fill={colours[0]} d="M532 279L533 280L532 279z"/>
<path fill={colours[2]} d="M31 280L32 281L31 280M57 280L58 281L57 280z"/>
<path fill={colours[0]} d="M178 280L179 281L178 280z"/>
<path fill={colours[2]} d="M235 280L236 281L235 280M480 280L481 281L480 280M532 280L533 281L532 280z"/>
<path fill={colours[0]} d="M31 281L32 282L31 281z"/>
<path fill={colours[2]} d="M176 281L177 282L176 281z"/>
<path fill={colours[0]} d="M359.333 281.667L359.667 282.333L359.333 281.667M481 281L482 282L481 281M56 282L57 283L56 282z"/>
<path fill={colours[2]} d="M174 282L175 283L174 282M236 282L237 283L236 282z"/>
<path fill={colours[0]} d="M531 282L532 283L531 282z"/>
<path fill={colours[2]} d="M30 283L31 284L30 283z"/>
<path fill={colours[3]} d="M56 283L57 284L56 283z"/>
<path fill={colours[0]} d="M173 283L174 284L173 283M236 283L237 284L236 283z"/>
<path fill={colours[2]} d="M384.333 283.667L384.667 284.333L384.333 283.667z"/>
<path fill={colours[3]} d="M482 283L483 284L482 283z"/>
<path fill={colours[0]} d="M171 284L172 285L171 284z"/>
<path fill={colours[3]} d="M358 284L359 285L358 284z"/>
<path fill={colours[2]} d="M483 284L484 285L483 284M530 284L531 285L530 284M55 285L56 286L55 285z"/>
<path fill={colours[0]} d="M169 285L170 286L169 285M237 285L238 286L237 285z"/>
<path fill={colours[2]} d="M358 285L359 286L358 285z"/>
<path fill={colours[0]} d="M484 285L485 286L484 285z"/>
<path fill={colours[2]} d="M529 285L530 286L529 285M29 286L30 287L29 286M167 286L168 287L167 286z"/>
<path fill={colours[0]} d="M358 286L359 287L358 286z"/>
<path fill={colours[2]} d="M528 286L529 287L528 286z"/>
<path fill={colours[0]} d="M29 287L30 288L29 287M54 287L55 288L54 287z"/>
<path fill={colours[2]} d="M165 287L166 288L165 287M211 287C199.97 296.48 187.103 304.115 174 310.363C168.258 313.101 156.777 315.999 153.14 321.343C147.796 329.195 147.817 343.823 146.834 353C143.205 386.899 151.286 422.75 168.8 452C174.878 462.151 183.527 478.518 195 483C202.523 471.019 212.879 460.557 220.936 448.83C233.484 430.567 243.555 410.587 251.644 390C255.192 380.971 264.492 364.023 262.596 354.21C261.473 348.401 249.153 341.148 245.039 336.989C235.582 327.426 227.791 316.588 221.012 305C217.633 299.225 215.732 291.723 211 287z"/>
<path fill={colours[0]} d="M212 287L213 288L212 287M238 287L239 288L238 287M383 287L384 288L383 287z"/>
<path fill={colours[3]} d="M485 287L486 288L485 287z"/>
<path fill={colours[0]} d="M526 287L527 288L526 287z"/>
<path fill={colours[2]} d="M54 288L55 289L54 288M163 288L164 289L163 288z"/>
<path fill={colours[0]} d="M209 288L210 289L209 288z"/>
<path fill={colours[2]} d="M383 288L384 289L383 288M486 288L487 289L486 288z"/>
<path fill={colours[0]} d="M516 288L517 289L516 288z"/>
<path fill={colours[2]} d="M517.333 288.667L517.667 289.333L517.333 288.667z"/>
<path fill={colours[0]} d="M518 288L519 289L518 288M523 288L524 289L523 288z"/>
<path fill={colours[2]} d="M524 288L525 289L524 288M28 289L29 290L28 289z"/>
<path fill={colours[0]} d="M161 289L162 290L161 289M208 289L209 290L208 289M213 289L214 290L213 289M239 289L240 290L239 289z"/>
<path fill={colours[2]} d="M357 289L358 290L357 289z"/>
<path fill={colours[3]} d="M383 289L384 290L383 289z"/>
<path fill={colours[0]} d="M487 289L488 290L487 289M28 290L29 291L28 290M53 290L54 291L53 290M159 290L160 291L159 290M357 290L358 291L357 290z"/>
<path fill={colours[2]} d="M53 291L54 292L53 291z"/>
<path fill={colours[3]} d="M156 291L157 292L156 291z"/>
<path fill={colours[0]} d="M157 291L158 292L157 291M205 291L206 292L205 291M240 291L241 292L240 291M382.333 291.667L382.667 292.333L382.333 291.667z"/>
<path fill={colours[2]} d="M488 291L489 292L488 291z"/>
<path fill={colours[0]} d="M518 291L519 292L518 291z"/>
<path fill={colours[2]} d="M27 292L28 293L27 292M154 292L155 293L154 292z"/>
<path fill={colours[0]} d="M155 292L156 293L155 292z"/>
<path fill={colours[3]} d="M356 292L357 293L356 292z"/>
<path fill={colours[0]} d="M489 292L490 293L489 292z"/>
<path fill={colours[2]} d="M519 292L520 293L519 292z"/>
<path fill={colours[0]} d="M27 293L28 294L27 293M52 293L53 294L52 293M152 293L153 294L152 293z"/>
<path fill={colours[3]} d="M203 293L204 294L203 293z"/>
<path fill={colours[0]} d="M241 293L242 294L241 293M356 293L357 294L356 293z"/>
<path fill={colours[2]} d="M382 293L383 294L382 293M52 294L53 295L52 294M149 294L150 295L149 294z"/>
<path fill={colours[0]} d="M150 294L151 295L150 294M201 294L202 295L201 294M215 294L216 295L215 294z"/>
<path fill={colours[3]} d="M490 294L491 295L490 294z"/>
<path fill={colours[0]} d="M520 294L521 295L520 294M147 295L148 296L147 295M242 295L243 296L242 295z"/>
<path fill={colours[2]} d="M491 295L492 296L491 295M26 296L27 297L26 296z"/>
<path fill={colours[0]} d="M51 296L52 297L51 296z"/>
<path fill={colours[2]} d="M144 296L145 297L144 296z"/>
<path fill={colours[0]} d="M198 296L199 297L198 296M216 296L217 297L216 296z"/>
<path fill={colours[2]} d="M243 296L244 297L243 296M355 296L356 297L355 296z"/>
<path fill={colours[0]} d="M381 296L382 297L381 296M492 296L493 297L492 296M26 297L27 298L26 297z"/>
<path fill={colours[2]} d="M51 297L52 298L51 297M141 297L142 298L141 297z"/>
<path fill={colours[0]} d="M142 297L143 298L142 297z"/>
<path fill={colours[3]} d="M381 297L382 298L381 297z"/>
<path fill={colours[2]} d="M522 297L523 298L522 297M138 298L139 299L138 298z"/>
<path fill={colours[0]} d="M139 298L140 299L139 298M195 298L196 299L195 298M217 298L218 299L217 298M244 298L245 299L244 298z"/>
<path fill={colours[3]} d="M354 298L355 299L354 298z"/>
<path fill={colours[2]} d="M493 298L494 299L493 298z"/>
<path fill={colours[0]} d="M50 299L51 300L50 299z"/>
<path fill={colours[2]} d="M135 299L136 300L135 299z"/>
<path fill={colours[0]} d="M193 299L194 300L193 299z"/>
<path fill={colours[3]} d="M194 299L195 300L194 299M245 299L246 300L245 299z"/>
<path fill={colours[0]} d="M354 299L355 300L354 299M380 299L381 300L380 299M494 299L495 300L494 299M523 299L524 300L523 299z"/>
<path fill={colours[2]} d="M25 300L26 301L25 300M50 300L51 301L50 300M131 300L132 301L131 300z"/>
<path fill={colours[0]} d="M132 300L133 301L132 300M218 300L219 301L218 300z"/>
<path fill={colours[2]} d="M380 300L381 301L380 300M524 300L525 301L524 300z"/>
<path fill={colours[0]} d="M25 301L26 302L25 301z"/>
<path fill={colours[3]} d="M50 301L51 302L50 301z"/>
<path fill={colours[2]} d="M127 301L128 302L127 301z"/>
<path fill={colours[0]} d="M128.667 301.333L129.333 301.667L128.667 301.333M190 301L191 302L190 301z"/>
<path fill={colours[2]} d="M246 301L247 302L246 301M353 301L354 302L353 301M495 301L496 302L495 301M123 302L124 303L123 302z"/>
<path fill={colours[0]} d="M124.667 302.333L125.333 302.667L124.667 302.333M219 302L220 303L219 302M353 302L354 303L353 302M379.333 302.667L379.667 303.333L379.333 302.667M496 302L497 303L496 302M525 302L526 303L525 302M49 303L50 304L49 303z"/>
<path fill={colours[2]} d="M118.667 303.333L119.333 303.667L118.667 303.333z"/>
<path fill={colours[0]} d="M120 303L121 304L120 303z"/>
<path fill={colours[2]} d="M24 304L25 305L24 304M49 304L50 305L49 304M113 304L114 305L113 304z"/>
<path fill={colours[0]} d="M114.667 304.333L115.333 304.667L114.667 304.333M185 304L186 305L185 304M220 304L221 305L220 304M248 304L249 305L248 304M352 304L353 305L352 304z"/>
<path fill={colours[2]} d="M497 304L498 305L497 304z"/>
<path fill={colours[0]} d="M526 304L527 305L526 304M24 305L25 306L24 305z"/>
<path fill={colours[3]} d="M105 305L106 306L105 305z"/>
<path fill={colours[2]} d="M106.667 305.333L107.333 305.667L106.667 305.333z"/>
<path fill={colours[0]} d="M108 305L108 306L111 306L108 305M183 305L184 306L183 305z"/>
<path fill={colours[3]} d="M184 305L185 306L184 305z"/>
<path fill={colours[2]} d="M249 305L250 306L249 305z"/>
<path fill={colours[0]} d="M380 305L381 306L380 305M498 305L499 306L498 305z"/>
<path fill={colours[2]} d="M527 305L528 306L527 305z"/>
<path fill={colours[0]} d="M48.3333 306.667L48.6667 307.333L48.3333 306.667z"/>
<path fill={colours[2]} d="M98.6667 306.333L99.3333 306.667L98.6667 306.333z"/>
<path fill={colours[0]} d="M100 306L100 307L103 307L100 306M181 306L182 307L181 306z"/>
<path fill={colours[2]} d="M351 306L352 307L351 306z"/>
<path fill={colours[3]} d="M381 306L382 307L381 306M92 307L93 308L92 307z"/>
<path fill={colours[2]} d="M93 307L94 308L93 307z"/>
<path fill={colours[0]} d="M94.6667 307.333L95.3333 307.667L94.6667 307.333M222 307L223 308L222 307M250 307L251 308L250 307M381 307L382 308L381 307z"/>
<path fill={colours[2]} d="M499 307L500 308L499 307z"/>
<path fill={colours[0]} d="M528 307L529 308L528 307z"/>
<path fill={colours[2]} d="M23 308L24 309L23 308M48 308L49 309L48 308z"/>
<path fill={colours[3]} d="M88 308L89 309L88 308z"/>
<path fill={colours[2]} d="M89 308L90 309L89 308z"/>
<path fill={colours[0]} d="M90 308L91 309L90 308z"/>
<path fill={colours[2]} d="M251 308L252 309L251 308M350 308L351 309L350 308M382 308L383 309L382 308z"/>
<path fill={colours[0]} d="M500 308L501 309L500 308M23.3333 309.667L23.6667 310.333L23.3333 309.667z"/>
<path fill={colours[3]} d="M84 309L85 310L84 309z"/>
<path fill={colours[2]} d="M85 309L86 310L85 309z"/>
<path fill={colours[0]} d="M86 309L87 310L86 309M176 309L177 310L176 309M223 309L224 310L223 309z"/>
<path fill={colours[3]} d="M252 309L253 310L252 309z"/>
<path fill={colours[0]} d="M350 309L351 310L350 309M529 309L530 310L529 309z"/>
<path fill={colours[2]} d="M81 310L82 311L81 310z"/>
<path fill={colours[0]} d="M82 310L83 311L82 310M383 310L384 311L383 310z"/>
<path fill={colours[2]} d="M501 310L502 311L501 310M530 310L531 311L530 310z"/>
<path fill={colours[0]} d="M47 311L48 312L47 311z"/>
<path fill={colours[2]} d="M78 311L79 312L78 311z"/>
<path fill={colours[0]} d="M79 311L80 312L79 311M349 311L350 312L349 311z"/>
<path fill={colours[2]} d="M384 311L385 312L384 311z"/>
<path fill={colours[0]} d="M502 311L503 312L502 311z"/>
<path fill={colours[2]} d="M47 312L48 313L47 312M75 312L76 313L75 312z"/>
<path fill={colours[0]} d="M76 312L77 313L76 312M225 312L226 313L225 312M254 312L255 313L254 312z"/>
<path fill={colours[3]} d="M385 312L386 313L385 312z"/>
<path fill={colours[0]} d="M531 312L532 313L531 312z"/>
<path fill={colours[2]} d="M22 313L23 314L22 313z"/>
<path fill={colours[3]} d="M72 313L73 314L72 313z"/>
<path fill={colours[0]} d="M73 313L74 314L73 313M167 313L168 314L167 313M255 313L256 314L255 313M348 313L349 314L348 313z"/>
<path fill={colours[2]} d="M503 313L504 314L503 313z"/>
<path fill={colours[0]} d="M22.3333 314.667L22.6667 315.333L22.3333 314.667z"/>
<path fill={colours[2]} d="M70 314L71 315L70 314z"/>
<path fill={colours[0]} d="M165 314L166 315L165 314z"/>
<path fill={colours[2]} d="M256 314L257 315L256 314M347 314L348 315L347 314z"/>
<path fill={colours[0]} d="M386 314L387 315L386 314M504 314L505 315L504 314M532 314L533 315L532 314M46 315L47 316L46 315z"/>
<path fill={colours[3]} d="M67 315L68 316L67 315z"/>
<path fill={colours[0]} d="M68 315L69 316L68 315M163 315L164 316L163 315M227 315L228 316L227 315z"/>
<path fill={colours[2]} d="M257 315L258 316L257 315z"/>
<path fill={colours[0]} d="M347 315L348 316L347 315z"/>
<path fill={colours[2]} d="M387 315L388 316L387 315z"/>
<path fill={colours[3]} d="M504 315L505 316L504 315z"/>
<path fill={colours[2]} d="M533 315L534 316L533 315M46.3333 316.667L46.6667 317.333L46.3333 316.667M65 316L66 317L65 316z"/>
<path fill={colours[0]} d="M66 316L67 317L66 316z"/>
<path fill={colours[2]} d="M258 316L259 317L258 316M346 316L347 317L346 316M388 316L389 317L388 316z"/>
<path fill={colours[0]} d="M505 316L506 317L505 316M533 316L534 317L533 316z"/>
<path fill={colours[2]} d="M63 317L64 318L63 317z"/>
<path fill={colours[0]} d="M64 317L65 318L64 317M158 317L159 318L158 317z"/>
<path fill={colours[3]} d="M159 317L160 318L159 317M259 317L260 318L259 317z"/>
<path fill={colours[0]} d="M346 317L347 318L346 317z"/>
<path fill={colours[3]} d="M389 317L390 318L389 317z"/>
<path fill={colours[2]} d="M534 317L535 318L534 317M61 318L62 319L61 318z"/>
<path fill={colours[0]} d="M229 318L230 319L229 318z"/>
<path fill={colours[3]} d="M260 318L261 319L260 318z"/>
<path fill={colours[2]} d="M345 318L346 319L345 318M506 318L507 319L506 318M21 319L22 320L21 319M59 319L60 320L59 319z"/>
<path fill={colours[0]} d="M153 319L154 320L153 319z"/>
<path fill={colours[3]} d="M154 319L155 320L154 319M261 319L262 320L261 319z"/>
<path fill={colours[0]} d="M507 319L508 320L507 319z"/>
<path fill={colours[2]} d="M535 319L536 320L535 319z"/>
<path fill={colours[0]} d="M21.3333 320.667L21.6667 321.333L21.3333 320.667M45.3333 320.667L45.6667 321.333L45.3333 320.667z"/>
<path fill={colours[2]} d="M57 320L58 321L57 320z"/>
<path fill={colours[3]} d="M230 320L231 321L230 320M262 320L263 321L262 320z"/>
<path fill={colours[0]} d="M344 320L345 321L344 320M391 320L392 321L391 320M56 321L57 322L56 321M152 321L153 322L152 321z"/>
<path fill={colours[3]} d="M263 321L264 322L263 321z"/>
<path fill={colours[0]} d="M392 321L393 322L392 321M508 321L509 322L508 321M536 321L537 322L536 321z"/>
<path fill={colours[2]} d="M45.3333 322.667L45.6667 323.333L45.3333 322.667z"/>
<path fill={colours[0]} d="M54 322L55 323L54 322M232 322L233 323L232 322z"/>
<path fill={colours[2]} d="M264 322L265 323L264 322z"/>
<path fill={colours[0]} d="M343 322L344 323L343 322M393 322L394 323L393 322M52 323L53 324L52 323z"/>
<path fill={colours[2]} d="M265 323L266 324L265 323M342 323L343 324L342 323z"/>
<path fill={colours[0]} d="M394 323L395 324L394 323z"/>
<path fill={colours[2]} d="M509 323L510 324L509 323z"/>
<path fill={colours[0]} d="M537 323L538 324L537 323z"/>
<path fill={colours[2]} d="M50 324L51 325L50 324z"/>
<path fill={colours[3]} d="M233 324L234 325L233 324z"/>
<path fill={colours[2]} d="M266 324L267 325L266 324z"/>
<path fill={colours[0]} d="M395 324L396 325L395 324M510 324L511 325L510 324z"/>
<path fill={colours[2]} d="M20.3333 325.667L20.6667 326.333L20.3333 325.667z"/>
<path fill={colours[0]} d="M49 325L50 326L49 325M151 325L152 326L151 325M267 325L268 326L267 325M341 325L342 326L341 325M396 325L397 326L396 325M538 325L539 326L538 325M44 326L44 329L45 329L44 326z"/>
<path fill={colours[2]} d="M47 326L48 327L47 326z"/>
<path fill={colours[0]} d="M235 326L236 327L235 326M268 326L269 327L268 326z"/>
<path fill={colours[3]} d="M340 326L341 327L340 326z"/>
<path fill={colours[0]} d="M397 326L398 327L397 326M511 326L512 327L511 326M20 327L20 330L21 330L20 327M46 327L47 328L46 327M236 327L237 328L236 327M340 327L341 328L340 327M398 327L399 328L398 327M539 327L540 328L539 327M121.667 328.333L122.333 328.667L121.667 328.333z"/>
<path fill={colours[2]} d="M66 508C96.9681 501.591 120.967 477.845 144 458L144 457C121.225 417.973 118.471 371.455 126 328C112.086 330.246 85.5445 329.521 74.7238 339.843C66.2165 347.957 64.0075 369.791 62.0247 380.985C54.6799 422.452 56.6566 466.96 66 508z"/>
<path fill={colours[3]} d="M124 328L125 329L124 328M271 328L272 329L271 328z"/>
<path fill={colours[2]} d="M339 328L340 329L339 328M512 328L513 329L512 328z"/>
<path fill={colours[0]} d="M114 329L114 330L117 330L114 329z"/>
<path fill={colours[3]} d="M125 329L126 330L125 329z"/>
<path fill={colours[0]} d="M150 329L151 330L150 329z"/>
<path fill={colours[2]} d="M272 329L273 330L272 329z"/>
<path fill={colours[3]} d="M338 329L339 330L338 329M401 329L402 330L401 329z"/>
<path fill={colours[0]} d="M540 329L541 330L540 329M107 330L107 331L110 331L107 330z"/>
<path fill={colours[3]} d="M238 330L239 331L238 330z"/>
<path fill={colours[2]} d="M273 330L274 331L273 330M367 330C360.383 345.337 347.895 359.606 336.011 371.09C331.447 375.5 327.94 381.391 321 381.88C308.667 382.749 296.079 372.032 285 368C281.675 379.718 273.28 394.05 272.614 406C272.125 414.779 275.604 424.764 278.334 433.001C286.574 457.866 300.998 481.091 319.965 499.169C327.364 506.222 334.496 514.715 344 519C369.853 483.204 393.34 448.002 400.718 403.424C402.568 392.247 406.995 373.957 402.475 363.184C400.324 358.058 393.106 354.438 388.95 350.985C381.189 344.537 375.067 335.993 367 330z"/>
<path fill={colours[0]} d="M368 330L369 331L368 330z"/>
<path fill={colours[3]} d="M402 330L403 331L402 330z"/>
<path fill={colours[2]} d="M513 330L514 331L513 330M541 330L542 331L541 330z"/>
<path fill={colours[0]} d="M100.667 331.333L101.333 331.667L100.667 331.333M125.333 331.667L125.667 332.333L125.333 331.667M274 331L275 332L274 331M337 331L338 332L337 331z"/>
<path fill={colours[2]} d="M403 331L404 332L403 331z"/>
<path fill={colours[0]} d="M514 331L515 332L514 331M541 331L542 332L541 331M95.6667 332.333L96.3333 332.667L95.6667 332.333z"/>
<path fill={colours[3]} d="M336 332L337 333L336 332z"/>
<path fill={colours[0]} d="M404 332L405 333L404 332z"/>
<path fill={colours[3]} d="M514 332L515 333L514 332z"/>
<path fill={colours[2]} d="M542 332L543 333L542 332M19 333L19 336L20 336L19 333z"/>
<path fill={colours[0]} d="M91 333L92 334L91 333z"/>
<path fill={colours[3]} d="M93 333L94 334L93 333z"/>
<path fill={colours[0]} d="M149.333 333.667L149.667 334.333L149.333 333.667M241 333L242 334L241 333z"/>
<path fill={colours[2]} d="M277 333L278 334L277 333z"/>
<path fill={colours[0]} d="M365 333L366 334L365 333M405 333L406 334L405 333M515 333L516 334L515 333M542 333L543 334L542 333M88 334L89 335L88 334M242 334L243 335L242 334M278 334L279 335L278 334M335 334L336 335L335 334z"/>
<path fill={colours[3]} d="M371 334L372 335L371 334z"/>
<path fill={colours[2]} d="M543 334L544 335L543 334z"/>
<path fill={colours[0]} d="M84 335L85 336L84 335M243 335L244 336L243 335M279 335L280 336L279 335z"/>
<path fill={colours[2]} d="M334 335L335 336L334 335z"/>
<path fill={colours[0]} d="M364 335L365 336L364 335z"/>
<path fill={colours[2]} d="M408 335L409 336L408 335z"/>
<path fill={colours[0]} d="M516 335L517 336L516 335M543 335L544 336L543 335M19 336L19 342L20 342L19 336M81 336L82 337L81 336M124.333 336.667L124.667 337.333L124.333 336.667M244 336L245 337L244 336z"/>
<path fill={colours[2]} d="M281 336L282 337L281 336z"/>
<path fill={colours[3]} d="M333 336L334 337L333 336z"/>
<path fill={colours[2]} d="M409 336L410 337L409 336z"/>
<path fill={colours[0]} d="M79 337L80 338L79 337M245 337L246 338L245 337M282 337L283 338L282 337M410 337L411 338L410 337M517 337L518 338L517 337M544 337L545 338L544 337M76 338L77 339L76 338M148.333 338.667L148.667 339.333L148.333 338.667M246 338L247 339L246 338z"/>
<path fill={colours[2]} d="M284 338L285 339L284 338z"/>
<path fill={colours[0]} d="M332 338L333 339L332 338M362 338L363 339L362 338M375 338L376 339L375 338z"/>
<path fill={colours[3]} d="M412 338L413 339L412 338z"/>
<path fill={colours[0]} d="M247 339L248 340L247 339M285 339L286 340L285 339z"/>
<path fill={colours[2]} d="M331 339L332 340L331 339z"/>
<path fill={colours[0]} d="M376 339L377 340L376 339z"/>
<path fill={colours[2]} d="M413 339L414 340L413 339M518 339L519 340L518 339z"/>
<path fill={colours[0]} d="M545 339L546 340L545 339M248 340L249 341L248 340z"/>
<path fill={colours[2]} d="M287 340L288 341L287 340z"/>
<path fill={colours[3]} d="M330 340L331 341L330 340z"/>
<path fill={colours[0]} d="M377 340L378 341L377 340M414 340L415 341L414 340M249 341L250 342L249 341M288 341L289 342L288 341M360 341L361 342L360 341M378 341L379 342L378 341z"/>
<path fill={colours[2]} d="M416 341L417 342L416 341M519 341L520 342L519 341z"/>
<path fill={colours[0]} d="M546 341L547 342L546 341M123 342L123 345L124 345L123 342z"/>
<path fill={colours[2]} d="M290 342L291 343L290 342z"/>
<path fill={colours[0]} d="M379 342L380 343L379 342M417 342L418 343L417 342M72 343L73 344L72 343M147 343L147 347L148 347L147 343M291 343L292 344L291 343M328 343L329 344L328 343z"/>
<path fill={colours[3]} d="M419 343L420 344L419 343z"/>
<path fill={colours[2]} d="M520 343L521 344L520 343M547 343L548 344L547 343z"/>
<path fill={colours[3]} d="M72 344L73 345L72 344z"/>
<path fill={colours[0]} d="M293 344L294 345L293 344z"/>
<path fill={colours[2]} d="M327 344L328 345L327 344z"/>
<path fill={colours[0]} d="M358 344L359 345L358 344z"/>
<path fill={colours[2]} d="M420 344L421 345L420 344z"/>
<path fill={colours[3]} d="M253 345L254 346L253 345z"/>
<path fill={colours[2]} d="M295 345L296 346L295 345M326 345L327 346L326 345z"/>
<path fill={colours[0]} d="M421 345L422 346L421 345z"/>
<path fill={colours[2]} d="M521 345L522 346L521 345M548 345L549 346L548 345z"/>
<path fill={colours[3]} d="M254 346L255 347L254 346z"/>
<path fill={colours[0]} d="M296 346L297 347L296 346z"/>
<path fill={colours[3]} d="M325 346L326 347L325 346z"/>
<path fill={colours[0]} d="M423 346L424 347L423 346M548 346L549 347L548 346z"/>
<path fill={colours[3]} d="M122 347L123 348L122 347z"/>
<path fill={colours[0]} d="M256 347L257 348L256 347M298 347L299 348L298 347z"/>
<path fill={colours[3]} d="M324 347L325 348L324 347z"/>
<path fill={colours[0]} d="M356 347L357 348L356 347z"/>
<path fill={colours[3]} d="M425 347L426 348L425 347z"/>
<path fill={colours[2]} d="M522 347L523 348L522 347M549 347L550 348L549 347M18 348L18 362L19 362L18 348z"/>
<path fill={colours[0]} d="M70 348L71 349L70 348M257 348L258 349L257 348M300 348L301 349L300 348z"/>
<path fill={colours[3]} d="M385 348L386 349L385 348z"/>
<path fill={colours[0]} d="M426 348L427 349L426 348M549 348L550 349L549 348M302 349L303 350L302 349z"/>
<path fill={colours[3]} d="M386 349L387 350L386 349z"/>
<path fill={colours[2]} d="M428 349L429 350L428 349z"/>
<path fill={colours[0]} d="M523 349L524 350L523 349M122 350L122 355L123 355L122 350z"/>
<path fill={colours[3]} d="M259 350L260 351L259 350z"/>
<path fill={colours[2]} d="M304 350L305 351L304 350z"/>
<path fill={colours[0]} d="M322 350L323 351L322 350M388 350L389 351L388 350M429 350L430 351L429 350M550 350L551 351L550 350M69 351L70 352L69 351M261 351L262 352L261 351M306 351L307 352L306 351M321 351L322 352L321 351M353 351L354 352L353 351M389 351L390 352L389 351z"/>
<path fill={colours[2]} d="M431 351L432 352L431 351z"/>
<path fill={colours[0]} d="M524 351L525 352L524 351M146 352L146 356L147 356L146 352M308 352L309 353L308 352M320 352L321 353L320 352M432 352L433 353L432 352z"/>
<path fill={colours[2]} d="M551 352L552 353L551 352z"/>
<path fill={colours[0]} d="M263 353L264 354L263 353M310 353L311 354L310 353M319 353L320 354L319 353M434 353L435 354L434 353M525 353L526 354L525 353M551 353L552 354L551 353M68 354L69 355L68 354M312 354L313 355L312 354z"/>
<path fill={colours[2]} d="M313 354L314 355L313 354z"/>
<path fill={colours[0]} d="M318 354L319 355L318 354z"/>
<path fill={colours[3]} d="M392 354L393 355L392 354z"/>
<path fill={colours[2]} d="M436 354L437 355L436 354M315 355L316 356L315 355z"/>
<path fill={colours[0]} d="M317 355L318 356L317 355M350 355L351 356L350 355M394 355L395 356L394 355M437 355L438 356L437 355M526 355L527 356L526 355M552 355L553 356L552 355M349 356L350 357L349 356M395 356L396 357L395 356M439 356L440 357L439 356z"/>
<path fill={colours[2]} d="M526 356L527 357L526 356z"/>
<path fill={colours[0]} d="M67 357L68 358L67 357M262 357L263 358L262 357z"/>
<path fill={colours[2]} d="M441 357L442 358L441 357M553 357L554 358L553 357z"/>
<path fill={colours[3]} d="M348 358L349 359L348 358z"/>
<path fill={colours[0]} d="M398 358L399 359L398 358z"/>
<path fill={colours[2]} d="M443 358L444 359L443 358M527 358L528 359L527 358z"/>
<path fill={colours[0]} d="M444 359L445 360L444 359M66 360L67 361L66 360z"/>
<path fill={colours[3]} d="M400 360L401 361L400 360z"/>
<path fill={colours[0]} d="M446 360L447 361L446 360M528 360L529 361L528 360M554 360L555 361L554 360M261 361L262 362L261 361M345 361L346 362L345 361M402 361L403 362L402 361M448 361L449 362L448 361M344 362L345 363L344 362M403 362L403 368L404 368L403 362z"/>
<path fill={colours[2]} d="M450 362L451 363L450 362z"/>
<path fill={colours[0]} d="M529 362L530 363L529 362z"/>
<path fill={colours[2]} d="M555 362L556 363L555 362M452 363L453 364L452 363M529 363L530 364L529 363z"/>
<path fill={colours[0]} d="M555 363L556 364L555 363M19 364L20 365L19 364M65 364L66 365L65 364M260 364L261 365L260 364M453 364L454 365L453 364z"/>
<path fill={colours[2]} d="M454 364L455 365L454 364M19 365L20 366L19 365z"/>
<path fill={colours[0]} d="M455 365L456 366L455 365z"/>
<path fill={colours[2]} d="M456 365L457 366L456 365z"/>
<path fill={colours[0]} d="M530 365L531 366L530 365z"/>
<path fill={colours[2]} d="M556 365L557 366L556 365z"/>
<path fill={colours[3]} d="M65 366L66 367L65 366M259 366L260 367L259 366M341 366L342 367L341 366z"/>
<path fill={colours[0]} d="M457 366L458 367L457 366z"/>
<path fill={colours[3]} d="M458 366L459 367L458 366z"/>
<path fill={colours[2]} d="M20 367L21 368L20 367z"/>
<path fill={colours[0]} d="M259 367L260 368L259 367z"/>
<path fill={colours[3]} d="M340 367L341 368L340 367z"/>
<path fill={colours[0]} d="M459 367L460 368L459 367M531 367L532 368L531 367M21 368L22 369L21 368M64 368L65 369L64 368M461 368L462 369L461 368z"/>
<path fill={colours[2]} d="M531 368L532 369L531 368M557 368L558 369L557 368z"/>
<path fill={colours[0]} d="M284 369L285 370L284 369M288 369L289 370L288 369M463 369L464 370L463 369M23 370L24 371L23 370z"/>
<path fill={colours[2]} d="M38.3333 370.667L38.6667 371.333L38.3333 370.667z"/>
<path fill={colours[3]} d="M64 370L65 371L64 370z"/>
<path fill={colours[0]} d="M290 370L291 371L290 370M465 370L466 371L465 370M532 370L533 371L532 370M24 371L25 372L24 371z"/>
<path fill={colours[2]} d="M36 371L37 372L36 371z"/>
<path fill={colours[0]} d="M292 371L293 372L292 371M467 371L468 372L467 371z"/>
<path fill={colours[2]} d="M468 371L469 372L468 371z"/>
<path fill={colours[3]} d="M532 371L533 372L532 371z"/>
<path fill={colours[2]} d="M558 371L559 372L558 371z"/>
<path fill={colours[0]} d="M26 372L27 373L26 372M33 372L34 373L33 372z"/>
<path fill={colours[2]} d="M34 372L35 373L34 372z"/>
<path fill={colours[0]} d="M38.3333 372.667L38.6667 373.333L38.3333 372.667M63 372L64 373L63 372M294 372L295 373L294 372M469 372L470 373L469 372z"/>
<path fill={colours[2]} d="M470 372L471 373L470 372z"/>
<path fill={colours[0]} d="M283 373L284 374L283 373M296 373L297 374L296 373M471 373L472 374L471 373z"/>
<path fill={colours[2]} d="M472 373L473 374L472 373z"/>
<path fill={colours[0]} d="M533 373L534 374L533 373M298 374L299 375L298 374M403 374L403 385L404 385L403 374M473 374L474 375L473 374z"/>
<path fill={colours[2]} d="M474 374L475 375L474 374M559 374L560 375L559 374z"/>
<path fill={colours[0]} d="M300 375L301 376L300 375z"/>
<path fill={colours[2]} d="M476 375L477 376L476 375z"/>
<path fill={colours[0]} d="M559 375L560 376L559 375z"/>
<path fill={colours[2]} d="M37 376L38 377L37 376z"/>
<path fill={colours[0]} d="M282 376L283 377L282 376M478 376L479 377L478 376z"/>
<path fill={colours[2]} d="M534 376L535 377L534 376z"/>
<path fill={colours[0]} d="M37.3333 377.667L37.6667 378.333L37.3333 377.667M62.3333 377.667L62.6667 378.333L62.3333 377.667M305 377L306 378L305 377z"/>
<path fill={colours[3]} d="M330 377L331 378L330 377z"/>
<path fill={colours[0]} d="M480 377L481 378L480 377z"/>
<path fill={colours[2]} d="M560 377L561 378L560 377z"/>
<path fill={colours[0]} d="M307 378L308 379L307 378z"/>
<path fill={colours[3]} d="M329 378L330 379L329 378z"/>
<path fill={colours[2]} d="M428 378L429 379L428 378z"/>
<path fill={colours[0]} d="M482 378L483 379L482 378z"/>
<path fill={colours[3]} d="M483 378L484 379L483 378z"/>
<path fill={colours[0]} d="M560 378L561 379L560 378M310 379L311 380L310 379z"/>
<path fill={colours[3]} d="M328 379L329 380L328 379M428 379C428 392.353 422.082 407.052 423.34 420C428.828 476.526 469.749 531.575 517 561C534.738 530.609 533.802 492.792 531.086 459C530.35 449.847 530.993 430.03 525.012 422.702C520.236 416.851 504.057 414.023 497 411.2C474.008 402.003 451.483 385.908 428 379z"/>
<path fill={colours[0]} d="M430 379L431 380L430 379M484 379L485 380L484 379z"/>
<path fill={colours[2]} d="M485 379L486 380L485 379z"/>
<path fill={colours[0]} d="M535 379L536 380L535 379M146 380L146 385L147 385L146 380M326 380L327 381L326 380M427 380L427 388L428 388L427 380M432 380L433 381L432 380z"/>
<path fill={colours[2]} d="M487 380L488 381L487 380z"/>
<path fill={colours[3]} d="M535 380L536 381L535 380z"/>
<path fill={colours[2]} d="M36.3333 381.667L36.6667 382.333L36.3333 381.667z"/>
<path fill={colours[3]} d="M254 381L255 382L254 381z"/>
<path fill={colours[0]} d="M315 381L316 382L315 381M324 381L325 382L324 381M434 381L435 382L434 381M489 381L490 382L489 381M561 381L562 382L561 381M61.3333 382.667L61.6667 383.333L61.3333 382.667M122 382L122 387L123 387L122 382M254 382L255 383L254 382z"/>
<path fill={colours[2]} d="M435 382L436 383L435 382z"/>
<path fill={colours[0]} d="M436 382L437 383L436 382M491 382L492 383L491 382z"/>
<path fill={colours[2]} d="M492 382L493 383L492 382z"/>
<path fill={colours[0]} d="M536 382L537 383L536 382M36.3333 383.667L36.6667 384.333L36.3333 383.667z"/>
<path fill={colours[2]} d="M437 383L438 384L437 383M494 383L495 384L494 383M536 383L537 384L536 383M439 384L440 385L439 384z"/>
<path fill={colours[0]} d="M496 384L497 385L496 384z"/>
<path fill={colours[2]} d="M562 384L563 385L562 384z"/>
<path fill={colours[3]} d="M61 385L62 386L61 385z"/>
<path fill={colours[0]} d="M253 385L254 386L253 385z"/>
<path fill={colours[2]} d="M441 385L442 386L441 385z"/>
<path fill={colours[0]} d="M498 385L499 386L498 385z"/>
<path fill={colours[2]} d="M499 385L500 386L499 385z"/>
<path fill={colours[0]} d="M537 385L538 386L537 385M562 385L563 386L562 385M279 386L280 387L279 386M443 386L444 387L443 386z"/>
<path fill={colours[2]} d="M501 386L502 387L501 386M537 386L538 387L537 386z"/>
<path fill={colours[0]} d="M445 387L446 388L445 387M503 387L504 388L503 387z"/>
<path fill={colours[3]} d="M504 387L505 388L504 387M537 387L538 388L537 387z"/>
<path fill={colours[2]} d="M35.3333 388.667L35.6667 389.333L35.3333 388.667z"/>
<path fill={colours[0]} d="M60 388L60 391L61 391L60 388z"/>
<path fill={colours[2]} d="M427 388L427 391L428 391L427 388z"/>
<path fill={colours[0]} d="M447 388L448 389L447 388z"/>
<path fill={colours[2]} d="M506 388L507 389L506 388M563 388L564 389L563 388z"/>
<path fill={colours[3]} d="M251 389L252 390L251 389z"/>
<path fill={colours[0]} d="M449 389L450 390L449 389M508 389L509 390L508 389z"/>
<path fill={colours[3]} d="M509 389L510 390L509 389z"/>
<path fill={colours[0]} d="M538 389L539 390L538 389M563.333 389.667L563.667 390.333L563.333 389.667M35 390L35 393L36 393L35 390M147.333 390.667L147.667 391.333L147.333 390.667M251 390L252 391L251 390M451 390L452 391L451 390z"/>
<path fill={colours[2]} d="M511 390L512 391L511 390M538 390L539 391L538 390z"/>
<path fill={colours[0]} d="M277 391L278 392L277 391M402 391L402 395L403 395L402 391M453 391L454 392L453 391M513 391L514 392L513 391z"/>
<path fill={colours[3]} d="M514 391L515 392L514 391z"/>
<path fill={colours[0]} d="M455 392L456 393L455 392z"/>
<path fill={colours[2]} d="M516 392L517 393L516 392M564.333 392.667L564.667 393.333L564.333 392.667z"/>
<path fill={colours[0]} d="M123.333 393.667L123.667 394.333L123.333 393.667M457 393L458 394L457 393M518 393L519 394L518 393z"/>
<path fill={colours[2]} d="M519 393L520 394L519 393z"/>
<path fill={colours[0]} d="M539 393L540 394L539 393z"/>
<path fill={colours[3]} d="M148 394L149 395L148 394z"/>
<path fill={colours[0]} d="M276 394L277 395L276 394M426 394L426 398L427 398L426 394M459 394L460 395L459 394M521 394L522 395L521 394z"/>
<path fill={colours[2]} d="M539 394L540 395L539 394z"/>
<path fill={colours[0]} d="M564.333 394.667L564.667 395.333L564.333 394.667M59 395L59 399L60 399L59 395M249 395L250 396L249 395M461 395L462 396L461 395z"/>
<path fill={colours[2]} d="M524 395L525 396L524 395z"/>
<path fill={colours[3]} d="M539 395L540 396L539 395z"/>
<path fill={colours[2]} d="M34.3333 396.667L34.6667 397.333L34.3333 396.667z"/>
<path fill={colours[0]} d="M148.333 396.667L148.667 397.333L148.333 396.667M463 396L464 397L463 396M526 396L527 397L526 396z"/>
<path fill={colours[2]} d="M527 396L528 397L527 396z"/>
<path fill={colours[0]} d="M248 397L249 398L248 397M275 397L276 398L275 397z"/>
<path fill={colours[2]} d="M465 397L466 398L465 397z"/>
<path fill={colours[0]} d="M529 397L530 398L529 397z"/>
<path fill={colours[3]} d="M530 397L531 398L530 397z"/>
<path fill={colours[0]} d="M34 398L34 403L35 403L34 398z"/>
<path fill={colours[2]} d="M426.333 398.667L426.667 399.333L426.333 398.667M467 398L468 399L467 398z"/>
<path fill={colours[0]} d="M468 398L469 399L468 398M532 398L533 399L532 398z"/>
<path fill={colours[3]} d="M533 398L534 399L533 398z"/>
<path fill={colours[0]} d="M540 398L541 399L540 398z"/>
<path fill={colours[2]} d="M565 398L566 399L565 398z"/>
<path fill={colours[0]} d="M124 399L124 402L125 402L124 399M274 399L275 400L274 399M401.333 399.667L401.667 400.333L401.333 399.667z"/>
<path fill={colours[2]} d="M469 399L470 400L469 399z"/>
<path fill={colours[0]} d="M470 399L471 400L470 399z"/>
<path fill={colours[2]} d="M535 399L536 400L535 399M540.333 399.667L540.667 400.333L540.333 399.667z"/>
<path fill={colours[0]} d="M565.333 399.667L565.667 400.333L565.333 399.667M472 400L473 401L472 400z"/>
<path fill={colours[2]} d="M538 400L539 401L538 400z"/>
<path fill={colours[3]} d="M59 401L60 402L59 401z"/>
<path fill={colours[0]} d="M474 401L475 402L474 401M149 402L150 403L149 402M246 402L247 403L246 402M425.333 402.667L425.667 403.333L425.333 402.667M476 402L477 403L476 402z"/>
<path fill={colours[3]} d="M400 403L401 404L400 403z"/>
<path fill={colours[2]} d="M478 403L479 404L478 403z"/>
<path fill={colours[0]} d="M479 403L480 404L479 403M245 404L246 405L245 404M272 404L273 405L272 404z"/>
<path fill={colours[2]} d="M425.333 404.667L425.667 405.333L425.333 404.667M480 404L481 405L480 404z"/>
<path fill={colours[0]} d="M481 404L482 405L481 404z"/>
<path fill={colours[2]} d="M566 404L567 405L566 404z"/>
<path fill={colours[0]} d="M58 405L58 410L59 410L58 405M125 405L126 406L125 405M400.333 405.667L400.667 406.333L400.333 405.667M483 405L484 406L483 405M566 405L566 408L567 408L566 405M150.333 406.667L150.667 407.333L150.333 406.667M244 406L245 407L244 406z"/>
<path fill={colours[2]} d="M485 406L486 407L485 406M33 407L33 410L34 410L33 407z"/>
<path fill={colours[0]} d="M272 407L272 410L273 410L272 407z"/>
<path fill={colours[2]} d="M487 407L488 408L487 407z"/>
<path fill={colours[0]} d="M488 407L489 408L488 407M424.333 408.667L424.667 409.333L424.333 408.667M490 408L491 409L490 408M126.333 409.667L126.667 410.333L126.333 409.667z"/>
<path fill={colours[2]} d="M492 409L493 410L492 409z"/>
<path fill={colours[0]} d="M493 409L494 410L493 409M33 410L33 421L34 421L33 410M151 410L152 411L151 410M399.333 410.667L399.667 411.333L399.333 410.667z"/>
<path fill={colours[2]} d="M424.333 410.667L424.667 411.333L424.333 410.667z"/>
<path fill={colours[0]} d="M495 410L496 411L495 410z"/>
<path fill={colours[2]} d="M497 411L498 412L497 411z"/>
<path fill={colours[0]} d="M500 412L501 413L500 412z"/>
<path fill={colours[2]} d="M567 412L567 416L568 416L567 412M502 413L503 414L502 413z"/>
<path fill={colours[0]} d="M127 414L128 415L127 414M152 414L153 415L152 414M273 414L274 415L273 414M423 414L424 415L423 414M505 414L506 415L505 414M398 415L399 416L398 415z"/>
<path fill={colours[2]} d="M423 415L424 416L423 415M507 415L508 416L507 415z"/>
<path fill={colours[0]} d="M508 415L509 416L508 415z"/>
<path fill={colours[2]} d="M510 416L511 417L510 416z"/>
<path fill={colours[0]} d="M567 416L567 420L568 420L567 416M153 417L154 418L153 417z"/>
<path fill={colours[3]} d="M397 417L398 418L397 417z"/>
<path fill={colours[0]} d="M513 417L514 418L513 417M128 418L129 419L128 418M422 418L422 421L423 421L422 418z"/>
<path fill={colours[2]} d="M515 418L516 419L515 418z"/>
<path fill={colours[0]} d="M516 418L517 419L516 418z"/>
<path fill={colours[3]} d="M154 419L155 420L154 419z"/>
<path fill={colours[0]} d="M274 419L275 420L274 419M397 419L398 420L397 419z"/>
<path fill={colours[2]} d="M518 419L519 420L518 419z"/>
<path fill={colours[0]} d="M154 420L155 421L154 420z"/>
<path fill={colours[2]} d="M521 420L522 421L521 420M567 420L568 421L567 420z"/>
<path fill={colours[0]} d="M57 421L57 441L58 441L57 421M129 421L130 422L129 421z"/>
<path fill={colours[2]} d="M423 421L424 422L423 421z"/>
<path fill={colours[0]} d="M524 421L525 422L524 421M236 422L237 423L236 422M396 422L397 423L396 422M423 422L424 423L423 422M155 423L156 424L155 423M275 423L276 424L275 423z"/>
<path fill={colours[2]} d="M566 423L567 424L566 423z"/>
<path fill={colours[0]} d="M235 424L236 425L235 424M526 424L527 425L526 424M565 424L566 425L565 424M130 425L131 426L130 425z"/>
<path fill={colours[3]} d="M276 425L277 426L276 425z"/>
<path fill={colours[2]} d="M424 425L425 426L424 425M526.333 425.667L526.667 426.333L526.333 425.667z"/>
<path fill={colours[3]} d="M130 426L131 427L130 426z"/>
<path fill={colours[0]} d="M156 426L157 427L156 426M234 426L235 427L234 426M395 426L396 427L395 426M424.333 426.667L424.667 427.333L424.333 426.667z"/>
<path fill={colours[2]} d="M564 426L565 427L564 426z"/>
<path fill={colours[0]} d="M276 427L277 428L276 427z"/>
<path fill={colours[2]} d="M563 427L564 428L563 427z"/>
<path fill={colours[0]} d="M233 428L234 429L233 428M561 428L562 429L561 428M394 429L395 430L394 429M527.333 429.667L527.667 430.333L527.333 429.667M559 429L560 430L559 429z"/>
<path fill={colours[2]} d="M560 429L561 430L560 429z"/>
<path fill={colours[0]} d="M277 430L278 431L277 430M552 432L556 431L552 432z"/>
<path fill={colours[2]} d="M556.667 430.333L557.333 430.667L556.667 430.333z"/>
<path fill={colours[0]} d="M158 431L159 432L158 431M231 431L232 432L231 431z"/>
<path fill={colours[3]} d="M393 431L394 432L393 431z"/>
<path fill={colours[2]} d="M425 431L426 432L425 431M527 431L528 432L527 431z"/>
<path fill={colours[0]} d="M393 432L394 433L393 432M425.333 432.667L425.667 433.333L425.333 432.667M133 433L134 434L133 433M159 433L160 434L159 433M230 433L231 434L230 433M278 433L279 434L278 433z"/>
<path fill={colours[3]} d="M392 434L393 435L392 434z"/>
<path fill={colours[0]} d="M392 435L393 436L392 435M528.333 435.667L528.667 436.333L528.333 435.667z"/>
<path fill={colours[2]} d="M553 435L554 436L553 435z"/>
<path fill={colours[0]} d="M134 436L135 437L134 436M228 436L229 437L228 436M279 436L280 437L279 436z"/>
<path fill={colours[2]} d="M426 436L427 437L426 436z"/>
<path fill={colours[0]} d="M553 436L553 439L554 439L553 436M426.333 437.667L426.667 438.333L426.333 437.667z"/>
<path fill={colours[2]} d="M528 437L529 438L528 437z"/>
<path fill={colours[0]} d="M161 438L162 439L161 438M227 438L228 439L227 438M391 438L392 439L391 438M280 439L281 440L280 439M33 440L33 452L34 452L33 440M162 440L163 441L162 440M254.667 440.333L255.333 440.667L254.667 440.333M136 441L137 442L136 441M225 441L226 442L225 441z"/>
<path fill={colours[3]} d="M254 441C244.241 461.877 228.292 480.749 213.282 498C208.59 503.392 201.223 514.322 193 513.555C180.836 512.42 171.946 494.841 163 488C157.803 529.123 166.778 570.016 185.27 607C192.652 621.764 202.978 635.154 210 650C225.236 642.894 238.387 619.621 247.656 606C263.542 582.655 276.913 556.796 282.789 529C284.592 520.471 287.46 507.561 285.427 499C282.821 488.024 271.498 477.156 266.258 467C262.125 458.988 259.98 447.645 254 441z"/>
<path fill={colours[2]} d="M255 441L256 442L255 441z"/>
<path fill={colours[0]} d="M281 441L282 442L281 441M390 441L391 442L390 441z"/>
<path fill={colours[2]} d="M427 441L428 442L427 441z"/>
<path fill={colours[0]} d="M529 441L529 444L530 444L529 441z"/>
<path fill={colours[2]} d="M554.333 441.667L554.667 442.333L554.333 441.667z"/>
<path fill={colours[0]} d="M163 442L164 443L163 442z"/>
<path fill={colours[3]} d="M224 442L225 443L224 442z"/>
<path fill={colours[2]} d="M253 442L254 443L253 442z"/>
<path fill={colours[0]} d="M427 442L428 443L427 442M137 443L138 444L137 443M256 443L257 444L256 443M389 443L390 444L389 443M554 443L554 446L555 446L554 443M164 444L165 445L164 444M223 444L224 445L223 444z"/>
<path fill={colours[2]} d="M252 444L253 445L252 444M256 444L257 445L256 444z"/>
<path fill={colours[0]} d="M282 444L283 445L282 444z"/>
<path fill={colours[2]} d="M428 444L429 445L428 444M529 444L530 445L529 444z"/>
<path fill={colours[0]} d="M251 445L252 446L251 445z"/>
<path fill={colours[3]} d="M283 445L284 446L283 445z"/>
<path fill={colours[0]} d="M428 445L429 446L428 445M222 446L223 447L222 446M257 446L258 447L257 446M283 446L284 447L283 446M388 446L389 447L388 446M250 447L251 448L250 447M139 448L140 449L139 448M284 448L285 449L284 448M387 448L388 449L387 448z"/>
<path fill={colours[2]} d="M429 448L430 449L429 448z"/>
<path fill={colours[0]} d="M530 448L530 451L531 451L530 448M167 449L168 450L167 449M220 449L221 450L220 449z"/>
<path fill={colours[2]} d="M249 449L250 450L249 449M258 449L259 450L258 449z"/>
<path fill={colours[0]} d="M429 449L430 450L429 449z"/>
<path fill={colours[2]} d="M555.333 449.667L555.667 450.333L555.333 449.667z"/>
<path fill={colours[0]} d="M140 450L141 451L140 450M248 450L249 451L248 450M168 451L169 452L168 451z"/>
<path fill={colours[3]} d="M218 451L219 452L218 451z"/>
<path fill={colours[0]} d="M259 451L260 452L259 451z"/>
<path fill={colours[2]} d="M430 451L431 452L430 451M530.333 451.667L530.667 452.333L530.333 451.667z"/>
<path fill={colours[0]} d="M555 451L555 455L556 455L555 451z"/>
<path fill={colours[2]} d="M33 452L33 455L34 455L33 452z"/>
<path fill={colours[0]} d="M141 452L142 453L141 452z"/>
<path fill={colours[2]} d="M247 452L248 453L247 452M259 452L260 453L259 452z"/>
<path fill={colours[0]} d="M430 452L431 453L430 452M58 453L58 459L59 459L58 453M217 453L218 454L217 453M385 453L386 454L385 453M142 454L143 455L142 454M170 454L171 455L170 454z"/>
<path fill={colours[3]} d="M216 454L217 455L216 454z"/>
<path fill={colours[2]} d="M260 454L261 455L260 454M431 454L432 455L431 454z"/>
<path fill={colours[0]} d="M245 455L246 456L245 455M384 455L385 456L384 455M431 455L432 456L431 455M143 456L144 457L143 456M171 456L172 457L171 456M215 456L216 457L215 456M261 456L262 457L261 456M531 456L531 460L532 460L531 456z"/>
<path fill={colours[2]} d="M244 457L245 458L244 457z"/>
<path fill={colours[0]} d="M383 457L384 458L383 457z"/>
<path fill={colours[2]} d="M432 457L433 458L432 457z"/>
<path fill={colours[3]} d="M142 458L143 459L142 458z"/>
<path fill={colours[0]} d="M243 458L244 459L243 458M262 458L263 459L262 458M289 458L290 459L289 458M432 458L433 459L432 458z"/>
<path fill={colours[2]} d="M556 458L556 461L557 461L556 458z"/>
<path fill={colours[0]} d="M173 459L174 460L173 459z"/>
<path fill={colours[2]} d="M262 459L263 460L262 459z"/>
<path fill={colours[0]} d="M382 459L383 460L382 459M34 460L34 465L35 465L34 460M141 460L142 461L141 460M212 460L213 461L212 460z"/>
<path fill={colours[2]} d="M242 460L243 461L242 460z"/>
<path fill={colours[0]} d="M263 460L264 461L263 460M290 460L291 461L290 460z"/>
<path fill={colours[2]} d="M433 460L434 461L433 460M531 460L531 463L532 463L531 460z"/>
<path fill={colours[0]} d="M140 461L141 462L140 461M241 461L242 462L241 461z"/>
<path fill={colours[2]} d="M263 461L264 462L263 461z"/>
<path fill={colours[0]} d="M381 461L382 462L381 461M556 461L556 467L557 467L556 461z"/>
<path fill={colours[3]} d="M59 462L60 463L59 462z"/>
<path fill={colours[0]} d="M175 462L176 463L175 462M291 462L292 463L291 462z"/>
<path fill={colours[2]} d="M240 463L241 464L240 463M264 463L265 464L264 463z"/>
<path fill={colours[0]} d="M380 463L381 464L380 463z"/>
<path fill={colours[2]} d="M408 463L407 465L408 463z"/>
<path fill={colours[0]} d="M434 463L435 464L434 463M209 464L210 465L209 464M239 464L240 465L239 464z"/>
<path fill={colours[3]} d="M408 464C395.103 486.662 383.806 508.54 367.196 529C362.092 535.287 354.123 547.848 345 547.787C331.47 547.697 320.111 529.763 309 524C308.036 536.11 299.976 549.238 300.51 561C300.914 569.902 306.856 580.186 310.753 588C321.093 608.733 332.92 631.307 348 649L349 649C377.89 620.11 403.638 585.531 418.05 547C422.504 535.093 428.89 518.951 427.828 506C427.386 500.609 423.234 495.649 420.781 491C416.214 482.345 413.373 472.092 408 464z"/>
<path fill={colours[2]} d="M34.3333 465.667L34.6667 466.333L34.3333 465.667z"/>
<path fill={colours[0]} d="M59 465L59 469L60 469L59 465z"/>
<path fill={colours[3]} d="M135 465L136 466L135 465z"/>
<path fill={colours[2]} d="M265 465L266 466L265 465z"/>
<path fill={colours[0]} d="M293 465L294 466L293 465M379 465L380 466L379 465M409 465L410 466L409 465M435 465L436 466L435 465z"/>
<path fill={colours[3]} d="M134 466L135 467L134 466z"/>
<path fill={colours[0]} d="M178 466L179 467L178 466z"/>
<path fill={colours[3]} d="M207 466L208 467L207 466z"/>
<path fill={colours[2]} d="M238 466L239 467L238 466z"/>
<path fill={colours[0]} d="M266 466L267 467L266 466M406 466L407 467L406 466z"/>
<path fill={colours[2]} d="M409 466L410 467L409 466z"/>
<path fill={colours[0]} d="M237 467L238 468L237 467z"/>
<path fill={colours[2]} d="M266 467L267 468L266 467z"/>
<path fill={colours[0]} d="M294 467L295 468L294 467M378 467L379 468L378 467z"/>
<path fill={colours[2]} d="M436 467L437 468L436 467z"/>
<path fill={colours[0]} d="M532 467L532 474L533 474L532 467z"/>
<path fill={colours[3]} d="M180 468L181 469L180 468z"/>
<path fill={colours[0]} d="M206 468L207 469L206 468M267 468L268 469L267 468M405 468L406 469L405 468M410 468L411 469L410 468M436 468L437 469L436 468M131 469L132 470L131 469z"/>
<path fill={colours[2]} d="M267 469L268 470L267 469z"/>
<path fill={colours[0]} d="M377 469L378 470L377 469z"/>
<path fill={colours[2]} d="M437 469L438 470L437 469z"/>
<path fill={colours[0]} d="M130 470L131 471L130 470M181 470L182 471L181 470z"/>
<path fill={colours[2]} d="M235 470L236 471L235 470z"/>
<path fill={colours[0]} d="M268 470L269 471L268 470M296 470L297 471L296 470M404 470L405 471L404 470M411 470L412 471L411 470M437 470L438 471L437 470M35 471L35 474L36 474L35 471M182 471L183 472L182 471z"/>
<path fill={colours[3]} d="M203 471L204 472L203 471z"/>
<path fill={colours[0]} d="M234 471L235 472L234 471z"/>
<path fill={colours[2]} d="M268 471L269 472L268 471z"/>
<path fill={colours[0]} d="M376 471L377 472L376 471z"/>
<path fill={colours[2]} d="M404 471L405 472L404 471M411 471L412 472L411 471M557 471L557 477L558 477L557 471z"/>
<path fill={colours[3]} d="M60 472L61 473L60 472M127 472L128 473L127 472z"/>
<path fill={colours[0]} d="M269 472L270 473L269 472z"/>
<path fill={colours[3]} d="M375 472L376 473L375 472z"/>
<path fill={colours[0]} d="M403 472L404 473L403 472M438 472L439 473L438 472M202 473L203 474L202 473z"/>
<path fill={colours[2]} d="M233 473L234 474L233 473z"/>
<path fill={colours[0]} d="M298 473L299 474L298 473M375 473L376 474L375 473z"/>
<path fill={colours[2]} d="M403 473L404 474L403 473z"/>
<path fill={colours[0]} d="M412 473L413 474L412 473z"/>
<path fill={colours[2]} d="M35 474L35 477L36 477L35 474z"/>
<path fill={colours[0]} d="M125 474L126 475L125 474M201 474L202 475L201 474M232 474L233 475L232 474M270 474L271 475L270 474M402 474L403 475L402 474M439 474L440 475L439 474z"/>
<path fill={colours[2]} d="M532 474L532 481L533 481L532 474z"/>
<path fill={colours[0]} d="M60 475L60 478L61 478L60 475M124 475L125 476L124 475M185 475L186 476L185 475z"/>
<path fill={colours[2]} d="M402 475L403 476L402 475z"/>
<path fill={colours[0]} d="M413 475L414 476L413 475z"/>
<path fill={colours[3]} d="M122 476L123 477L122 476z"/>
<path fill={colours[0]} d="M186 476L187 477L186 476z"/>
<path fill={colours[3]} d="M199 476L200 477L199 476z"/>
<path fill={colours[2]} d="M271 476L272 477L271 476z"/>
<path fill={colours[0]} d="M300 476L301 477L300 476M401 476L402 477L401 476M440 476L441 477L440 476M187 477L188 478L187 477z"/>
<path fill={colours[3]} d="M198 477L199 478L198 477z"/>
<path fill={colours[2]} d="M230 477L231 478L230 477z"/>
<path fill={colours[0]} d="M272 477L273 478L272 477z"/>
<path fill={colours[2]} d="M401 477L402 478L401 477z"/>
<path fill={colours[0]} d="M414 477L415 478L414 477M557 477L557 498L558 498L557 477M120 478L121 479L120 478M229 478L230 479L229 478z"/>
<path fill={colours[3]} d="M302 478L303 479L302 478z"/>
<path fill={colours[0]} d="M372 478L373 479L372 478M400 478L401 479L400 478z"/>
<path fill={colours[2]} d="M414 478L415 479L414 478z"/>
<path fill={colours[0]} d="M441 478L442 479L441 478M36 479L36 482L37 482L36 479M228 479L229 480L228 479M273 479L274 480L273 479z"/>
<path fill={colours[3]} d="M371 479L372 480L371 479z"/>
<path fill={colours[2]} d="M400 479L401 480L400 479z"/>
<path fill={colours[3]} d="M61 480L62 481L61 480z"/>
<path fill={colours[0]} d="M196 480L197 481L196 480M303 480L304 481L303 480M371 480L372 481L371 480M399 480L400 481L399 480z"/>
<path fill={colours[2]} d="M415 480L416 481L415 480z"/>
<path fill={colours[0]} d="M442 480L443 481L442 480M116 481L117 482L116 481z"/>
<path fill={colours[3]} d="M191 481L192 482L191 481z"/>
<path fill={colours[0]} d="M195 481L196 482L195 481z"/>
<path fill={colours[2]} d="M227 481L228 482L227 481M274 481L275 482L274 481M443 481L444 482L443 481M36.3333 482.667L36.6667 483.333L36.3333 482.667z"/>
<path fill={colours[0]} d="M61 482L61 485L62 485L61 482M115 482L116 483L115 482z"/>
<path fill={colours[3]} d="M192 482L193 483L192 482z"/>
<path fill={colours[0]} d="M226 482L227 483L226 482M275 482L276 483L275 482M398 482L399 483L398 482M416 482L417 483L416 482M443 482L444 483L443 482M193 483L194 484L193 483M225 483L226 484L225 483M369 483L370 484L369 483z"/>
<path fill={colours[2]} d="M444 483L445 484L444 483z"/>
<path fill={colours[0]} d="M112 484L113 485L112 484z"/>
<path fill={colours[2]} d="M276 484L277 485L276 484z"/>
<path fill={colours[0]} d="M397 484L398 485L397 484M417 484L418 485L417 484z"/>
<path fill={colours[3]} d="M110 485L111 486L110 485z"/>
<path fill={colours[0]} d="M277 485L278 486L277 485M307 485L308 486L307 485M445 485L446 486L445 485z"/>
<path fill={colours[2]} d="M223 486L224 487L223 486z"/>
<path fill={colours[0]} d="M367 486L368 487L367 486M396 486L397 487L396 486M418 486L419 487L418 486M37.3333 487.667L37.6667 488.333L37.3333 487.667z"/>
<path fill={colours[3]} d="M62 487L63 488L62 487z"/>
<path fill={colours[0]} d="M108 487L109 488L108 487M162 487L162 490L163 490L162 487z"/>
<path fill={colours[2]} d="M163 487L164 488L163 487z"/>
<path fill={colours[0]} d="M222 487L223 488L222 487z"/>
<path fill={colours[2]} d="M278 487L279 488L278 487z"/>
<path fill={colours[0]} d="M446 487L447 488L446 487M164 488L165 489L164 488M221 488L222 489L221 488M279 488L280 489L279 488z"/>
<path fill={colours[3]} d="M310 488L311 489L310 488z"/>
<path fill={colours[0]} d="M366 488L367 489L366 488z"/>
<path fill={colours[2]} d="M395 488L396 489L395 488z"/>
<path fill={colours[0]} d="M419 488L420 489L419 488z"/>
<path fill={colours[2]} d="M447 488L448 489L447 488M37.3333 489.667L37.6667 490.333L37.3333 489.667z"/>
<path fill={colours[0]} d="M62 489L62 492L63 492L62 489M105 489L106 490L105 489M165 489L166 490L165 489M394 489L395 490L394 489z"/>
<path fill={colours[2]} d="M162.333 490.667L162.667 491.333L162.333 490.667M280 490L281 491L280 490M394 490L395 491L394 490z"/>
<path fill={colours[0]} d="M420 490L421 491L420 490M448 490L449 491L448 490M102 491L103 492L102 491M281 491L282 492L281 491M312 491L313 492L312 491M364 491L365 492L364 491M393 491L394 492L393 491z"/>
<path fill={colours[2]} d="M167 492L168 493L167 492M218 492L219 493L218 492z"/>
<path fill={colours[0]} d="M282 492L283 493L282 492M313 492L314 493L313 492z"/>
<path fill={colours[2]} d="M421 492L422 493L421 492z"/>
<path fill={colours[0]} d="M449 492L450 493L449 492M38.3333 493.667L38.6667 494.333L38.3333 493.667z"/>
<path fill={colours[3]} d="M63 493L64 494L63 493M98 493L99 494L98 493z"/>
<path fill={colours[0]} d="M99 493L100 494L99 493z"/>
<path fill={colours[2]} d="M168 493L169 494L168 493M217 493L218 494L217 493M392 493L393 494L392 493M450 493L451 494L450 493z"/>
<path fill={colours[0]} d="M97 494L98 495L97 494z"/>
<path fill={colours[2]} d="M169 494L170 495L169 494z"/>
<path fill={colours[0]} d="M216 494L217 495L216 494z"/>
<path fill={colours[2]} d="M283 494L284 495L283 494z"/>
<path fill={colours[0]} d="M362 494L363 495L362 494z"/>
<path fill={colours[2]} d="M422 494L423 495L422 494M38.3333 495.667L38.6667 496.333L38.3333 495.667z"/>
<path fill={colours[0]} d="M63.3333 495.667L63.6667 496.333L63.3333 495.667M161 495L161 500L162 500L161 495M170 495L171 496L170 495M215 495L216 496L215 495M284 495L285 496L284 495z"/>
<path fill={colours[2]} d="M391 495L392 496L391 495z"/>
<path fill={colours[0]} d="M423 495L424 496L423 495M451 495L452 496L451 495z"/>
<path fill={colours[2]} d="M532 495L532 501L533 501L532 495M136 496L137 497L136 496z"/>
<path fill={colours[0]} d="M171 496L172 497L171 496M285 496L286 497L285 496z"/>
<path fill={colours[3]} d="M360 496L361 497L360 496z"/>
<path fill={colours[0]} d="M390 496L391 497L390 496z"/>
<path fill={colours[2]} d="M452 496L453 497L452 496z"/>
<path fill={colours[0]} d="M92 497L93 498L92 497z"/>
<path fill={colours[3]} d="M107 635C120.541 631.092 133.983 619.539 144 609.961C147.227 606.875 152.898 602.746 153.497 598C154.71 588.386 145.287 572.653 142.873 563C137.406 541.129 136.041 519.333 137 497C126.115 501.85 116.389 511.25 106 517.397C102.207 519.641 95.7208 521.524 93.3179 525.39C89.4889 531.552 91.3814 542.085 90.9105 549C88.8805 578.812 96.2838 607.371 107 635z"/>
<path fill={colours[0]} d="M172 497L173 498L172 497z"/>
<path fill={colours[2]} d="M285.333 497.667L285.667 498.333L285.333 497.667z"/>
<path fill={colours[3]} d="M318 497L319 498L318 497z"/>
<path fill={colours[0]} d="M424 497L425 498L424 497M90 498L91 499L90 498M133 498L134 499L133 498z"/>
<path fill={colours[3]} d="M319 498L320 499L319 498z"/>
<path fill={colours[0]} d="M359 498L360 499L359 498z"/>
<path fill={colours[2]} d="M389 498L390 499L389 498z"/>
<path fill={colours[0]} d="M453 498L454 499L453 498z"/>
<path fill={colours[2]} d="M557 498L557 503L558 503L557 498z"/>
<path fill={colours[0]} d="M39.3333 499.667L39.6667 500.333L39.3333 499.667M88 499L89 500L88 499M132 499L133 500L132 499z"/>
<path fill={colours[3]} d="M320 499L321 500L320 499z"/>
<path fill={colours[0]} d="M388 499L389 500L388 499z"/>
<path fill={colours[2]} d="M425 499L426 500L425 499M454 499L455 500L454 499z"/>
<path fill={colours[0]} d="M86 500L87 501L86 500z"/>
<path fill={colours[2]} d="M131 500L132 501L131 500M161 500L161 505L162 505L161 500M211 500L212 501L211 500z"/>
<path fill={colours[3]} d="M321 500L322 501L321 500z"/>
<path fill={colours[2]} d="M39 501L40 502L39 501z"/>
<path fill={colours[0]} d="M64.3333 501.667L64.6667 502.333L64.3333 501.667M84 501L85 502L84 501z"/>
<path fill={colours[2]} d="M210 501L211 502L210 501z"/>
<path fill={colours[3]} d="M322 501L323 502L322 501z"/>
<path fill={colours[0]} d="M357 501L358 502L357 501M387 501L388 502L387 501z"/>
<path fill={colours[2]} d="M426 501L427 502L426 501z"/>
<path fill={colours[0]} d="M532 501L532 507L533 507L532 501M82 502L83 503L82 502M128 502L129 503L128 502z"/>
<path fill={colours[2]} d="M136 502L136 518L137 518L136 502z"/>
<path fill={colours[0]} d="M209 502L210 503L209 502z"/>
<path fill={colours[3]} d="M323 502L324 503L323 502z"/>
<path fill={colours[0]} d="M427 502L428 503L427 502M456 502L457 503L456 502M80 503L81 504L80 503z"/>
<path fill={colours[2]} d="M127 503L128 504L127 503z"/>
<path fill={colours[0]} d="M208 503L209 504L208 503z"/>
<path fill={colours[3]} d="M355 503L356 504L355 503z"/>
<path fill={colours[2]} d="M457 503L458 504L457 503z"/>
<path fill={colours[0]} d="M40.3333 504.667L40.6667 505.333L40.3333 504.667z"/>
<path fill={colours[3]} d="M65 504L66 505L65 504z"/>
<path fill={colours[0]} d="M207 504L208 505L207 504z"/>
<path fill={colours[2]} d="M285 504L285 510L286 510L285 504z"/>
<path fill={colours[0]} d="M385 504L386 505L385 504M428 504L428 507L429 507L428 504M75 505L76 506L75 505M124 505L125 506L124 505M206 505L207 506L206 505M354 505L355 506L354 505M458 505L459 506L458 505z"/>
<path fill={colours[2]} d="M40.3333 506.667L40.6667 507.333L40.3333 506.667z"/>
<path fill={colours[0]} d="M65 506L66 507L65 506M72 506L73 507L72 506z"/>
<path fill={colours[2]} d="M123 506L124 507L123 506z"/>
<path fill={colours[0]} d="M181 506L182 507L181 506M205 506L206 507L205 506M327 506L328 507L327 506z"/>
<path fill={colours[2]} d="M384 506L385 507L384 506z"/>
<path fill={colours[0]} d="M459 506L460 507L459 506M68 507L69 508L68 507M121 507L122 508L121 507M182 507L183 508L182 507M328 507L329 508L328 507z"/>
<path fill={colours[3]} d="M352 507L353 508L352 507z"/>
<path fill={colours[0]} d="M383 507L384 508L383 507z"/>
<path fill={colours[2]} d="M460 507L461 508L460 507M120 508L121 509L120 508z"/>
<path fill={colours[0]} d="M183 508L184 509L183 508M329 508L330 509L329 508M556 508L556 512L557 512L556 508M41.3333 509.667L41.6667 510.333L41.3333 509.667M118 509L119 510L118 509z"/>
<path fill={colours[2]} d="M184 509L185 510L184 509M117 510L118 511L117 510M185 510L186 511L185 510z"/>
<path fill={colours[0]} d="M285 510L285 515L286 515L285 510z"/>
<path fill={colours[3]} d="M332 510L333 511L332 510z"/>
<path fill={colours[0]} d="M350 510L351 511L350 510z"/>
<path fill={colours[2]} d="M381 510L382 511L381 510M427.333 510.667L427.667 511.333L427.333 510.667z"/>
<path fill={colours[0]} d="M462 510L463 511L462 510z"/>
<path fill={colours[2]} d="M41 511L42 512L41 511z"/>
<path fill={colours[0]} d="M115 511L116 512L115 511M200 511L201 512L200 511M380 511L381 512L380 511z"/>
<path fill={colours[2]} d="M463 511L464 512L463 511M531.333 511.667L531.667 512.333L531.333 511.667M114 512L115 513L114 512M188 512L189 513L188 512z"/>
<path fill={colours[0]} d="M198 512L199 513L198 512z"/>
<path fill={colours[3]} d="M348 512L349 513L348 512z"/>
<path fill={colours[0]} d="M427.333 512.667L427.667 513.333L427.333 512.667z"/>
<path fill={colours[2]} d="M556 512L556 515L557 515L556 512z"/>
<path fill={colours[0]} d="M112 513L113 514L112 513z"/>
<path fill={colours[2]} d="M191 513L192 514L191 513z"/>
<path fill={colours[0]} d="M192 513L192 514L196 514L192 513z"/>
<path fill={colours[2]} d="M196 513L197 514L196 513z"/>
<path fill={colours[0]} d="M335 513L336 514L335 513z"/>
<path fill={colours[2]} d="M379 513L380 514L379 513z"/>
<path fill={colours[0]} d="M531 513L531 516L532 516L531 513M42.3333 514.667L42.6667 515.333L42.3333 514.667M110 514L111 515L110 514M378 514L379 515L378 514z"/>
<path fill={colours[2]} d="M109 515L110 516L109 515z"/>
<path fill={colours[3]} d="M338 515L339 516L338 515z"/>
<path fill={colours[0]} d="M346 515L347 516L346 515M466 515L467 516L466 515z"/>
<path fill={colours[2]} d="M42 516L43 517L42 516z"/>
<path fill={colours[0]} d="M107 516L108 517L107 516M345 516L346 517L345 516z"/>
<path fill={colours[2]} d="M426.333 516.667L426.667 517.333L426.333 516.667z"/>
<path fill={colours[0]} d="M467 516L468 517L467 516M105 517L106 518L105 517z"/>
<path fill={colours[2]} d="M161 517L161 524L162 524L161 517z"/>
<path fill={colours[0]} d="M340 517L341 518L340 517z"/>
<path fill={colours[2]} d="M376 517L377 518L376 517M468 517L469 518L468 517M104 518L105 519L104 518M284.333 518.667L284.667 519.333L284.333 518.667z"/>
<path fill={colours[3]} d="M342 518L343 519L342 518z"/>
<path fill={colours[0]} d="M375 518L376 519L375 518M426 518L427 519L426 518z"/>
<path fill={colours[2]} d="M469 518L470 519L469 518z"/>
<path fill={colours[0]} d="M555 518L555 521L556 521L555 518M43 519L44 520L43 519M102 519L103 520L102 519z"/>
<path fill={colours[2]} d="M530.333 519.667L530.667 520.333L530.333 519.667M43 520L44 521L43 520z"/>
<path fill={colours[0]} d="M100 520L101 521L100 520M284.333 520.667L284.667 521.333L284.333 520.667M98 521L99 522L98 521z"/>
<path fill={colours[2]} d="M373 521L374 522L373 521M425.333 521.667L425.667 522.333L425.333 521.667z"/>
<path fill={colours[0]} d="M530.333 521.667L530.667 522.333L530.333 521.667z"/>
<path fill={colours[2]} d="M555 521L556 522L555 521z"/>
<path fill={colours[0]} d="M96 522L97 523L96 522M372 522L373 523L372 522M44 523L45 524L44 523M94 523L95 524L94 523z"/>
<path fill={colours[2]} d="M309 523L310 524L309 523z"/>
<path fill={colours[0]} d="M425 523L426 524L425 523M473 523L474 524L473 523M92.3333 524.667L92.6667 525.333L92.3333 524.667M137 524L137 530L138 530L137 524M161 524L161 529L162 529L161 524z"/>
<path fill={colours[2]} d="M310 524L311 525L310 524z"/>
<path fill={colours[0]} d="M474 524L475 525L474 524z"/>
<path fill={colours[2]} d="M283 525L284 526L283 525z"/>
<path fill={colours[0]} d="M308.333 525.667L308.667 526.333L308.333 525.667M475 525L476 526L475 525z"/>
<path fill={colours[2]} d="M529 525L530 526L529 525z"/>
<path fill={colours[0]} d="M554.333 525.667L554.667 526.333L554.333 525.667z"/>
<path fill={colours[2]} d="M45 526L46 527L45 526M92 526L93 527L92 526z"/>
<path fill={colours[0]} d="M283.333 526.667L283.667 527.333L283.333 526.667z"/>
<path fill={colours[2]} d="M369 526L370 527L369 526M424 526L425 527L424 526z"/>
<path fill={colours[0]} d="M476 526L477 527L476 526M529.333 526.667L529.667 527.333L529.333 526.667z"/>
<path fill={colours[2]} d="M308.333 527.667L308.667 528.333L308.333 527.667z"/>
<path fill={colours[0]} d="M368 527L369 528L368 527M424 527L425 528L424 527z"/>
<path fill={colours[2]} d="M477 527L478 528L477 527M554 527L555 528L554 527M478 528L479 529L478 528M47 529L48 530L47 529z"/>
<path fill={colours[0]} d="M316 529L317 530L316 529z"/>
<path fill={colours[2]} d="M479 529L480 530L479 529z"/>
<path fill={colours[0]} d="M91 530L91 533L92 533L91 530z"/>
<path fill={colours[2]} d="M137 530L137 533L138 533L137 530M282 530L283 531L282 530z"/>
<path fill={colours[0]} d="M317 530L318 531L317 530z"/>
<path fill={colours[2]} d="M423 530L424 531L423 530M480 530L481 531L480 530M528 530L529 531L528 530z"/>
<path fill={colours[0]} d="M553.333 530.667L553.667 531.333L553.333 530.667M50 531L51 532L50 531M282 531L283 532L282 531M307.333 531.667L307.667 532.333L307.333 531.667z"/>
<path fill={colours[2]} d="M318 531L319 532L318 531M365 531L366 532L365 531z"/>
<path fill={colours[0]} d="M423 531L424 532L423 531z"/>
<path fill={colours[2]} d="M481 531L482 532L481 531z"/>
<path fill={colours[0]} d="M528 531L529 532L528 531M52 532L53 533L52 532M63 532L63 533L67 533L63 532z"/>
<path fill={colours[2]} d="M162 532L162 535L163 535L162 532M364 532L365 533L364 532z"/>
<path fill={colours[3]} d="M448 532C443.655 550.954 435.453 568.721 426.752 586C423.413 592.631 414.861 601.538 414.485 609C414.185 614.929 419.305 622.759 421.781 628C429.442 644.219 438.645 659.718 448 675C453.763 670.097 456.765 662.495 460.424 656C470.567 637.997 477.848 618.008 482.873 598C484.631 591.002 489.752 578.088 487.357 571.039C485.648 566.01 478.736 562.389 475 558.911C465.704 550.255 457.319 540.616 448 532z"/>
<path fill={colours[2]} d="M482 532L483 533L482 532M553 532L554 533L553 532M56.6667 533.333L57.3333 533.667L56.6667 533.333M66.3333 533.667L66.6667 534.333L66.3333 533.667M91.3333 533.667L91.6667 534.333L91.3333 533.667M307 533L308 534L307 533z"/>
<path fill={colours[0]} d="M321 533L322 534L321 533M363 533L364 534L363 533z"/>
<path fill={colours[2]} d="M422 533L423 534L422 533z"/>
<path fill={colours[0]} d="M447 533L448 534L447 533z"/>
<path fill={colours[2]} d="M449 533L450 534L449 533M483 533L484 534L483 533M281 534L282 535L281 534z"/>
<path fill={colours[0]} d="M322 534L323 535L322 534M362 534L363 535L362 534M422 534L423 535L422 534z"/>
<path fill={colours[2]} d="M447 534L448 535L447 534M450 534L451 535L450 534M484 534L485 535L484 534M527 534L528 535L527 534z"/>
<path fill={colours[0]} d="M66 535L66 541L67 541L66 535M162.333 535.667L162.667 536.333L162.333 535.667M281 535L282 536L281 535z"/>
<path fill={colours[2]} d="M323 535L324 536L323 535z"/>
<path fill={colours[0]} d="M451 535L452 536L451 535M485 535L486 536L485 535M527 535L528 536L527 535M552.333 535.667L552.667 536.333L552.333 535.667M138 536L138 539L139 539L138 536M306.333 536.667L306.667 537.333L306.333 536.667M446 536L447 537L446 536M452 536L453 537L452 536M486 536L487 537L486 536M326 537L327 538L326 537M421 537L422 538L421 537z"/>
<path fill={colours[2]} d="M446 537L447 538L446 537z"/>
<path fill={colours[0]} d="M453 537L454 538L453 537M487 537L488 538L487 537z"/>
<path fill={colours[2]} d="M552 537L553 538L552 537M280 538L281 539L280 538M306 538L307 539L306 538M327 538L328 539L327 538z"/>
<path fill={colours[0]} d="M488 538L489 539L488 538z"/>
<path fill={colours[2]} d="M526 538L527 539L526 538z"/>
<path fill={colours[0]} d="M90 539L90 545L91 545L90 539z"/>
<path fill={colours[2]} d="M138.333 539.667L138.667 540.333L138.333 539.667z"/>
<path fill={colours[0]} d="M280 539L281 540L280 539M526 539L527 540L526 539z"/>
<path fill={colours[2]} d="M163.333 540.667L163.667 541.333L163.333 540.667z"/>
<path fill={colours[0]} d="M330 540L331 541L330 540z"/>
<path fill={colours[2]} d="M357 540L358 541L357 540z"/>
<path fill={colours[0]} d="M420 540L421 541L420 540M445 540L446 541L445 540M551 540L552 541L551 540z"/>
<path fill={colours[2]} d="M279 541L280 542L279 541z"/>
<path fill={colours[0]} d="M305 541L306 542L305 541z"/>
<path fill={colours[2]} d="M331 541L332 542L331 541M356 541L357 542L356 541M445 541L446 542L445 541M525 541L526 542L525 541M551 541L552 542L551 541z"/>
<path fill={colours[0]} d="M163.333 542.667L163.667 543.333L163.333 542.667M279 542L280 543L279 542z"/>
<path fill={colours[2]} d="M305 542L306 543L305 542z"/>
<path fill={colours[0]} d="M333 542L334 543L333 542z"/>
<path fill={colours[2]} d="M355 542L356 543L355 542z"/>
<path fill={colours[0]} d="M525 542L526 543L525 542z"/>
<path fill={colours[2]} d="M334 543L335 544L334 543M354 543L355 544L354 543z"/>
<path fill={colours[0]} d="M419 543L420 544L419 543M444 543L445 544L444 543z"/>
<path fill={colours[2]} d="M494 543L495 544L494 543z"/>
<path fill={colours[0]} d="M550 543L551 544L550 543M139.333 544.667L139.667 545.333L139.333 544.667M304.333 544.667L304.667 545.333L304.333 544.667M336 544L337 545L336 544M353 544L354 545L353 544z"/>
<path fill={colours[2]} d="M444 544L445 545L444 544M495 544L496 545L495 544M524 544L525 545L524 544M550 544L551 545L550 544M90 545L90 553L91 553L90 545z"/>
<path fill={colours[0]} d="M278 545L279 546L278 545z"/>
<path fill={colours[2]} d="M352 545L353 546L352 545M418 545L419 546L418 545z"/>
<path fill={colours[0]} d="M496 545L497 546L496 545M524 545L525 546L524 545z"/>
<path fill={colours[2]} d="M139 546L140 547L139 546M164 546L165 547L164 546M339 546L340 547L339 546z"/>
<path fill={colours[0]} d="M350 546L351 547L350 546M418 546L419 547L418 546M443 546L444 547L443 546M497 546L498 547L497 546z"/>
<path fill={colours[2]} d="M65 547L65 562L66 562L65 547z"/>
<path fill={colours[0]} d="M164.333 547.667L164.667 548.333L164.333 547.667z"/>
<path fill={colours[2]} d="M341 547L342 548L341 547z"/>
<path fill={colours[0]} d="M342 547L342 548L348 548L342 547z"/>
<path fill={colours[2]} d="M348 547L349 548L348 547M443 547L444 548L443 547M523 547L524 548L523 547z"/>
<path fill={colours[0]} d="M549 547L550 548L549 547M277 548L278 549L277 548M303 548L304 549L303 548z"/>
<path fill={colours[2]} d="M417 548L418 549L417 548M500 548L501 549L500 548M549 548L550 549L549 548z"/>
<path fill={colours[0]} d="M140.333 549.667L140.667 550.333L140.333 549.667z"/>
<path fill={colours[2]} d="M303 549L304 550L303 549z"/>
<path fill={colours[0]} d="M442 549L443 550L442 549z"/>
<path fill={colours[2]} d="M501 549L502 550L501 549M442 550L443 551L442 550z"/>
<path fill={colours[0]} d="M502 550L503 551L502 550M522 550L523 551L522 550M548 550L549 551L548 550z"/>
<path fill={colours[2]} d="M140.333 551.667L140.667 552.333L140.333 551.667M165 551L166 552L165 551z"/>
<path fill={colours[0]} d="M276 551L277 552L276 551M416 551L417 552L416 551z"/>
<path fill={colours[2]} d="M548 551L549 552L548 551z"/>
<path fill={colours[0]} d="M165.333 552.667L165.667 553.333L165.333 552.667z"/>
<path fill={colours[2]} d="M302 552L303 553L302 552z"/>
<path fill={colours[0]} d="M441 552L442 553L441 552z"/>
<path fill={colours[2]} d="M505 552L506 553L505 552z"/>
<path fill={colours[0]} d="M521 552L522 553L521 552z"/>
<path fill={colours[2]} d="M275 553L276 554L275 553M415 553L416 554L415 553M441 553L442 554L441 553z"/>
<path fill={colours[0]} d="M506 553L507 554L506 553M547 553L548 554L547 553M275 554L276 555L275 554M507 554L508 555L507 554z"/>
<path fill={colours[2]} d="M520 554L521 555L520 554z"/>
<path fill={colours[0]} d="M141.333 555.667L141.667 556.333L141.333 555.667z"/>
<path fill={colours[2]} d="M301 555L302 556L301 555M414 555L415 556L414 555z"/>
<path fill={colours[0]} d="M440 555L441 556L440 555z"/>
<path fill={colours[2]} d="M509 555L510 556L509 555M166 556L167 557L166 556z"/>
<path fill={colours[0]} d="M274 556L275 557L274 556M414 556L415 557L414 556M510 556L511 557L510 556z"/>
<path fill={colours[2]} d="M519 556L520 557L519 556M546 556L547 557L546 556M141 557L142 558L141 557z"/>
<path fill={colours[0]} d="M166 557L167 558L166 557M439 557L440 558L439 557M473 557L474 558L473 557M511 557L512 558L511 557z"/>
<path fill={colours[2]} d="M90 558L90 567L91 567L90 558M273 558L274 559L273 558M300 558L301 559L300 558z"/>
<path fill={colours[0]} d="M413 558L414 559L413 558z"/>
<path fill={colours[2]} d="M439 558L440 559L439 558z"/>
<path fill={colours[0]} d="M474 558L475 559L474 558z"/>
<path fill={colours[2]} d="M513 558L514 559L513 558M518 558L519 559L518 558z"/>
<path fill={colours[0]} d="M545 558L546 559L545 558M142.333 559.667L142.667 560.333L142.333 559.667z"/>
<path fill={colours[2]} d="M167 559L168 560L167 559z"/>
<path fill={colours[0]} d="M273 559L274 560L273 559M475 559L476 560L475 559M514 559L515 560L514 559z"/>
<path fill={colours[2]} d="M545 559L546 560L545 559z"/>
<path fill={colours[0]} d="M167.333 560.667L167.667 561.333L167.333 560.667M412 560L413 561L412 560M438 560L439 561L438 560z"/>
<path fill={colours[2]} d="M476 560L477 561L476 560z"/>
<path fill={colours[0]} d="M517 560L518 561L517 560z"/>
<path fill={colours[2]} d="M142 561L143 562L142 561z"/>
<path fill={colours[0]} d="M272 561L273 562L272 561z"/>
<path fill={colours[2]} d="M477 561L478 562L477 561M544 561L545 562L544 561M300 562L301 563L300 562z"/>
<path fill={colours[0]} d="M411 562L412 563L411 562z"/>
<path fill={colours[2]} d="M168 563L169 564L168 563M271 563L272 564L271 563z"/>
<path fill={colours[0]} d="M300 563L301 564L300 563z"/>
<path fill={colours[2]} d="M437 563L438 564L437 563z"/>
<path fill={colours[0]} d="M543 563L544 564L543 563M143 564L144 565L143 564M168 564L169 565L168 564z"/>
<path fill={colours[2]} d="M410 564L411 565L410 564z"/>
<path fill={colours[0]} d="M481 564L482 565L481 564z"/>
<path fill={colours[2]} d="M143 565L144 566L143 565M270 565L271 566L270 565M301 565L302 566L301 565z"/>
<path fill={colours[0]} d="M436 565L437 566L436 565z"/>
<path fill={colours[2]} d="M482 565L483 566L482 565z"/>
<path fill={colours[0]} d="M542 565L543 566L542 565M270 566L271 567L270 566M301 566L302 567L301 566z"/>
<path fill={colours[2]} d="M409 566L410 567L409 566M483 566L484 567L483 566z"/>
<path fill={colours[0]} d="M90 567L90 572L91 572L90 567z"/>
<path fill={colours[2]} d="M169 567L170 568L169 567M269 567L270 568L269 567z"/>
<path fill={colours[0]} d="M435 567L436 568L435 567M541 567L542 568L541 567M144 568L145 569L144 568M269 568L270 569L269 568z"/>
<path fill={colours[2]} d="M302 568L303 569L302 568M408 568L409 569L408 568M435 568L436 569L435 568z"/>
<path fill={colours[0]} d="M486 568L487 569L486 568z"/>
<path fill={colours[2]} d="M144 569L145 570L144 569z"/>
<path fill={colours[0]} d="M302 569L303 570L302 569M487 569L488 570L487 569M540 569L541 570L540 569M66 570L66 576L67 576L66 570z"/>
<path fill={colours[2]} d="M170 570L171 571L170 570z"/>
<path fill={colours[0]} d="M268 570L269 571L268 570z"/>
<path fill={colours[2]} d="M407 570L408 571L407 570M434 570L435 571L434 570z"/>
<path fill={colours[0]} d="M488 570L488 573L489 573L488 570M145 571L146 572L145 571M170 571L171 572L170 571z"/>
<path fill={colours[2]} d="M303 571L304 572L303 571M539 571L540 572L539 571M145 572L146 573L145 572z"/>
<path fill={colours[0]} d="M267 572L268 573L267 572M406 572L407 573L406 572M433 572L434 573L433 572z"/>
<path fill={colours[2]} d="M171 573L172 574L171 573M538 573L539 574L538 573z"/>
<path fill={colours[0]} d="M171 574L172 575L171 574M266 574L267 575L266 574M304 574L305 575L304 574M405 574L406 575L405 574M432 574L433 575L432 574M537 574L538 575L537 574M146 575L147 576L146 575z"/>
<path fill={colours[2]} d="M487.333 575.667L487.667 576.333L487.333 575.667M66 576L66 579L67 579L66 576M91.3333 576.667L91.6667 577.333L91.3333 576.667M146 576L147 577L146 576M172 576L173 577L172 576z"/>
<path fill={colours[0]} d="M265 576L266 577L265 576z"/>
<path fill={colours[2]} d="M305 576L306 577L305 576z"/>
<path fill={colours[0]} d="M404 576L405 577L404 576M431 576L432 577L431 576z"/>
<path fill={colours[2]} d="M536 576L537 577L536 576z"/>
<path fill={colours[0]} d="M172 577L173 578L172 577z"/>
<path fill={colours[2]} d="M264 577L265 578L264 577M403 577L404 578L403 577z"/>
<path fill={colours[0]} d="M487.333 577.667L487.667 578.333L487.333 577.667M535 577L536 578L535 577M91 578L91 581L92 581L91 578M147 578L148 579L147 578M264 578L265 579L264 578z"/>
<path fill={colours[2]} d="M306 578L307 579L306 578z"/>
<path fill={colours[0]} d="M403 578L404 579L403 578M430 578L431 579L430 578z"/>
<path fill={colours[2]} d="M147 579L148 580L147 579M173 579L174 580L173 579M263 579L264 580L263 579z"/>
<path fill={colours[0]} d="M306 579L307 580L306 579z"/>
<path fill={colours[2]} d="M402 579L403 580L402 579M534 579L535 580L534 579z"/>
<path fill={colours[0]} d="M263 580L264 581L263 580M429 580L430 581L429 580M533 580L534 581L533 580M67 581L67 585L68 585L67 581M148 581L149 582L148 581z"/>
<path fill={colours[2]} d="M262 581L263 582L262 581z"/>
<path fill={colours[0]} d="M307 581L308 582L307 581M401 581L402 582L401 581z"/>
<path fill={colours[2]} d="M486.333 581.667L486.667 582.333L486.333 581.667z"/>
<path fill={colours[0]} d="M174 582L175 583L174 582M428 582L429 583L428 582M261 583L262 584L261 583M308 583L309 584L308 583M400 583L401 584L400 583M486.333 583.667L486.667 584.333L486.333 583.667z"/>
<path fill={colours[2]} d="M92.3333 584.667L92.6667 585.333L92.3333 584.667z"/>
<path fill={colours[0]} d="M149 584L150 585L149 584z"/>
<path fill={colours[2]} d="M175 584L176 585L175 584M399 584L400 585L399 584z"/>
<path fill={colours[0]} d="M427 584L428 585L427 584z"/>
<path fill={colours[2]} d="M530 584L531 585L530 584M67.3333 585.667L67.6667 586.333L67.3333 585.667z"/>
<path fill={colours[0]} d="M175 585L176 586L175 585M260 585L261 586L260 585M309 585L310 586L309 585z"/>
<path fill={colours[2]} d="M529 585L530 586L529 585z"/>
<path fill={colours[0]} d="M92.3333 586.667L92.6667 587.333L92.3333 586.667M398 586L399 587L398 586M426 586L427 587L426 586z"/>
<path fill={colours[2]} d="M485.333 586.667L485.667 587.333L485.333 586.667z"/>
<path fill={colours[0]} d="M511 586L512 587L511 586z"/>
<path fill={colours[2]} d="M528 586L529 587L528 586z"/>
<path fill={colours[0]} d="M150 587L151 588L150 587M176 587L177 588L176 587M259 587L260 588L259 587M310 587L311 588L310 587M513 587L514 588L513 587z"/>
<path fill={colours[2]} d="M258 588L259 589L258 588z"/>
<path fill={colours[0]} d="M397 588L398 589L397 588M425 588L426 589L425 588M485 588L486 589L485 588M510 588L511 589L510 588z"/>
<path fill={colours[2]} d="M514 588L515 589L514 588M525 588L526 589L525 588z"/>
<path fill={colours[0]} d="M68 589L68 592L69 592L68 589M151 589L152 590L151 589z"/>
<path fill={colours[2]} d="M177 589L178 590L177 589z"/>
<path fill={colours[0]} d="M311 589L312 590L311 589z"/>
<path fill={colours[2]} d="M396 589L397 590L396 589M510 589L511 590L510 589M517 589L518 590L517 589z"/>
<path fill={colours[0]} d="M518 589L518 590L522 590L518 589z"/>
<path fill={colours[2]} d="M522 589L523 590L522 589M93 590L94 591L93 590M151 590L152 591L151 590z"/>
<path fill={colours[0]} d="M177 590L178 591L177 590M257 590L258 591L257 590z"/>
<path fill={colours[2]} d="M424 590L425 591L424 590z"/>
<path fill={colours[0]} d="M93.3333 591.667L93.6667 592.333L93.3333 591.667M312 591L313 592L312 591M395 591L396 592L395 591z"/>
<path fill={colours[2]} d="M484 591L485 592L484 591M68 592L69 593L68 592z"/>
<path fill={colours[0]} d="M152 592L153 593L152 592M178 592L179 593L178 592M256 592L257 593L256 592M284.667 592.333L285.333 592.667L284.667 592.333z"/>
<path fill={colours[2]} d="M394 592L395 593L394 592M423 592L424 593L423 592z"/>
<path fill={colours[0]} d="M484 592L485 593L484 592M509.333 592.667L509.667 593.333L509.333 592.667z"/>
<path fill={colours[2]} d="M255 593L256 594L255 593M284 593L285 594L284 593z"/>
<path fill={colours[3]} d="M285 593C279.383 600.866 268.1 614.493 266.512 624C265.119 632.344 271.378 645.133 274 653C283.208 680.623 296.188 706.782 312.333 731C319.271 741.406 326.914 751.587 335.156 761C337.536 763.718 341.108 769.606 345.015 770.079C348.539 770.505 351.798 766.252 353.91 763.999C360.594 756.871 366.481 748.947 372.14 741C384.708 723.349 394.71 703.06 402.799 683C405.3 676.798 410.672 667.791 410.281 661C409.772 652.161 400.441 641.265 397 633L395 633C389.804 640.599 383.341 647.277 377.09 654C369.949 661.679 358.156 678.831 347 680.13C337.244 681.267 330.381 667.671 325.72 661C315.813 646.824 306.825 632.027 298.281 617C293.901 609.297 290.632 599.797 285 593z"/>
<path fill={colours[0]} d="M313 593L314 594L313 593M422 593L423 594L422 593M153 594L154 595L153 594M179 594L180 595L179 594M283 594L284 595L283 594M286 594L287 595L286 594M393 594L394 595L393 594z"/>
<path fill={colours[2]} d="M509 594L510 595L509 594z"/>
<path fill={colours[0]} d="M69 595L69 598L70 598L69 595z"/>
<path fill={colours[2]} d="M94.3333 595.667L94.6667 596.333L94.3333 595.667M153 595L154 596L153 595z"/>
<path fill={colours[0]} d="M254 595L255 596L254 595M314 595L315 596L314 595M421 595L422 596L421 595z"/>
<path fill={colours[2]} d="M483 595L484 596L483 595M180 596L181 597L180 596z"/>
<path fill={colours[0]} d="M282 596L283 597L282 596M287 596L288 597L287 596M483.333 596.667L483.667 597.333L483.333 596.667M94 597L95 598L94 597M154 597L155 598L154 597M315 597L316 598L315 597M391 597L392 598L391 597z"/>
<path fill={colours[2]} d="M420 597L421 598L420 597z"/>
<path fill={colours[0]} d="M508 597L509 598L508 597z"/>
<path fill={colours[2]} d="M69 598L70 599L69 598M154 598L155 599L154 598M181 598L182 599L181 598z"/>
<path fill={colours[0]} d="M252 598L253 599L252 598z"/>
<path fill={colours[2]} d="M281 598L282 599L281 598z"/>
<path fill={colours[0]} d="M288 598L289 599L288 598M419 598L420 599L419 598z"/>
<path fill={colours[2]} d="M508 598L509 599L508 598z"/>
<path fill={colours[0]} d="M181 599L182 600L181 599M316 599L317 600L316 599z"/>
<path fill={colours[2]} d="M482 599L483 600L482 599M95 600L96 601L95 600M182 600L183 601L182 600z"/>
<path fill={colours[0]} d="M251 600L252 601L251 600z"/>
<path fill={colours[2]} d="M280 600L281 601L280 600z"/>
<path fill={colours[0]} d="M289 600L290 601L289 600z"/>
<path fill={colours[2]} d="M317 600L318 601L317 600z"/>
<path fill={colours[0]} d="M389 600L390 601L389 600M418 600L419 601L418 600M482 600L483 601L482 600M70 601L71 602L70 601M95 601L96 602L95 601M182 601L183 602L182 601z"/>
<path fill={colours[2]} d="M250 601L251 602L250 601z"/>
<path fill={colours[0]} d="M279 601L280 602L279 601z"/>
<path fill={colours[2]} d="M388 601L389 602L388 601z"/>
<path fill={colours[0]} d="M507 601L508 602L507 601z"/>
<path fill={colours[2]} d="M70 602L71 603L70 602z"/>
<path fill={colours[0]} d="M290 602L291 603L290 602z"/>
<path fill={colours[2]} d="M318 602L319 603L318 602M507 602L508 603L507 602z"/>
<path fill={colours[0]} d="M150 603L151 604L150 603M183 603L184 604L183 603M249 603L250 604L249 603M278 603L279 604L278 603M387 603L388 604L387 603M416 603L417 604L416 603z"/>
<path fill={colours[2]} d="M481 603L482 604L481 603M96 604L97 605L96 604z"/>
<path fill={colours[0]} d="M149 604L150 605L149 604z"/>
<path fill={colours[2]} d="M248 604L249 605L248 604z"/>
<path fill={colours[0]} d="M291 604L292 605L291 604M319 604L320 605L319 604z"/>
<path fill={colours[2]} d="M386 604L387 605L386 604z"/>
<path fill={colours[0]} d="M481 604L482 605L481 604M71.3333 605.667L71.6667 606.333L71.3333 605.667M96 605L97 606L96 605M148 605L149 606L148 605M184 605L185 606L184 605z"/>
<path fill={colours[2]} d="M277 605L278 606L277 605M415 605L416 606L415 605z"/>
<path fill={colours[0]} d="M506 605L507 606L506 605M147 606L148 607L147 606M247 606L248 607L247 606M276 606L277 607L276 606M292 606L293 607L292 606M320 606L321 607L320 606M414 606L415 607L414 606z"/>
<path fill={colours[2]} d="M480 606L481 607L480 606M506 606L507 607L506 606M71 607L72 608L71 607z"/>
<path fill={colours[0]} d="M146 607L147 608L146 607M185 607L186 608L185 607z"/>
<path fill={colours[2]} d="M246 607L247 608L246 607z"/>
<path fill={colours[0]} d="M384 607L385 608L384 607M480 607L481 608L480 607z"/>
<path fill={colours[2]} d="M97 608L98 609L97 608z"/>
<path fill={colours[0]} d="M145 608L146 609L145 608M275 608L276 609L275 608z"/>
<path fill={colours[2]} d="M293 608L294 609L293 608z"/>
<path fill={colours[0]} d="M321 608L322 609L321 608z"/>
<path fill={colours[2]} d="M383 608L384 609L383 608M413.333 608.667L413.667 609.333L413.333 608.667z"/>
<path fill={colours[0]} d="M144 609L145 610L144 609M186 609L187 610L186 609z"/>
<path fill={colours[2]} d="M322 609L323 610L322 609z"/>
<path fill={colours[0]} d="M505 609L506 610L505 609M72 610L73 611L72 610M244 610L245 611L244 610z"/>
<path fill={colours[2]} d="M294 610L295 611L294 610z"/>
<path fill={colours[0]} d="M382 610L383 611L382 610z"/>
<path fill={colours[2]} d="M479 610L480 611L479 610M505 610L506 611L505 610M72 611L73 612L72 611M98 611L99 612L98 611z"/>
<path fill={colours[0]} d="M187 611L188 612L187 611z"/>
<path fill={colours[2]} d="M243 611L244 612L243 611z"/>
<path fill={colours[0]} d="M273 611L274 612L273 611M295 611L296 612L295 611M323 611L324 612L323 611M381 611L382 612L381 611z"/>
<path fill={colours[2]} d="M414 611L415 612L414 611z"/>
<path fill={colours[0]} d="M479 611L480 612L479 611M98 612L99 613L98 612z"/>
<path fill={colours[2]} d="M188 612L189 613L188 612M295 612L296 613L295 612M380 612L381 613L380 612z"/>
<path fill={colours[0]} d="M504 612L505 613L504 612M188 613L189 614L188 613M242 613L243 614L242 613z"/>
<path fill={colours[2]} d="M272 613L273 614L272 613z"/>
<path fill={colours[0]} d="M296 613L297 614L296 613M324 613L325 614L324 613z"/>
<path fill={colours[2]} d="M415 613L416 614L415 613M478 613L479 614L478 613M504 613L505 614L504 613z"/>
<path fill={colours[0]} d="M73 614L74 615L73 614z"/>
<path fill={colours[2]} d="M99 614L100 615L99 614M138 614L139 615L138 614M189 614L190 615L189 614M241 614L242 615L241 614z"/>
<path fill={colours[0]} d="M271 614L272 615L271 614z"/>
<path fill={colours[2]} d="M325 614L326 615L325 614z"/>
<path fill={colours[0]} d="M415 614L416 615L415 614M478 614L479 615L478 614z"/>
<path fill={colours[2]} d="M73 615L74 616L73 615z"/>
<path fill={colours[0]} d="M99 615L100 616L99 615M137 615L138 616L137 615M189 615L190 616L189 615M297 615L298 616L297 615M378 615L379 616L378 615M136 616L137 617L136 616z"/>
<path fill={colours[2]} d="M190 616L191 617L190 616M270 616L271 617L270 616z"/>
<path fill={colours[0]} d="M326 616L327 617L326 616z"/>
<path fill={colours[2]} d="M377 616L378 617L377 616z"/>
<path fill={colours[0]} d="M416 616L417 617L416 616z"/>
<path fill={colours[2]} d="M477 616L478 617L477 616z"/>
<path fill={colours[0]} d="M503 616L504 617L503 616M74 617L75 618L74 617z"/>
<path fill={colours[2]} d="M100 617L101 618L100 617z"/>
<path fill={colours[0]} d="M239 617L240 618L239 617M269 617L270 618L269 617M298 617L299 618L298 617z"/>
<path fill={colours[2]} d="M74 618L75 619L74 618z"/>
<path fill={colours[0]} d="M100 618L101 619L100 618z"/>
<path fill={colours[2]} d="M191 618L192 619L191 618M238 618L239 619L238 618z"/>
<path fill={colours[0]} d="M327 618L328 619L327 618M417 618L418 619L417 618z"/>
<path fill={colours[2]} d="M132 619L133 620L132 619M268 619L269 620L268 619M299 619L300 620L299 619M328 619L329 620L328 619z"/>
<path fill={colours[0]} d="M375 619L376 620L375 619z"/>
<path fill={colours[2]} d="M476 619L477 620L476 619z"/>
<path fill={colours[0]} d="M502 619L503 620L502 619M75 620L76 621L75 620z"/>
<path fill={colours[2]} d="M101 620L102 621L101 620z"/>
<path fill={colours[0]} d="M131 620L132 621L131 620M192 620L193 621L192 620M267 620L268 621L267 620M300 620L301 621L300 620M374 620L375 621L374 620z"/>
<path fill={colours[2]} d="M418 620L419 621L418 620M502 620L503 621L502 620M75 621L76 622L75 621z"/>
<path fill={colours[0]} d="M101 621L102 622L101 621M236 621L237 622L236 621M329 621L330 622L329 621z"/>
<path fill={colours[2]} d="M373 621L374 622L373 621M128 622L129 623L128 622z"/>
<path fill={colours[0]} d="M193 622L194 623L193 622z"/>
<path fill={colours[2]} d="M235 622L236 623L235 622M266 622L267 623L266 622z"/>
<path fill={colours[0]} d="M301 622L302 623L301 622z"/>
<path fill={colours[2]} d="M419 622L420 623L419 622z"/>
<path fill={colours[0]} d="M475 622L476 623L475 622M501 622L502 623L501 622z"/>
<path fill={colours[2]} d="M102 623L103 624L102 623z"/>
<path fill={colours[0]} d="M127 623L128 624L127 623z"/>
<path fill={colours[2]} d="M194 623L195 624L194 623z"/>
<path fill={colours[0]} d="M265.333 623.667L265.667 624.333L265.333 623.667M330 623L331 624L330 623M419 623L420 624L419 623z"/>
<path fill={colours[2]} d="M501 623L502 624L501 623z"/>
<path fill={colours[0]} d="M76 624L77 625L76 624z"/>
<path fill={colours[2]} d="M125 624L126 625L125 624z"/>
<path fill={colours[3]} d="M165 624L164 626L165 624z"/>
<path fill={colours[2]} d="M166 624L167 625L166 624z"/>
<path fill={colours[0]} d="M302 624L303 625L302 624z"/>
<path fill={colours[2]} d="M331 624L332 625L331 624z"/>
<path fill={colours[0]} d="M124 625L125 626L124 625z"/>
<path fill={colours[4]} d="M247 649C234.949 657.87 225.489 680.117 210 683.402C201.182 685.272 197.111 676.218 193.688 670C185.314 654.788 175.758 640.004 167 625C161.723 626.794 146.15 637.31 145.118 643.093C143.816 650.39 149.71 662.13 152 669C160.259 693.777 172.094 716.378 186.67 738C190.525 743.719 200.708 762.25 208.09 762.887C211.875 763.213 216.329 758.575 219 756.384C228.762 748.379 237.833 739.129 245.37 729C251.393 720.907 263.896 706.679 263.602 696C263.466 691.065 259.815 685.539 258 681C253.847 670.617 249.525 659.916 247 649z"/>
<path fill={colours[0]} d="M167 625L168 626L167 625z"/>
<path fill={colours[2]} d="M195 625L196 626L195 625z"/>
<path fill={colours[0]} d="M370 625L371 626L370 625M420 625L421 626L420 625M474 625L475 626L474 625M500 625L501 626L500 625M103 626L104 627L103 626M232 626L233 627L232 626z"/>
<path fill={colours[2]} d="M303 626L304 627L303 626z"/>
<path fill={colours[0]} d="M332 626L333 627L332 626M369 626L370 627L369 626z"/>
<path fill={colours[2]} d="M500 626L501 627L500 626M77 627L78 628L77 627z"/>
<path fill={colours[0]} d="M121 627L122 628L121 627M168 627L169 628L168 627M196 627L197 628L196 627z"/>
<path fill={colours[2]} d="M231 627L232 628L231 627M266 627L267 628L266 627z"/>
<path fill={colours[0]} d="M304 627L305 628L304 627z"/>
<path fill={colours[2]} d="M333 627L334 628L333 627M368 627L369 628L368 627z"/>
<path fill={colours[0]} d="M421 627L422 628L421 627z"/>
<path fill={colours[2]} d="M473 627L474 628L473 627M104 628L105 629L104 628z"/>
<path fill={colours[0]} d="M266 628L267 629L266 628M499 628L500 629L499 628M78 629L79 630L78 629M118 629L119 630L118 629M159 629L160 630L159 629M169 629L170 630L169 629M197 629L198 630L197 629M305 629L306 630L305 629M334 629L335 630L334 629M422 629L423 630L422 629z"/>
<path fill={colours[2]} d="M78 630L79 631L78 630M116 630L117 631L116 630z"/>
<path fill={colours[0]} d="M158 630L159 631L158 630z"/>
<path fill={colours[2]} d="M198 630L199 631L198 630M267 630L268 631L267 630M335 630L336 631L335 630z"/>
<path fill={colours[0]} d="M472 630L473 631L472 630M105 631L106 632L105 631M115 631L116 632L115 631z"/>
<path fill={colours[2]} d="M157 631L158 632L157 631z"/>
<path fill={colours[0]} d="M170 631L171 632L170 631M228 631L229 632L228 631M267.333 631.667L267.667 632.333L267.333 631.667z"/>
<path fill={colours[2]} d="M306 631L307 632L306 631z"/>
<path fill={colours[0]} d="M423 631L424 632L423 631M498 631L499 632L498 631M79 632L80 633L79 632M113 632L114 633L113 632z"/>
<path fill={colours[2]} d="M156 632L157 633L156 632z"/>
<path fill={colours[0]} d="M199 632L200 633L199 632z"/>
<path fill={colours[2]} d="M227 632L228 633L227 632z"/>
<path fill={colours[0]} d="M307 632L308 633L307 632M336 632L337 633L336 632M364 632L365 633L364 632z"/>
<path fill={colours[2]} d="M396 632L397 633L396 632M471 632L472 633L471 632z"/>
<path fill={colours[0]} d="M106 633L107 634L106 633z"/>
<path fill={colours[2]} d="M111 633L112 634L111 633z"/>
<path fill={colours[3]} d="M155 633L156 634L155 633z"/>
<path fill={colours[2]} d="M171 633L172 634L171 633M226 633L227 634L226 633z"/>
<path fill={colours[0]} d="M363 633L364 634L363 633M424 633L425 634L424 633z"/>
<path fill={colours[2]} d="M109 634L110 635L109 634z"/>
<path fill={colours[0]} d="M153 634L154 635L153 634M172 634L173 635L172 634M200 634L201 635L200 634z"/>
<path fill={colours[2]} d="M268 634L269 635L268 634z"/>
<path fill={colours[0]} d="M308 634L309 635L308 634z"/>
<path fill={colours[2]} d="M362 634L363 635L362 634M394 634L395 635L394 634M397 634L398 635L397 634M470 634L471 635L470 634M497 634L498 635L497 634M80 635L81 636L80 635z"/>
<path fill={colours[0]} d="M107.667 635.333L108.333 635.667L107.667 635.333M152 635L153 636L152 635z"/>
<path fill={colours[2]} d="M172 635L173 636L172 635M201 635L202 636L201 635z"/>
<path fill={colours[0]} d="M268 635L269 636L268 635M338 635L339 636L338 635z"/>
<path fill={colours[2]} d="M361 635L362 636L361 635M393 635L394 636L393 635z"/>
<path fill={colours[0]} d="M425 635L426 636L425 635M470 635L471 636L470 635z"/>
<path fill={colours[2]} d="M151 636L152 637L151 636z"/>
<path fill={colours[0]} d="M173 636L174 637L173 636z"/>
<path fill={colours[2]} d="M309 636L310 637L309 636M360 636L361 637L360 636z"/>
<path fill={colours[0]} d="M392 636L393 637L392 636M398 636L399 637L398 636M496 636L497 637L496 636M81 637L82 638L81 637z"/>
<path fill={colours[3]} d="M150 637L151 638L150 637z"/>
<path fill={colours[0]} d="M202 637L203 638L202 637M223 637L224 638L223 637M310 637L311 638L310 637M426 637L427 638L426 637M469 637L470 638L469 637z"/>
<path fill={colours[2]} d="M496 637L497 638L496 637z"/>
<path fill={colours[0]} d="M148 638L149 639L148 638M174 638L175 639L174 638z"/>
<path fill={colours[2]} d="M203 638L204 639L203 638z"/>
<path fill={colours[0]} d="M222 638L223 639L222 638z"/>
<path fill={colours[2]} d="M269 638L270 639L269 638z"/>
<path fill={colours[0]} d="M340 638L341 639L340 638M399 638L400 639L399 638M82 639L83 640L82 639z"/>
<path fill={colours[2]} d="M147 639L148 640L147 639M221 639L222 640L221 639z"/>
<path fill={colours[0]} d="M269 639L270 640L269 639z"/>
<path fill={colours[2]} d="M311 639L312 640L311 639z"/>
<path fill={colours[0]} d="M427 639L428 640L427 639z"/>
<path fill={colours[2]} d="M468 639L469 640L468 639z"/>
<path fill={colours[0]} d="M495 639L496 640L495 639z"/>
<path fill={colours[2]} d="M82 640L83 641L82 640M175 640L176 641L175 640z"/>
<path fill={colours[0]} d="M204 640L205 641L204 640z"/>
<path fill={colours[2]} d="M220 640L221 641L220 640z"/>
<path fill={colours[0]} d="M312 640L313 641L312 640z"/>
<path fill={colours[2]} d="M389 640L390 641L389 640z"/>
<path fill={colours[0]} d="M400 640L401 641L400 640M144 641L145 642L144 641M176 641L177 642L176 641M342 641L343 642L342 641M388 641L389 642L388 641M428 641L429 642L428 641z"/>
<path fill={colours[2]} d="M467 641L468 642L467 641z"/>
<path fill={colours[0]} d="M494 641L495 642L494 641z"/>
<path fill={colours[2]} d="M83 642L84 643L83 642z"/>
<path fill={colours[0]} d="M205 642L206 643L205 642M270 642L271 643L270 642M313 642L314 643L313 642M387 642L388 643L387 642M401 642L402 643L401 642M467 642L468 643L467 642z"/>
<path fill={colours[2]} d="M494 642L495 643L494 642M144 643L145 644L144 643z"/>
<path fill={colours[0]} d="M177 643L178 644L177 643z"/>
<path fill={colours[2]} d="M206 643L207 644L206 643z"/>
<path fill={colours[0]} d="M429 643L430 644L429 643z"/>
<path fill={colours[2]} d="M466 643L467 644L466 643z"/>
<path fill={colours[0]} d="M84 644L85 645L84 644M144 644L145 645L144 644z"/>
<path fill={colours[2]} d="M314 644L315 645L314 644z"/>
<path fill={colours[0]} d="M344 644L345 645L344 644M402 644L403 645L402 644z"/>
<path fill={colours[2]} d="M430 644L431 645L430 644z"/>
<path fill={colours[0]} d="M466 644L467 645L466 644z"/>
<path fill={colours[2]} d="M493 644L494 645L493 644M178 645L179 646L178 645z"/>
<path fill={colours[0]} d="M207 645L208 646L207 645M216 645L217 646L216 645z"/>
<path fill={colours[2]} d="M271 645L272 646L271 645z"/>
<path fill={colours[0]} d="M315 645L316 646L315 645M430 645L431 646L430 645z"/>
<path fill={colours[2]} d="M465 645L466 646L465 645z"/>
<path fill={colours[0]} d="M85 646L86 647L85 646z"/>
<path fill={colours[3]} d="M145 646L146 647L145 646z"/>
<path fill={colours[0]} d="M179 646L180 647L179 646z"/>
<path fill={colours[2]} d="M208 646L209 647L208 646z"/>
<path fill={colours[0]} d="M215 646L216 647L215 646z"/>
<path fill={colours[2]} d="M384 646L385 647L384 646z"/>
<path fill={colours[0]} d="M403 646L404 647L403 646z"/>
<path fill={colours[2]} d="M431 646L432 647L431 646z"/>
<path fill={colours[0]} d="M465 646L466 647L465 646M492 646L493 647L492 646M145.333 647.667L145.667 648.333L145.333 647.667M214 647L215 648L214 647z"/>
<path fill={colours[2]} d="M316 647L317 648L316 647z"/>
<path fill={colours[0]} d="M346 647L347 648L346 647M350 647L351 648L350 647z"/>
<path fill={colours[2]} d="M383 647L384 648L383 647z"/>
<path fill={colours[0]} d="M86 648L87 649L86 648M180 648L181 649L180 648M209 648L210 649L209 648z"/>
<path fill={colours[2]} d="M213 648L214 649L213 648M246 648L247 649L246 648M272 648L273 649L272 648z"/>
<path fill={colours[0]} d="M317 648L318 649L317 648z"/>
<path fill={colours[2]} d="M347 648L348 649L347 648M382 648L383 649L382 648z"/>
<path fill={colours[0]} d="M404 648L405 649L404 648z"/>
<path fill={colours[2]} d="M432 648L433 649L432 648z"/>
<path fill={colours[0]} d="M464 648L465 649L464 648M491 648L492 649L491 648z"/>
<path fill={colours[2]} d="M212 649L213 650L212 649M245 649L246 650L245 649z"/>
<path fill={colours[0]} d="M272 649L273 650L272 649M381 649L382 650L381 649z"/>
<path fill={colours[2]} d="M491 649L492 650L491 649z"/>
<path fill={colours[0]} d="M87 650L88 651L87 650z"/>
<path fill={colours[2]} d="M146 650L147 651L146 650M181 650L182 651L181 650z"/>
<path fill={colours[0]} d="M210 650L211 651L210 650z"/>
<path fill={colours[2]} d="M211 650L212 651L211 650M244 650L245 651L244 650z"/>
<path fill={colours[0]} d="M247 650L248 651L247 650z"/>
<path fill={colours[2]} d="M318 650L319 651L318 650z"/>
<path fill={colours[0]} d="M380 650L381 651L380 650z"/>
<path fill={colours[2]} d="M405 650L406 651L405 650z"/>
<path fill={colours[0]} d="M433 650L434 651L433 650M463 650L464 651L463 650M146.333 651.667L146.667 652.333L146.333 651.667M182 651L183 652L182 651M243 651L244 652L243 651z"/>
<path fill={colours[2]} d="M247 651L248 652L247 651M273 651L274 652L273 651z"/>
<path fill={colours[0]} d="M319 651L320 652L319 651z"/>
<path fill={colours[2]} d="M490 651L491 652L490 651M88 652L89 653L88 652z"/>
<path fill={colours[0]} d="M273 652L274 653L273 652z"/>
<path fill={colours[2]} d="M406 652L407 653L406 652z"/>
<path fill={colours[0]} d="M434 652L435 653L434 652M462 652L463 653L462 652z"/>
<path fill={colours[2]} d="M183 653L184 654L183 653z"/>
<path fill={colours[0]} d="M248 653L249 654L248 653z"/>
<path fill={colours[2]} d="M320 653L321 654L320 653M435 653L436 654L435 653z"/>
<path fill={colours[0]} d="M489 653L490 654L489 653z"/>
<path fill={colours[2]} d="M89 654L90 655L89 654M147 654L148 655L147 654z"/>
<path fill={colours[3]} d="M241 654L242 655L241 654z"/>
<path fill={colours[2]} d="M248 654L249 655L248 654M274 654L275 655L274 654z"/>
<path fill={colours[0]} d="M321 654L322 655L321 654z"/>
<path fill={colours[2]} d="M407 654L408 655L407 654z"/>
<path fill={colours[0]} d="M435 654L436 655L435 654M461 654L462 655L461 654M90 655L91 656L90 655M147 655L148 656L147 655z"/>
<path fill={colours[3]} d="M184 655L185 656L184 655z"/>
<path fill={colours[2]} d="M240 655L241 656L240 655z"/>
<path fill={colours[0]} d="M274 655L275 656L274 655M408 655L409 656L408 655z"/>
<path fill={colours[2]} d="M436 655L437 656L436 655M460 655L461 656L460 655z"/>
<path fill={colours[0]} d="M488 655L489 656L488 655M120 656L121 657L120 656z"/>
<path fill={colours[2]} d="M122 656L123 657L122 656z"/>
<path fill={colours[0]} d="M185 656L186 657L185 656z"/>
<path fill={colours[2]} d="M239 656L240 657L239 656z"/>
<path fill={colours[0]} d="M249 656L250 657L249 656z"/>
<path fill={colours[2]} d="M322 656L323 657L322 656z"/>
<path fill={colours[0]} d="M460 656L461 657L460 656z"/>
<path fill={colours[2]} d="M91 657L92 658L91 657z"/>
<path fill={colours[0]} d="M118 657L119 658L118 657M238 657L239 658L238 657z"/>
<path fill={colours[2]} d="M249 657L250 658L249 657M275 657L276 658L275 657z"/>
<path fill={colours[0]} d="M323 657L324 658L323 657M409 657L410 658L409 657z"/>
<path fill={colours[2]} d="M437 657L438 658L437 657M459 657L460 658L459 657z"/>
<path fill={colours[0]} d="M487 657L488 658L487 657M92 658L93 659L92 658z"/>
<path fill={colours[2]} d="M117 658L118 659L117 658M148 658L149 659L148 658M186 658L187 659L186 658z"/>
<path fill={colours[0]} d="M237 658L238 659L237 658z"/>
<path fill={colours[2]} d="M373 658L374 659L373 658M115 659L116 660L115 659z"/>
<path fill={colours[0]} d="M123 659L124 660L123 659M148 659L149 660L148 659M187 659L188 660L187 659M236 659L237 660L236 659z"/>
<path fill={colours[2]} d="M324 659L325 660L324 659M372 659L373 660L372 659M410 659L411 660L410 659z"/>
<path fill={colours[0]} d="M438 659L439 660L438 659z"/>
<path fill={colours[2]} d="M458 659L459 660L458 659z"/>
<path fill={colours[0]} d="M486 659L487 660L486 659M113 660L114 661L113 660z"/>
<path fill={colours[2]} d="M123 660L124 661L123 660M250 660L251 661L250 660z"/>
<path fill={colours[0]} d="M276 660L277 661L276 660M325 660L326 661L325 660z"/>
<path fill={colours[2]} d="M371 660L372 661L371 660z"/>
<path fill={colours[0]} d="M111 661L112 662L111 661z"/>
<path fill={colours[2]} d="M149 661L150 662L149 661M188 661L189 662L188 661M370 661L371 662L370 661z"/>
<path fill={colours[0]} d="M439 661L440 662L439 661M457 661L458 662L457 661M485 661L486 662L485 661M96 662L97 663L96 662M109 662L110 663L109 662M149 662L150 663L149 662M189 662L190 663L189 662z"/>
<path fill={colours[2]} d="M326 662L327 663L326 662M369 662L370 663L369 662M410 662L411 663L410 662M440 662L441 663L440 662z"/>
<path fill={colours[0]} d="M98 663L99 664L98 663M106 663L107 664L106 663z"/>
<path fill={colours[2]} d="M107 663L108 664L107 663z"/>
<path fill={colours[0]} d="M124 663L125 664L124 663z"/>
<path fill={colours[3]} d="M189 663L190 664L189 663M233 663L234 664L233 663z"/>
<path fill={colours[2]} d="M251 663L252 664L251 663z"/>
<path fill={colours[0]} d="M277 663L278 664L277 663M327 663L328 664L327 663z"/>
<path fill={colours[2]} d="M368 663L369 664L368 663z"/>
<path fill={colours[0]} d="M456 663L457 664L456 663M484 663L485 664L484 663z"/>
<path fill={colours[2]} d="M101 664L101 665L104 665L101 664M150 664L151 665L150 664z"/>
<path fill={colours[0]} d="M190 664L191 665L190 664z"/>
<path fill={colours[3]} d="M232 664L233 665L232 664z"/>
<path fill={colours[2]} d="M367 664L368 665L367 664z"/>
<path fill={colours[0]} d="M441 664L442 665L441 664M150 665L151 666L150 665z"/>
<path fill={colours[3]} d="M231 665L232 666L231 665z"/>
<path fill={colours[0]} d="M252 665L253 666L252 665z"/>
<path fill={colours[2]} d="M278 665L279 666L278 665M366 665L367 666L366 665M409 665L410 666L409 665M442 665L443 666L442 665z"/>
<path fill={colours[0]} d="M455 665L456 666L455 665M483 665L484 666L483 665M125 666L126 667L125 666z"/>
<path fill={colours[2]} d="M191 666L192 667L191 666M230 666L231 667L230 666M252 666L253 667L252 666z"/>
<path fill={colours[0]} d="M278 666L279 667L278 666z"/>
<path fill={colours[2]} d="M329 666L330 667L329 666M365 666L366 667L365 666M454 666L455 667L454 666M125 667L126 668L125 667z"/>
<path fill={colours[3]} d="M151 667L152 668L151 667z"/>
<path fill={colours[0]} d="M192 667L193 668L192 667z"/>
<path fill={colours[2]} d="M229 667L230 668L229 667z"/>
<path fill={colours[0]} d="M330 667L331 668L330 667z"/>
<path fill={colours[2]} d="M364 667L365 668L364 667M443 667L444 668L443 667z"/>
<path fill={colours[0]} d="M482 667L483 668L482 667M151 668L152 669L151 668z"/>
<path fill={colours[2]} d="M228 668L229 669L228 668z"/>
<path fill={colours[0]} d="M253 668L254 669L253 668z"/>
<path fill={colours[2]} d="M279 668L280 669L279 668M363 668L364 669L363 668z"/>
<path fill={colours[0]} d="M408 668L409 669L408 668M453 668L454 669L453 668M126 669L127 670L126 669M193 669L194 670L193 669z"/>
<path fill={colours[2]} d="M227 669L228 670L227 669M253 669L254 670L253 669M331 669L332 670L331 669M362 669L363 670L362 669z"/>
<path fill={colours[0]} d="M444 669L445 670L444 669z"/>
<path fill={colours[2]} d="M452 669L453 670L452 669z"/>
<path fill={colours[0]} d="M481 669L482 670L481 669z"/>
<path fill={colours[2]} d="M126 670L127 671L126 670z"/>
<path fill={colours[3]} d="M152 670L153 671L152 670z"/>
<path fill={colours[2]} d="M226 670L227 671L226 670M280 670L281 671L280 670z"/>
<path fill={colours[0]} d="M332 670L333 671L332 670z"/>
<path fill={colours[2]} d="M407 670L408 671L407 670M445 670L446 671L445 670z"/>
<path fill={colours[0]} d="M152 671L153 672L152 671M194 671L195 672L194 671z"/>
<path fill={colours[2]} d="M225 671L226 672L225 671z"/>
<path fill={colours[0]} d="M254 671L255 672L254 671M280 671L281 672L280 671M407 671L408 672L407 671M451 671L452 672L451 671z"/>
<path fill={colours[2]} d="M480 671L481 672L480 671M224 672L225 673L224 672z"/>
<path fill={colours[3]} d="M254 672L255 673L254 672z"/>
<path fill={colours[0]} d="M446 672L447 673L446 672z"/>
<path fill={colours[2]} d="M127 673L128 674L127 673M153 673L154 674L153 673z"/>
<path fill={colours[0]} d="M195 673L196 674L195 673z"/>
<path fill={colours[2]} d="M223 673L224 674L223 673z"/>
<path fill={colours[0]} d="M281 673L282 674L281 673z"/>
<path fill={colours[2]} d="M334 673L335 674L334 673M406 673L407 674L406 673z"/>
<path fill={colours[0]} d="M450 673L451 674L450 673z"/>
<path fill={colours[2]} d="M479 673L480 674L479 673z"/>
<path fill={colours[0]} d="M153 674L154 675L153 674z"/>
<path fill={colours[2]} d="M222 674L223 675L222 674M255 674L256 675L255 674z"/>
<path fill={colours[0]} d="M335 674L336 675L335 674M447 674L448 675L447 674z"/>
<path fill={colours[2]} d="M449 674L450 675L449 674z"/>
<path fill={colours[0]} d="M478 674L479 675L478 674z"/>
<path fill={colours[2]} d="M196 675L197 676L196 675M221 675L222 676L221 675M282 675L283 676L282 675M448 675L449 676L448 675z"/>
<path fill={colours[0]} d="M128 676L129 677L128 676z"/>
<path fill={colours[2]} d="M154 676L155 677L154 676M220 676L221 677L220 676z"/>
<path fill={colours[0]} d="M256 676L257 677L256 676M354 676L355 677L354 676M405 676L406 677L405 676M477 676L478 677L477 676z"/>
<path fill={colours[2]} d="M197 677L198 678L197 677M219 677L220 678L219 677M256 677L257 678L256 677M283 677L284 678L283 677z"/>
<path fill={colours[0]} d="M353 677L354 678L353 677z"/>
<path fill={colours[2]} d="M218 678L219 679L218 678z"/>
<path fill={colours[0]} d="M283 678L284 679L283 678M339 678L340 679L339 678z"/>
<path fill={colours[2]} d="M352 678L353 679L352 678M404 678L405 679L404 678M476 678L477 679L476 678M129 679L130 680L129 679z"/>
<path fill={colours[0]} d="M155 679L156 680L155 679z"/>
<path fill={colours[2]} d="M217 679L218 680L217 679M257 679L258 680L257 679z"/>
<path fill={colours[0]} d="M341 679L342 680L341 679M349 679L350 680L349 679z"/>
<path fill={colours[2]} d="M350 679L351 680L350 679z"/>
<path fill={colours[0]} d="M404 679L405 680L404 679M475 679L476 680L475 679z"/>
<path fill={colours[3]} d="M199 680L200 681L199 680M216 680L217 681L216 680z"/>
<path fill={colours[0]} d="M284 680L285 681L284 680z"/>
<path fill={colours[2]} d="M344 680L344 681L347 681L344 680M156 681L157 682L156 681z"/>
<path fill={colours[0]} d="M201 681L202 682L201 681M214 681L215 682L214 681M258 681L259 682L258 681M403 681L404 682L403 681M474 681L475 682L474 681z"/>
<path fill={colours[2]} d="M130 682L131 683L130 682z"/>
<path fill={colours[0]} d="M156 682L157 683L156 682z"/>
<path fill={colours[2]} d="M202 682L203 683L202 682z"/>
<path fill={colours[0]} d="M212 682L213 683L212 682z"/>
<path fill={colours[2]} d="M213 682L214 683L213 682M258 682L259 683L258 682M285 682L286 683L285 682z"/>
<path fill={colours[0]} d="M205 683L205 684L209 684L205 683z"/>
<path fill={colours[2]} d="M209.667 683.333L210.333 683.667L209.667 683.333z"/>
<path fill={colours[0]} d="M285 683L286 684L285 683z"/>
<path fill={colours[2]} d="M402 683L403 684L402 683M473 683L474 684L473 683z"/>
<path fill={colours[0]} d="M131 684L132 685L131 684M157 684L158 685L157 684M259 684L260 685L259 684z"/>
<path fill={colours[2]} d="M286 684L287 685L286 684z"/>
<path fill={colours[0]} d="M402 684L403 685L402 684M472 684L473 685L472 684z"/>
<path fill={colours[2]} d="M131 685L132 686L131 685z"/>
<path fill={colours[0]} d="M286 685L287 686L286 685z"/>
<path fill={colours[2]} d="M158 686L159 687L158 686z"/>
<path fill={colours[0]} d="M260 686L261 687L260 686z"/>
<path fill={colours[2]} d="M287 686L288 687L287 686z"/>
<path fill={colours[0]} d="M401 686L402 687L401 686z"/>
<path fill={colours[2]} d="M471 686L472 687L471 686z"/>
<path fill={colours[0]} d="M132 687L133 688L132 687M158 687L159 688L158 687z"/>
<path fill={colours[3]} d="M260 687L261 688L260 687z"/>
<path fill={colours[0]} d="M287 687L288 688L287 687M470 687L471 688L470 687M261 688L262 689L261 688z"/>
<path fill={colours[2]} d="M400 688L401 689L400 688z"/>
<path fill={colours[0]} d="M426.667 688.333L427.333 688.667L426.667 688.333M159 689L160 690L159 689z"/>
<path fill={colours[2]} d="M261 689L262 690L261 689z"/>
<path fill={colours[0]} d="M288 689L289 690L288 689z"/>
<path fill={colours[2]} d="M469 689L470 690L469 689M133 690L134 691L133 690M399 690L400 691L399 690z"/>
<path fill={colours[0]} d="M428 690L429 691L428 690M468 690L469 691L468 690z"/>
<path fill={colours[2]} d="M160 691L161 692L160 691M262 691L263 692L262 691z"/>
<path fill={colours[0]} d="M289 691L290 692L289 691M399 691L400 692L399 691z"/>
<path fill={colours[2]} d="M425 691L426 692L425 691z"/>
<path fill={colours[0]} d="M134 692L135 693L134 692z"/>
<path fill={colours[2]} d="M429 692L430 693L429 692M467 692L468 693L467 692M161 693L162 694L161 693z"/>
<path fill={colours[0]} d="M263 693L264 694L263 693M290 693L291 694L290 693M398 693L399 694L398 693M424 693L425 694L424 693M430 693L431 694L430 693M466 693L467 694L466 693M161 694L162 695L161 694z"/>
<path fill={colours[2]} d="M135 695L136 696L135 695M162 695L163 696L162 695z"/>
<path fill={colours[0]} d="M264 695L265 696L264 695M291 695L292 696L291 695M397 695L398 696L397 695M423 695L424 696L423 695M431 695L432 696L431 695M162 696L163 697L162 696z"/>
<path fill={colours[2]} d="M264 696L265 697L264 696M423 696L424 697L423 696M464 696L465 697L464 696z"/>
<path fill={colours[0]} d="M136 697L137 698L136 697z"/>
<path fill={colours[3]} d="M163 697L164 698L163 697z"/>
<path fill={colours[0]} d="M264 697L265 698L264 697M292 697L293 698L292 697M396 697L397 698L396 697z"/>
<path fill={colours[2]} d="M432 697L433 698L432 697z"/>
<path fill={colours[0]} d="M463 697L464 698L463 697M163 698L164 699L163 698z"/>
<path fill={colours[2]} d="M263 698L264 699L263 698z"/>
<path fill={colours[0]} d="M422 698L423 699L422 698M433 698L434 699L433 698M137 699L138 700L137 699M263 699L264 700L263 699M293 699L294 700L293 699z"/>
<path fill={colours[2]} d="M395 699L396 700L395 699M137 700L138 701L137 700z"/>
<path fill={colours[0]} d="M164 700L165 701L164 700M421 700L422 701L421 700z"/>
<path fill={colours[2]} d="M434 700L435 701L434 700z"/>
<path fill={colours[0]} d="M262 701L263 702L262 701M294 701L295 702L294 701z"/>
<path fill={colours[2]} d="M394 701L395 702L394 701z"/>
<path fill={colours[0]} d="M435 701L436 702L435 701z"/>
<path fill={colours[2]} d="M460 701L461 702L460 701M138 702L139 703L138 702z"/>
<path fill={colours[0]} d="M165 702L166 703L165 702z"/>
<path fill={colours[2]} d="M295 702L296 703L295 702z"/>
<path fill={colours[0]} d="M420 702L421 703L420 702M459 702L460 703L459 702M261 703L262 704L261 703M295 703L296 704L295 703z"/>
<path fill={colours[2]} d="M393 703L394 704L393 703M420 703L421 704L420 703M436 703L437 704L436 703z"/>
<path fill={colours[0]} d="M458 703L459 704L458 703M139 704L140 705L139 704M166 704L167 705L166 704z"/>
<path fill={colours[2]} d="M296 704L297 705L296 704z"/>
<path fill={colours[0]} d="M393 704L394 705L393 704M437 704L438 705L437 704M260 705L261 706L260 705z"/>
<path fill={colours[2]} d="M392 705L393 706L392 705M419 705L420 706L419 705z"/>
<path fill={colours[0]} d="M438 705L439 706L438 705M140 706L141 707L140 706M167 706L168 707L167 706z"/>
<path fill={colours[2]} d="M297 706L298 707L297 706z"/>
<path fill={colours[0]} d="M392 706L393 707L392 706M439 706L440 707L439 706M455 706L456 707L455 706M259 707L260 708L259 707z"/>
<path fill={colours[2]} d="M391 707L392 708L391 707z"/>
<path fill={colours[0]} d="M418 707L419 708L418 707z"/>
<path fill={colours[2]} d="M440 707L441 708L440 707z"/>
<path fill={colours[0]} d="M454 707L455 708L454 707M141 708L142 709L141 708M168 708L169 709L168 708z"/>
<path fill={colours[2]} d="M258 708L259 709L258 708z"/>
<path fill={colours[0]} d="M298 708L299 709L298 708M391 708L392 709L391 708z"/>
<path fill={colours[2]} d="M442 708L443 709L442 708z"/>
<path fill={colours[0]} d="M452 708L453 709L452 708z"/>
<path fill={colours[2]} d="M141 709L142 710L141 709z"/>
<path fill={colours[0]} d="M258 709L259 710L258 709z"/>
<path fill={colours[2]} d="M390 709L391 710L390 709z"/>
<path fill={colours[0]} d="M417 709L418 710L417 709z"/>
<path fill={colours[2]} d="M445 709L446 710L445 709z"/>
<path fill={colours[0]} d="M446.667 709.333L447.333 709.667L446.667 709.333z"/>
<path fill={colours[2]} d="M448.667 709.333L449.333 709.667L448.667 709.333z"/>
<path fill={colours[0]} d="M142 710L143 711L142 710M169 710L170 711L169 710z"/>
<path fill={colours[2]} d="M257 710L258 711L257 710z"/>
<path fill={colours[0]} d="M299 710L300 711L299 710M390 710L391 711L390 710z"/>
<path fill={colours[2]} d="M142 711L143 712L142 711M170 711L171 712L170 711M389 711L390 712L389 711z"/>
<path fill={colours[0]} d="M416 711L417 712L416 711M143 712L144 713L143 712M170 712L171 713L170 712M256 712L257 713L256 712M300 712L301 713L300 712z"/>
<path fill={colours[2]} d="M143 713L144 714L143 713M171 713L172 714L171 713M301 713L302 714L301 713M388 713L389 714L388 713z"/>
<path fill={colours[0]} d="M415 713L416 714L415 713M144 714L145 715L144 714M255 714L256 715L255 714z"/>
<path fill={colours[2]} d="M144 715L145 716L144 715M172 715L173 716L172 715M254 715L255 716L254 715z"/>
<path fill={colours[0]} d="M302 715L303 716L302 715z"/>
<path fill={colours[2]} d="M387 715L388 716L387 715z"/>
<path fill={colours[0]} d="M414 715L415 716L414 715M145 716L146 717L145 716z"/>
<path fill={colours[2]} d="M145 717L146 718L145 717z"/>
<path fill={colours[0]} d="M173 717L174 718L173 717M253 717L254 718L253 717M386 717L387 718L386 717M413 717L414 718L413 717M146 718L147 719L146 718z"/>
<path fill={colours[3]} d="M252 718L253 719L252 718z"/>
<path fill={colours[2]} d="M304 718L305 719L304 718M146 719L147 720L146 719z"/>
<path fill={colours[0]} d="M174 719L175 720L174 719M385 719L386 720L385 719M412 719L413 720L412 719M147 720L148 721L147 720z"/>
<path fill={colours[2]} d="M175 720L176 721L175 720z"/>
<path fill={colours[0]} d="M251 720L252 721L251 720M305 720L306 721L305 720z"/>
<path fill={colours[3]} d="M250 721L251 722L250 721z"/>
<path fill={colours[2]} d="M306 721L307 722L306 721z"/>
<path fill={colours[0]} d="M384 721L385 722L384 721M411 721L412 722L411 721M148 722L149 723L148 722M176 722L177 723L176 722z"/>
<path fill={colours[2]} d="M383 722L384 723L383 722z"/>
<path fill={colours[0]} d="M249 723L250 724L249 723z"/>
<path fill={colours[2]} d="M307 723L308 724L307 723z"/>
<path fill={colours[0]} d="M410 723L411 724L410 723M149 724L150 725L149 724M177 724L178 725L177 724z"/>
<path fill={colours[3]} d="M248 724L249 725L248 724z"/>
<path fill={colours[5]} d="M278 724L261.385 748L252.817 759L260.05 774L285 813L302.8 783L309.328 770L300.71 756L280 724L278 724z"/>
<path fill={colours[2]} d="M382 724L383 725L382 724M178 725L179 726L178 725z"/>
<path fill={colours[0]} d="M308 725L309 726L308 725M409 725L410 726L409 725M150 726L151 727L150 726M247 726L248 727L247 726z"/>
<path fill={colours[2]} d="M309 726L310 727L309 726z"/>
<path fill={colours[0]} d="M381 726L382 727L381 726M179 727L180 728L179 727z"/>
<path fill={colours[2]} d="M246 727L247 728L246 727M380 727L381 728L380 727z"/>
<path fill={colours[0]} d="M408 727L409 728L408 727z"/>
<path fill={colours[2]} d="M151 728L152 729L151 728M180 728L181 729L180 728z"/>
<path fill={colours[3]} d="M245 728L246 729L245 728z"/>
<path fill={colours[0]} d="M310 728L311 729L310 728M152 729L153 730L152 729z"/>
<path fill={colours[2]} d="M311 729L312 730L311 729M379 729L380 730L379 729M407 729L408 730L407 729z"/>
<path fill={colours[0]} d="M181 730L182 731L181 730M244 730L245 731L244 730M153 731L154 732L153 731z"/>
<path fill={colours[3]} d="M182 731L183 732L182 731z"/>
<path fill={colours[2]} d="M243 731L244 732L243 731z"/>
<path fill={colours[0]} d="M312 731L313 732L312 731M378 731L379 732L378 731z"/>
<path fill={colours[2]} d="M406 731L407 732L406 731z"/>
<path fill={colours[3]} d="M242 732L243 733L242 732z"/>
<path fill={colours[2]} d="M313 732L314 733L313 732M377 732L378 733L377 732z"/>
<path fill={colours[0]} d="M405 732L406 733L405 732z"/>
<path fill={colours[2]} d="M154 733L155 734L154 733z"/>
<path fill={colours[0]} d="M183 733L184 734L183 733M155 734L156 735L155 734z"/>
<path fill={colours[3]} d="M184 734L185 735L184 734z"/>
<path fill={colours[0]} d="M376 734L377 735L376 734M404 734L405 735L404 734z"/>
<path fill={colours[2]} d="M155 735L156 736L155 735z"/>
<path fill={colours[0]} d="M240 735L241 736L240 735M315 735L316 736L315 735M156 736L157 737L156 736M185 736L186 737L185 736M239 736L240 737L239 736z"/>
<path fill={colours[2]} d="M403 736L404 737L403 736z"/>
<path fill={colours[3]} d="M186 737L187 738L186 737z"/>
<path fill={colours[0]} d="M238 737L239 738L238 737M374 737L375 738L374 737z"/>
<path fill={colours[2]} d="M157 738L158 739L157 738M237 738L238 739L237 738z"/>
<path fill={colours[0]} d="M317 738L318 739L317 738z"/>
<path fill={colours[2]} d="M402 738L403 739L402 738z"/>
<path fill={colours[0]} d="M158 739L159 740L158 739M187 739L188 740L187 739z"/>
<path fill={colours[2]} d="M236 739L237 740L236 739M318 739L319 740L318 739z"/>
<path fill={colours[0]} d="M401 739L402 740L401 739z"/>
<path fill={colours[2]} d="M188 740L189 741L188 740z"/>
<path fill={colours[3]} d="M235 740L236 741L235 740z"/>
<path fill={colours[0]} d="M372 740L373 741L372 740M159 741L160 742L159 741z"/>
<path fill={colours[3]} d="M234 741L235 742L234 741z"/>
<path fill={colours[2]} d="M371 741L372 742L371 741z"/>
<path fill={colours[0]} d="M400 741L401 742L400 741M189 742L190 743L189 742M320 742L321 743L320 742z"/>
<path fill={colours[2]} d="M190 743L191 744L190 743M321 743L322 744L321 743z"/>
<path fill={colours[0]} d="M370 743L371 744L370 743z"/>
<path fill={colours[2]} d="M399 743L400 744L399 743z"/>
<path fill={colours[0]} d="M161 744L162 745L161 744z"/>
<path fill={colours[3]} d="M191 744L192 745L191 744z"/>
<path fill={colours[2]} d="M369 744L370 745L369 744z"/>
<path fill={colours[0]} d="M398 744L399 745L398 744M322 745L323 746L322 745M192 746L193 747L192 746z"/>
<path fill={colours[3]} d="M229 746L230 747L229 746z"/>
<path fill={colours[0]} d="M323 746L324 747L323 746z"/>
<path fill={colours[2]} d="M397 746L398 747L397 746z"/>
<path fill={colours[0]} d="M163 747L164 748L163 747M193 747L194 748L193 747z"/>
<path fill={colours[3]} d="M228 747L229 748L228 747z"/>
<path fill={colours[2]} d="M324 747L325 748L324 747z"/>
<path fill={colours[0]} d="M367 747L368 748L367 747M396 747L397 748L396 747z"/>
<path fill={colours[2]} d="M194 748L195 749L194 748z"/>
<path fill={colours[3]} d="M227 748L228 749L227 748z"/>
<path fill={colours[2]} d="M366 748L367 749L366 748z"/>
<path fill={colours[3]} d="M226 749L227 750L226 749z"/>
<path fill={colours[2]} d="M395 749L396 750L395 749z"/>
<path fill={colours[0]} d="M165 750L166 751L165 750z"/>
<path fill={colours[3]} d="M225 750L226 751L225 750z"/>
<path fill={colours[0]} d="M326 750L327 751L326 750M394 750L395 751L394 750M166 751L167 752L166 751M196 751L197 752L196 751z"/>
<path fill={colours[2]} d="M224 751L225 752L224 751M327 751L328 752L327 751z"/>
<path fill={colours[0]} d="M364 751L365 752L364 751z"/>
<path fill={colours[2]} d="M197 752L198 753L197 752M223 752L224 753L223 752M328 752L329 753L328 752M363 752L364 753L363 752M393 752L394 753L393 752M167 753L168 754L167 753z"/>
<path fill={colours[3]} d="M198 753L199 754L198 753z"/>
<path fill={colours[0]} d="M222 753L223 754L222 753z"/>
<path fill={colours[2]} d="M362 753L363 754L362 753z"/>
<path fill={colours[0]} d="M392 753L393 754L392 753M168 754L169 755L168 754M221 754L222 755L221 754M330 755L331 756L330 755z"/>
<path fill={colours[2]} d="M391 755L392 756L391 755M169 756L170 757L169 756z"/>
<path fill={colours[0]} d="M200 756L201 757L200 756z"/>
<path fill={colours[3]} d="M218 756L219 757L218 756z"/>
<path fill={colours[0]} d="M331 756L332 757L331 756M360 756L361 757L360 756M390 756L391 757L390 756M170 757L171 758L170 757z"/>
<path fill={colours[2]} d="M201 757L202 758L201 757M217 757L218 758L217 757M332 757L333 758L332 757z"/>
<path fill={colours[0]} d="M359 757L360 758L359 757M171 758L172 759L171 758z"/>
<path fill={colours[2]} d="M202 758L203 759L202 758z"/>
<path fill={colours[0]} d="M216 758L217 759L216 758z"/>
<path fill={colours[2]} d="M358 758L359 759L358 758z"/>
<path fill={colours[3]} d="M203 759L204 760L203 759z"/>
<path fill={colours[2]} d="M357 759L358 760L357 759z"/>
<path fill={colours[0]} d="M388 759L389 760L388 759z"/>
<path fill={colours[2]} d="M172 760L173 761L172 760z"/>
<path fill={colours[3]} d="M204 760L205 761L204 760z"/>
<path fill={colours[2]} d="M213 760L214 761L213 760z"/>
<path fill={colours[0]} d="M387 760L388 761L387 760M173 761L174 762L173 761M212 761L213 762L212 761M335 761L336 762L335 761M174 762L175 763L174 762z"/>
<path fill={colours[3]} d="M210 762L211 763L210 762z"/>
<path fill={colours[0]} d="M336 762L337 763L336 762z"/>
<path fill={colours[2]} d="M386 762L387 763L386 762z"/>
<path fill={colours[0]} d="M206 763L207 764L206 763z"/>
<path fill={colours[2]} d="M209 763L210 764L209 763M337 763L338 764L337 763M385 763L386 764L385 763z"/>
<path fill={colours[0]} d="M207.667 764.333L208.333 764.667L207.667 764.333z"/>
<path fill={colours[2]} d="M338 764L339 765L338 764z"/>
<path fill={colours[0]} d="M384 764L385 765L384 764z"/>
<path fill={colours[2]} d="M176 765L177 766L176 765M339 765L340 766L339 765z"/>
<path fill={colours[0]} d="M177 766L178 767L177 766M178 767L179 768L178 767z"/>
<path fill={colours[2]} d="M382 767L383 768L382 767z"/>
<path fill={colours[0]} d="M381 768L382 769L381 768M380 769L381 770L380 769z"/>
<path fill={colours[2]} d="M180 770L181 771L180 770z"/>
<path fill={colours[0]} d="M181 771L182 772L181 771M344 771L345 772L344 771z"/>
<path fill={colours[2]} d="M345 771L346 772L345 771z"/>
<path fill={colours[0]} d="M182 772L183 773L182 772z"/>
<path fill={colours[2]} d="M377 773L378 774L377 773M376 774L377 775L376 774z"/>
<path fill={colours[0]} d="M375 775L376 776L375 775z"/>
<path fill={colours[2]} d="M185 776L186 777L185 776z"/>
<path fill={colours[0]} d="M232 776L233 777L232 776z"/>
<path fill={colours[2]} d="M233 776L234 777L233 776z"/>
<path fill={colours[0]} d="M374 776L375 777L374 776z"/>
<path fill={colours[2]} d="M186 777L187 778L186 777M231 777L232 778L231 777z"/>
<path fill={colours[0]} d="M234 777L235 778L234 777z"/>
<path fill={colours[2]} d="M187 778L188 779L187 778z"/>
<path fill={colours[0]} d="M188 779L189 780L188 779z"/>
<path fill={colours[2]} d="M235 779L236 780L235 779z"/>
<path fill={colours[0]} d="M189 780L190 781L189 780M227 780L228 781L227 780M190 781L191 782L190 781z"/>
<path fill={colours[2]} d="M226 781L227 782L226 781M236 781L237 782L236 781z"/>
<path fill={colours[0]} d="M224 782L225 783L224 782M237 782L238 783L237 782M223 783L224 784L223 783z"/>
<path fill={colours[2]} d="M222 784L223 785L222 784M238 784L239 785L238 784z"/>
<path fill={colours[0]} d="M220 785L221 786L220 785M239 785L240 786L239 785z"/>
<path fill={colours[2]} d="M219 786L220 787L219 786z"/>
<path fill={colours[0]} d="M217 787L218 788L217 787M240 787L241 788L240 787M363 787L364 788L363 787z"/>
<path fill={colours[2]} d="M216 788L217 789L216 788z"/>
<path fill={colours[0]} d="M326 788L327 789L326 788M362 788L363 789L362 788M214 789L215 790L214 789z"/>
<path fill={colours[2]} d="M241 789L242 790L241 789z"/>
<path fill={colours[0]} d="M328 789L329 790L328 789M361 789L362 790L361 789M199 790L200 791L199 790M212 790L213 791L212 790M242 790L243 791L242 790M325 790L326 791L325 790M329 790L330 791L329 790z"/>
<path fill={colours[2]} d="M360 790L361 791L360 790M200 791L201 792L200 791z"/>
<path fill={colours[0]} d="M201 791L202 792L201 791M210 791L211 792L210 791z"/>
<path fill={colours[2]} d="M211 791L212 792L211 791z"/>
<path fill={colours[0]} d="M330 791L331 792L330 791z"/>
<path fill={colours[2]} d="M359 791L360 792L359 791M203 792L204 793L203 792z"/>
<path fill={colours[0]} d="M204 792L204 793L208 793L204 792z"/>
<path fill={colours[2]} d="M208 792L209 793L208 792M243 792L244 793L243 792M324 792L325 793L324 792z"/>
<path fill={colours[0]} d="M331 792L332 793L331 792M244 793L245 794L244 793M323 793L324 794L323 793M332 793L333 794L332 793M356 793L357 794L356 793z"/>
<path fill={colours[2]} d="M333 794L334 795L333 794z"/>
<path fill={colours[0]} d="M355 794L356 795L355 794M245 795L246 796L245 795M322 795L323 796L322 795z"/>
<path fill={colours[2]} d="M334 795L335 796L334 795M354 795L355 796L354 795M335 796L336 797L335 796M246 797L247 798L246 797M321 797L322 798L321 797z"/>
<path fill={colours[0]} d="M336 797L337 798L336 797M351 797L352 798L351 797M247 798L248 799L247 798z"/>
<path fill={colours[2]} d="M337 798L338 799L337 798M350 798L351 799L350 798M320 799L321 800L320 799M339 799L340 800L339 799z"/>
<path fill={colours[0]} d="M340 799L341 800L340 799M347 799L348 800L347 799z"/>
<path fill={colours[2]} d="M348 799L349 800L348 799M248 800L249 801L248 800z"/>
<path fill={colours[0]} d="M319 800L320 801L319 800z"/>
<path fill={colours[2]} d="M343.667 800.333L344.333 800.667L343.667 800.333z"/>
<path fill={colours[0]} d="M318 802L319 803L318 802M250 803L251 804L250 803z"/>
<path fill={colours[2]} d="M317 804L318 805L317 804M251 805L252 806L251 805z"/>
<path fill={colours[0]} d="M316 805L317 806L316 805M252 806L253 807L252 806M315 807L316 808L315 807M253 808L254 809L253 808z"/>
<path fill={colours[2]} d="M314 809L315 810L314 809M254 810L255 811L254 810z"/>
<path fill={colours[0]} d="M313 810L314 811L313 810M255 811L256 812L255 811z"/>
<path fill={colours[2]} d="M313 811L314 812L313 811z"/>
<path fill={colours[0]} d="M312 812L313 813L312 812z"/>
<path fill={colours[2]} d="M256 813L257 814L256 813z"/>
<path fill={colours[0]} d="M257 814L258 815L257 814M311 814L312 815L311 814M258 816L259 817L258 816z"/>
<path fill={colours[2]} d="M310 816L311 817L310 816z"/>
<path fill={colours[0]} d="M309 817L310 818L309 817z"/>
<path fill={colours[2]} d="M259 818L260 819L259 818z"/>
<path fill={colours[0]} d="M260 819L261 820L260 819M308 819L309 820L308 819z"/>
<path fill={colours[2]} d="M261 821L262 822L261 821M307 821L308 822L307 821z"/>
<path fill={colours[0]} d="M306 822L307 823L306 822M263 824L264 825L263 824M305 824L306 825L305 824z"/>
<path fill={colours[2]} d="M264 826L265 827L264 826M304 826L305 827L304 826z"/>
<path fill={colours[0]} d="M265 827L266 828L265 827z"/>
<path fill={colours[2]} d="M303 828L304 829L303 828z"/>
<path fill={colours[0]} d="M266 829L267 830L266 829M302 829L303 830L302 829z"/>
<path fill={colours[2]} d="M267 831L268 832L267 831z"/>
<path fill={colours[0]} d="M301 831L302 832L301 831M268 832L269 833L268 832z"/>
<path fill={colours[2]} d="M300 833L301 834L300 833M269 834L270 835L269 834z"/>
<path fill={colours[0]} d="M299 834L300 835L299 834M270 835L271 836L270 835M298 836L299 837L298 836M271 837L272 838L271 837z"/>
<path fill={colours[2]} d="M297 838L298 839L297 838M272 839L273 840L272 839z"/>
<path fill={colours[0]} d="M273 840L274 841L273 840z"/>
<path fill={colours[2]} d="M296 840L297 841L296 840z"/>
<path fill={colours[0]} d="M295 841L296 842L295 841z"/>
<path fill={colours[2]} d="M274 842L275 843L274 842z"/>
<path fill={colours[0]} d="M275 843L276 844L275 843z"/>
<path fill={colours[2]} d="M294 843L295 844L294 843z"/>
<path fill={colours[0]} d="M276 844L277 845L276 844z"/>
<path fill={colours[2]} d="M293 844L294 845L293 844z"/>
<path fill={colours[0]} d="M277 845L278 846L277 845z"/>
<path fill={colours[2]} d="M292 845L293 846L292 845M278 846L279 847L278 846z"/>
<path fill={colours[0]} d="M279 846L280 847L279 846M290 846L291 847L290 846M281.667 847.333L282.333 847.667L281.667 847.333M287 847L288 848L287 847z"/>
<path fill={colours[2]} d="M288 847L289 848L288 847z"/>
</svg>

);

export default HopIBU;