import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import './styles.scss';
import Designer from './../../components/Designer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIngredientsStart } from './../../redux/Ingredients/ingredients.actions';

const mapState = ({ ingredientsData }) => ({
  ingredients: ingredientsData.ingredients
});

const Create = props => {
  const { ingredients } = useSelector(mapState);
  const dispatch = useDispatch();
  const { brewType } = useParams();

  const { data, queryDoc, isLastPage } = ingredients;

  
  useEffect(() => {
    dispatch(
      fetchIngredientsStart()
    );
  }, []);
  
  return (
    <section className="createpage">
      <Designer {...props} brewType={brewType ? brewType : props?.brewType ? props.brewType : 'beer'} data={data}/>
    </section>
  );
  
}

export const CreateHome = props => {
  const history = useHistory();
  return (
    <section className="createpage">
      <div style={{
          minHeight: `calc(100vh - 6.5rem - 5.8rem)`,
          display: 'flex'
        }}>
          <div className='btncontainer'>
            <Card className="createbtn">
              <CardActionArea
                onClick={()=> history.push('/create/beer')}
              >
              <CardMedia
                  component="img"
                  height="150"
                  image="/static/images/grainblue.jpg"
                  alt="beer grain"
                  style={{ objectFit: "contain", padding: "2rem 2rem 0 2rem" }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Brew Beer
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card className="createbtn">
              <CardActionArea
                onClick={()=> history.push('/create/coffee')}
              >
                <CardMedia
                  component="img"
                  height="150"
                  image="/static/images/beanblue.jpg"
                  alt="coffee bean"
                  style={{ objectFit: "contain", padding: "2rem 2rem 0 2rem" }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Brew Coffee
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
      </div>
    </section>
  );
}

export const ReviewBrew = props => {
  const history = useHistory();
  return (
    <section className="createpage">
      <Designer {...props} brewType={"review"}/>
    </section>
  );
}

export default Create;
