import { colors } from '@material-ui/core';
import { unstable_createMuiStrictModeTheme, createMuiTheme } from '@material-ui/core/styles';

export const brewMatesColors = {
  primary: '#21A0C9',
  secondary: '#A0C921',
}

const OurMuiTheme = unstable_createMuiStrictModeTheme({
  breakpoints:{
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1024,
      xl: 1280
    }
  },
    palette: {
        primary: {
          main: '#21A0C9'
        },
        secondary: {
          main: '#A0C921'
        }
    },
    typography: {
      fontFamily: [
        "Montserrat",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      body1: {
        fontSize: "1.25rem",
      },
      h1: {
        fontSize: "1.75rem",
        fontWeight: "bold",
        color: '#21A0C9'
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: "bold"
      },
      h3: {
        fontSize: "1.35rem",
        fontWeight: "bold",
      },
    },
    overrides: {
      // Style sheet name
      MuiTypography: {
          root: {
            // Name of the rule
            gutterBottom: {
                marginBottom: "1.5rem",
            },
        }
      },
      MuiButton: {
        root: {
            fontSize: "1.4rem",
            margin: "1rem 0!important",
            textTransform: 'none',
        }
      },
      MuiGrid: {
        container: {
          '&$spacing-xs-3': {
            margin: 0
          }
        }
      }
    },
  });
  
  export default OurMuiTheme;