import React from 'react';
import './styles.scss';
import Checkout from './../../components/Checkout';

const Cart = ({}) => {
  return (
    <section className='cartpage'>
      <Checkout />
    </section>
  );
}

export default Cart;
