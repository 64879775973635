import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductStart, setProduct } from './../../redux/Products/products.actions';
import { addProduct } from './../../redux/Cart/cart.actions';
import './styles.scss';

import './styles.scss';

import ImageUploader from '../ImageUploader/ImageUploader';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Mash from '../../assets/mash.jpg';
import beanfull from '../../assets/beanfull.jpg';
import beanempty from '../../assets/beanempty.jpg';
import kettle from '../../assets/kettle.jpg';
import coldpour from '../../assets/coldpour.jpg';
import toddy from '../../assets/toddy.jpg';
import Fermenter from '../../assets/fermenter.jpg';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import BeerIPA from '../../assets/svg/GlassEBCIPA.jsx';
import HopIBU from '../../assets/svg/HopIBU.jsx';
import { registerLoadBalancerType } from '@grpc/grpc-js/build/src/load-balancer';
import { isBuffer } from 'util';

const mapState = state => ({
    product: state.productsData.product
  });

  const baseState = {
    productThumbnail: undefined,
    productName: "My Craft Brew",
    productPrice: 0.00,
    productDescription: "Describe your beer...",
    productCategory: "beer",
    beerRecipe: {
        batchsize: 0,
        packaging: "no label",
        abv: 0,
        ibu: 0,
        ebc: 0,
        fermentables: [
            {
                diastatic: 0,
                documentID: "",
                ebc: 0,
                ingredient_type: "",
                measure_unit: "kg",
                name: '',
                stock_lvl: 0,
                store: "beer",
                type: "",
                qty: 0,
                colour: ""
            }
        ],
        boil: [
            {
                diastatic: 0,
                documentID: "",
                ebc: 0,
                ingredient_type: "",
                measure_unit: "kg",
                name: '',
                stock_lvl: 0,
                store: "beer",
                type: "",
                qty: 0,
                mins: 0,
                boiltype: "",
                alpha: 0,
                description: ""
            }
        ],
        ferment: {
            yeast: "",
            additions: [{
                diastatic: 0,
                documentID: "",
                ebc: 0,
                ingredient_type: "",
                measure_unit: "kg",
                name: '',
                stock_lvl: 0,
                store: "beer",
                type: "",
                qty: 0,
                mins: 0,
                boiltype: ""
            }]
        }
    }
}


export const Beer = props => {
    const dispatch = useDispatch()
    const { productID } = useParams();
    const { product } = useSelector(mapState);
    const [recipe, setRecipe] = useState(productID ? product.beerRecipe : baseState.beerRecipe);
    const [productName, setProductName] = useState(productID ? product.productName : baseState.productName);
    const [productDescription, setProductDescription] = useState(productID ? product.productDescription : baseState.productDescription);
    const [productPrice, setProductPrice] = useState(productID ? product.productPrice : baseState.productPrice);
    const [productImage, setProductImage] = useState(productID ? product.productThumbnail : baseState.productThumbnail);
    const [strength, setStrength] = useState(0);
    const [roast, setRoast] = useState(0);
    const [abv, setAbv] = useState(0); 
    const [ebc, setEbc] = useState(0);
    const [ibu, setIbu] = useState(0);

    const deleteItem = (index, stage) => {
        setRecipe(prevState => {
            switch (stage){
                case "fermentables":
                    var fermentables = [...prevState.fermentables]
                    fermentables.splice(index,1)
                    return {
                        ...prevState,
                        fermentables
                    };
                case "boil":
                    var boil = [...prevState.boil]
                    boil.splice(index,1)
                    return {
                        ...prevState,
                        boil
                    };
                case "ferment":
                    var ferment = prevState.ferment
                    var boil = [...prevState.ferment.boil]
                    boil.splice(index,1)
                    ferment.boil = boil
                    return {
                        ...prevState,
                        ferment
                    };
            }
        })
    }

    useEffect(() => {

        if (productID){
            dispatch(
                fetchProductStart(productID)
            )

            return () => {
                dispatch(
                setProduct({})
                )
            }
        }
        updateBrewCalcs();
        
    }, [recipe]);

    const handleSubmit = e => {
        e.preventDefault();
        const packedProd = {
            productThumbnail: productImage,
            productName: productName,
            productDescription: productDescription,
            productPrice: productPrice,
            beerRecipe: recipe,
            productCategory: "beer"
        };
        console.log(packedProd)
    }
    const updateBrewCalcs = () => {
        
        var OG = 0;
        var EBC = 0;
        var colours = {
            gold: false,
            black: false,
            red: false
            };
        var FG = 1.007;
        var IBU = 0;
        var colourR = [150,255],         colourG = [90,245],         colourB = [0,140] ,         maxEBC = 5;
        var r, g , b;
        
        if (recipe.fermentables.length >0) {
            for (let i = 0; i < recipe.fermentables.length; i++) {
                //Calculates Original Gravity
                OG = OG + (recipe.fermentables[i].diastatic/10 * recipe.fermentables[i].qty/10);
                //Calculates EBC

                EBC = EBC + Math.round((recipe.fermentables[i].ebc * (recipe.fermentables[i].qty*10))/10.26086957)
                if (recipe.fermentables[i].colour === "gold") {
                    colours.gold = true
                }
                if (recipe.fermentables[i].colour === "black") {
                    colours.black = true
                }
                if (recipe.fermentables[i].colour === "red") {
                    colours.red = true
                }
              }
        }
        OG = (OG*1+1);
        setEbc("#FFFFFF")
        
        if (colours.gold && !colours.black && !colours.red) {
            if (EBC < 6) {
                setEbc("#FFF897")
            }
            if (EBC >= 6 && EBC < 10) {
                setEbc("#FAE96F")
            }
            if (EBC >= 10 && EBC < 20) {
                setEbc("#F6C101")
            }
            if (EBC >= 20 && EBC < 30) {
                setEbc("#EC9D00")
            }
            if (EBC >= 30 && EBC < 50) {
                setEbc("#C96E12")
            }
            if (EBC >= 50) {
                setEbc("#BB5100")
            }
        }

        if ((colours.gold && colours.black) || (!colours.gold && colours.black  && !colours.red)) {
            if (EBC < 6) {
                setEbc("#F7B76E")
            }
            if (EBC >= 6 && EBC < 10) {
                setEbc("#EC9D00")
            }
            if (EBC >= 10 && EBC < 20) {
                setEbc("#BB5100")
            }
            if (EBC >= 20 && EBC < 30) {
                setEbc("#603606")
            }
            if (EBC >= 30 && EBC < 70) {
                setEbc("#301B03")
            }
            if (EBC >= 70) {
                setEbc("#100100")
            }
        }

        // function setColour(colour, calc, qty) {
        //     console.log(colour + " " + calc + " " + qty )
            
        //     if (colour === "black" && qty < 0.3) {
        //         setEbc("#361e03")
        //     }
        //     if (colour === "black" && calc > 30 && qty > 0.29) {
        //         setEbc("0f0800")
        //     }
        // }

        //var ABV = (OG - FG) * 131.25
        var ABV = (76.08 * (OG-FG) / (1.775-OG)) * (FG / 0.794);
        //recipe.abv = recipe.abv < 0 ? 0 : (76.08 * (OG-FG) / (1.775-OG)) * (FG / 0.794);
        recipe.abv = Math.trunc(((76.08 * (OG-FG) / (1.775-OG)) * (FG / 0.794))*100)/100;
        recipe.ebc = EBC;
        setAbv(Math.trunc(((76.08 * (OG-FG) / (1.775-OG)) * (FG / 0.794))*100)/100)
        // if (EBC < maxEBC && EBC > 0) {
        //     console.log(colourR[0]+Math.trunc((EBC/maxEBC)*(colourR[1]-colourR[0])))
        //     console.log((Math.trunc(colourR[0]+(EBC/maxEBC)*(colourR[1]-colourR[0]))).toString(16) + (Math.trunc(colourG[0]+(EBC/maxEBC)*(colourG[1]-colourG[0]))).toString(16) + (Math.trunc(colourB[0]+(EBC/maxEBC)*(colourB[1]-colourB[0]))).toString(16))
        //     r = (Math.trunc(colourR[0]+(EBC/maxEBC)*(colourR[1]-colourR[0]))).toString(16);
        //     g = (Math.trunc(colourG[0]+(EBC/maxEBC)*(colourG[1]-colourG[0]))).toString(16);
        //     b = (Math.trunc(colourB[0]+(EBC/maxEBC)*(colourB[1]-colourB[0]))).toString(16);
        //     setEbc("#"+r+g+b)
        // } else {
        //     setEbc("#FFFFFF")
        // }


        
        // Final equation for IBU calc
        //IBU = (-0.0002575*alpha)*(boiltime*boiltime) + ((0.315*alpha)*boiltime)+ (0.115*alpha)
        // Working calcs
        //IBU = -0.0036125*(boiltime*boiltime) + (0.441189*boil) + 1.609    ***14 Alpha
        //IBU = -0.0018*(boiltime*boiltime) + (0.219971*boil) + 0.816      ***7 Alpha
        //IBU = -0.00258393*(boiltime*boiltime) + (0.315275*boil) + 1.151      ***10 Alpha
        if (recipe.boil.length >0) {
            var hopIBU = 0
            var utilisation = 0
            for (let i = 0; i < recipe.boil.length; i++) {
                utilisation = ((-0.01*(recipe.boil[i].mins * recipe.boil[i].mins)) + (.75*recipe.boil[i].mins) - 1.5);
                hopIBU = hopIBU + ((utilisation) * (recipe.boil[i].alpha*100)) * (recipe.boil[i].qty /100)
                // console.log("util: " + utilisation)
                
                
                // setIbu(['#465735', '#A5C22F', '#A5C22F', "#A5C22F",  "#A5C22F", "#FFFFFF"]);
                //Calculates Original Gravity
                //OG = OG + (recipe.fermentables[i].diastatic/10 * recipe.fermentables[i].qty/10);
                //Calculates EBC
                //EBC = EBC + Math.round((recipe.fermentables[i].ebc * recipe.fermentables[i].qty)/10.26086957)
              }
              IBU =  hopIBU;
              if (IBU < 1 || IBU === "undefined") {
                setIbu(['#465735', '#FFFFFF', '#FFFFFF', "#FFFFFF",  "#FFFFFF", "#FFFFFF"]);
              }
              if (IBU >= 1 && IBU < 5) {
                setIbu(['#465735', '#FFFFFF', '#FFFFFF', "#FFFFFF",  "#FFFFFF", "#A5C22F"]);
            }
            if (IBU >= 5 && IBU < 10) {
                setIbu(['#465735', '#FFFFFF', '#FFFFFF', "#FFFFFF",  "#A5C22F", "#A5C22F"]);
            }
            if (IBU >= 10 && IBU < 15) {
                setIbu(['#465735', '#FFFFFF', '#FFFFFF', "#A5C22F",  "#A5C22F", "#A5C22F"]);
            }
            if (IBU >= 15 && IBU < 30) {
                setIbu(['#465735', '#FFFFFF', '#A5C22F', "#A5C22F",  "#A5C22F", "#A5C22F"]);
            }
            if (IBU >= 30 ) {
                setIbu(['#465735', '#A5C22F', '#A5C22F', "#A5C22F",  "#A5C22F", "#A5C22F"]);
            }
            //   switch (IBU) {
            //     case < 1:
            //         setIbu(['#465735', '#FFFFFF', '#FFFFFF', "#FFFFFF",  "#FFFFFF", "#FFFFFF"]);
            //     break;
            //     case  < 10:
            //         setIbu(['#465735', '#FFFFFF', '#FFFFFF', "#FFFFFF",  "#FFFFFF", "#A5C22F"]);
            //     break;
            //     case  < 20:
            //         setIbu(['#465735', '#FFFFFF', '#FFFFFF', "#FFFFFF",  "#A5C22F", "#A5C22F"]);
            //     break;
            //     case  < 30:
            //         setIbu(['#465735', '#FFFFFF', '#FFFFFF', "#A5C22F",  "#A5C22F", "#A5C22F"]);
            //     break;
            //     case  < 40:
            //         setIbu(['#465735', '#FFFFFF', '#A5C22F', "#A5C22F",  "#A5C22F", "#A5C22F"]);
            //     break;
            //     case  >= 40:
            //         setIbu(['#465735', '#A5C22F', '#A5C22F', "#A5C22F",  "#A5C22F", "#A5C22F"]);
            //     break;
            // }
            
            
        }
        console.log("OG: " + OG);
        console.log("EBC: " + EBC);
        console.log("ABV: " + ABV);
        console.log("IBU: " + IBU);


    }
    const updateFermentableChoice = ({event, index}) => {
        
        setRecipe(prevState => {

            const newFermentable = props.data.filter(x => {return x.documentID == event.target.value})
            const fermentables = [...prevState.fermentables];


            fermentables[index] = {
                ...fermentables[index],
                diastatic: newFermentable[0].diastatic,
                documentID: event.target.value,
                ebc: newFermentable[0].ebc,
                ingredient_type: newFermentable[0].ingredient_type,
                name: newFermentable[0].name,
                stock_lvl: newFermentable[0].stock_lvl,
                colour: newFermentable[0].colour            }
            
            // updateBeanVals(grind)
            return {
                ...prevState,
                fermentables
            };
        })
        
    }
    const updateFermentableQty = ({event, index}) => {
        const newValue = event.target?.value ? event.target.value : recipe.fermentables[index].qty
        setRecipe(prevState => {
            const fermentables = [...prevState.fermentables];
            fermentables[index] = {
                ...fermentables[index],
                qty: Number(newValue)
            };
            return {
                ...prevState,
                fermentables,
                
            };
    
        })
        
    }
    const updateBoilChoice = ({event, index, option}) => {
        console.log("option: " + option);
        console.log("target value: " + event.target.value);
        setRecipe(prevState => {
            var newBoil = [];
            var boil = [];
            if (option === "addition") {
                newBoil = props.data.filter(x => {return x.documentID == event.target.value})
            
            }
            console.log("New Boil");
            console.log(newBoil);
            if ([...prevState.boil] === 'undefined') {
                boil = [];
            } else {
                boil = [...prevState.boil];
            }
            
            try{
                newBoil[0].qty = boil[index].qty;
                newBoil[0].mins = boil[index].mins;
                newBoil[0].boiltype = boil[index].boiltype;
            } catch (e) {
                console.log(e);
            }
            
            boil[index] = newBoil[0];
            // boil[index] = {
            //     ...boil[index],
            //     description: newBoil[0].description,
            //     documentID: event.target.value,
            //     type: newBoil[0].type,
            //     ingredient_type: newBoil[0].ingredient_type,
            //     name: newBoil[0].name,
            //     stock_lvl: newBoil[0].stock_lvl,
            //     colour: newBoil[0].colour,
            //     alpha: newBoil[0].alpha
            // }

            if (option === "type") {
                boil[index].boiltype = event.target.value
            } 
            if (option === "addition") {
                // boil[index] = newBoil[0];
                boil[index] = {
                    ...boil[index],
                    documentID: event.target.value,
                    ingredient_type: newBoil[0].ingredient_type,
                    name: newBoil[0].name
                }
            }
            if (option === "mins") {
                if (event.target.value === "") {
                    event.target.value = 0
                }
                boil[index].mins = event.target.value
            } 
            
            // console.log(boil)
            
            
            // updateBeanVals(grind)
            return {
                ...prevState,
                boil
            };
        })
    }
    
    const updateFermentChoice = ({event, index, option}) => {
        
        setRecipe(prevState => {
            
            const newFerment = props.data.filter(x => {return x.documentID == event.target.value})
            var ferment = prevState.ferment;
            const additions = [...prevState.ferment.additions]
            if (option === "type") {
                ferment.additions[index].boiltype = event.target.value
            } 
            if (option === "addition") {
                ferment.additions[index] = {
                    ...additions[index],
                    documentID: event.target.value,
                    ingredient_type: newFerment[0].ingredient_type,
                    name: newFerment[0].name
                }
            }
            if (option === "yeast") {
                ferment.yeast = newFerment[0].documentID
            }
            if (option === "mins") {
                if (event.target.value === "") {
                    event.target.value = 0
                }
                ferment.additions[index].mins = event.target.value
            
            } 
            
            console.log(ferment)
            
            
            // updateBeanVals(grind)
            return {
                ...prevState,
                ferment
            };
        })
    }

    


    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        style={{
                        width: "100%"
                    }}
                        id='brewname'
                        label='Brew Name'
                        variant="outlined"
                        placeholder={productName}
                        onChange={e => {
                            setProductName(e.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl
                        variant="outlined"
                        className={props.classes.formControl}
                        style={{
                        width: "100%"
                    }}>
                        <InputLabel id='label_for_batch_size'>Batch Size</InputLabel>
                        <Select
                            labelId='label_for_batch_size'
                            id="batchsize"
                            value={recipe.batchsize}
                            onChange={e => {
                            setRecipe(prevState => ({
                                ...prevState,
                                batchsize: e.target.value
                                
                            }))
                        }}
                            label="Batch Size">
                            <MenuItem disabled key={0} value={0}>Please select a batch size</MenuItem>
                            <MenuItem key={1} value={20}>20L (60 cans)</MenuItem>
                            {/* <MenuItem key={2} value={40}>40L (120 cans)</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl
                        variant="outlined"
                        className={props.classes.formControl}
                        style={{
                        width: "100%"
                    }}>
                        <InputLabel id='label_for_packaging'>Packaging Style</InputLabel>
                        <Select
                            labelId='label_for_packaging'
                            id="packaging"
                            value={recipe.packaging}
                            onChange={e => {
                            setRecipe(prevState => ({
                                ...prevState,
                                packaging: e.target.value
                            }))
                        }}
                            label="Packaging Style">
                            <MenuItem key={1} value={"personalised"}>Canned with personalised label</MenuItem>
                            <MenuItem key={2} value={"no label"}>Canned - no label</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={4}>
                <TextField
                            style={{
                            width: "100%",
                            height: "100%",
                            wordWrap: 'break-word',
                            wordBreak: 'break-all'
                           
                        }}
                            id='recipedescription'
                            label='Recipe Description'
                            variant="outlined"
                            multiline
                            rows={3}
                            // minRows={3}
                            // maxRows={3}
                            placeholder={productDescription}
                            onChange={e => {
                                setProductDescription(e.target.value)
                            }}
                        />
                    {/* <Paper className={props.classes.paperFullHeight}>
                        
                    </Paper> */}
                </Grid>
                <Grid container item xs={12} sm={4}>
                    <Grid item xs={4}>
                        <div style={{ margin: "0 0 1rem 0",  textAlign: "center",  borderRadius: "inherit",   display: "inline-flex" }}>
                            <Typography variant="h2" component="h2" >{abv < 0 ? 0 : abv}% ABV</Typography>
                            </div>
                    </Grid>
                    <Grid item xs={4}>
                       
                        <div style={{ margin: "0 0 1rem 0",  textAlign: "center",  borderRadius: "inherit",   display: "inline-flex" }}>
                        <HopIBU colours={ibu} height="90px" width="60px" />
                        </div>
                            
                        
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ margin: "0 0 1rem 0",  textAlign: "right",  borderRadius: "inherit",   display: "inline-flex" }}>
                        {/* <BeerIPA beerEBC="#FBB049" height="90px" width="80px"/> */}
                            <BeerIPA beerEBC={ebc} height="90px" width="80px"/>
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{
                    position: "relative"
                }}> 
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={props.classes.submitBtn}>
                        Review & Brew
                    </Button>
                    
                    
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Paper className={props.classes.paperFullHeight}>
                        <div className={props.classes.imageheader}><img src={Mash} alt="Mash Tun"/></div>
                        <Typography variant="h3" component="h3" gutterBottom>Mash</Typography>
                        {recipe
                            .fermentables
                            .map((fermentables, i) => (
                                <Box
                                    key={`Fragment Grind ${i}`}
                                    style={{
                                    margin: "1rem 0",
                                    padding: "0"
                                }}>
                                    {/* <InputLabel id='label_for_bean_qty'>Coffee Selection {i + 1}</InputLabel> */}
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: "0"}}>
                                        <Grid
                                            item xs={4} sm={6} md={6} lg={4} xl={3} 
                                            style={{
                                                // margin: "0.5rem 0.5rem 0.5rem 0",
                                                marginTop: "-1rem",
                                                marginBottom: "0.5rem",
                                                padding: "0"
                                            }}
                                        >
                                            <ButtonGroup
                                                disableFocusRipple
                                                disableRipple
                                                key={`btngroup${i}`}
                                                className={props.classes.buttonGroup}
                                                aria-label="small outlined button group">
                                                
                                                <Button
                                                    style={{
                                                        cursor: "text",
                                                        height: "5.2rem"
                                                    }}
                                                    tabIndex={-1}>
                                                    <TextField
                                                        InputProps={{endAdornment: <InputAdornment position="end">kg</InputAdornment>}}
                                                        value={fermentables.qty}
                                                        style={{
                                                            width: "inherit",
                                                            border: "none"
                                                        }}
                                                    onChange={e => {updateFermentableQty({event: e, index: i});  updateBrewCalcs();

                                                        
                                                            // const caret = e.target.selectionStart
                                                            // const element = e.target
    
                                                            // window.requestAnimationFrame(() => {
                                                            //         element.selectionStart = caret
                                                            //         element.selectionEnd = caret
                                                            //     })
                                                           
                                                         
                                                                                                          
                                                   
                                                    }}/>
                                                </Button>
                                                
                                            </ButtonGroup>
                                            </Grid>
                                           
                                            <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            style={{
                                                // margin: "0.5rem 0.5rem 0.5rem 0",
                                                marginBottom: "0.5rem",
                                                padding: "0"
                                            }}
                                        >
                                                <FormControl
                                            key={`FormControl${i}`}
                                            variant="outlined"
                                            className={props.classes.formControl}
                                            style={{
                                                margin: "0 0rem 0rem 0"
                                            }}>
                                            

                                            
                                            <InputLabel id='label_for_bean_choice' >Fermentables</InputLabel>
                                            <Select
                                                labelId='label_for_bean_choice'
                                                name='fermentable'
                                                key={`Select${i}`}
                                                value={fermentables.documentID}
                                                onChange={e => {
                                                    updateFermentableChoice({event: e, index: i});
                                                }}
                                                label="Fermentables">
                                                {props.data
                                                    ? (props.data.map((item, index) => {
                                                        if (item.store === 'beer' && item.ingredient_type === 'fermentable') 
                                                            return (
                                                                <MenuItem key={index} value={item.documentID} >{item.name} - (ebc: {item.ebc})</MenuItem>
                                                            )
                                                    }))
                                                    : <MenuItem>No items found</MenuItem>}
                                            </Select>
                                            
                                        </FormControl>
                                        
                                                {/* <Grid item xs={6} sm={2} md={2} lg={2} xl={2}> */}
                                                    <IconButton style={{ margin: "0rem" }} aria-label="delete" onClick={e => {
                                                            deleteItem(i, "fermentables")
                                                        }}>
                                                        <DeleteIcon fontSize="large"/>
                                                    </IconButton>
                                                {/* </Grid> */}
                                            </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            margin: "0 0.5rem 0.5rem 0",
                                            padding: "0"
                                        }}
                                    >
                                        <Typography
                                            id={`coffeebatchdesc ${i}`}
                                            className={props.classes.carddescription}
                                            variant="body1"
                                            component="p">
                                            {fermentables.description}
                                        </Typography>
                                    </Grid>
                                    <Divider
                                        style={{
                                        margin: "1rem 0 2rem 0"
                                    }}/>
                                </Box>
                            ))}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={e => {
                                    setRecipe(prevState => {
                                        const fermentables = [...prevState.fermentables];
                                        fermentables.push({
                                            diastatic: 0,
                                            documentID: "",
                                            ebc: 0,
                                            ingredient_type: "",
                                            measure_unit: "kg",
                                            name: '',
                                            stock_lvl: 0,
                                            store: "beer",
                                            type: "",
                                            qty: 0
                                        });
                                        return {
                                            ...prevState,
                                            fermentables
                                        };
                                    })
                                }}>
                                +
                            </Button>
                        </Grid>
                        <Typography
                            id='beer_offering'
                            className={props.classes.carddescription}
                            variant="body1"
                            component="p">
                            {/* Some text will go here */}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper className={props.classes.paperFullHeight}>
                        <div className={props.classes.imageheader}><img src={kettle} alt="Kettle"/></div>
                        <Typography variant="h3" component="h3" gutterBottom>Boil</Typography>
                        
                        {recipe
                            .boil
                            .map((boil, i) => (
                                <Box
                                    key={`Boil ${i}`}
                                    style={{
                                    margin: "1rem 0",
                                    padding: "0"
                                }}>
                                    {/* <InputLabel id='label_for_bean_qty'>Coffee Selection {i + 1}</InputLabel> */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container
                                        style={{ padding: "0" }}>
                                        <Grid item xs={6} sm={6} md={6} lg={4} xl={3}
                                            style={{
                                                // margin: "0.5rem 0.5rem 0.5rem 0",
                                                marginTop: "-1rem",
                                                marginBottom: "0.5rem",
                                                padding: "0"
                                            }}
                                        >
                                            <ButtonGroup
                                                disableFocusRipple
                                                disableRipple
                                                key={`btngroup${i}`}
                                                className={props.classes.buttonGroup}
                                                aria-label="small outlined button group">
                                                
                                                <Button
                                                    style={{
                                                        cursor: "text", height: "5.2rem"
                                                    }}
                                                    tabIndex={-1}>
                                                    <TextField
                                                        InputProps={{endAdornment: <InputAdornment position="end">g</InputAdornment>}}
                                                        value={boil.qty}
                                                        style={{
                                                            width: "inherit",
                                                            border: "none"
                                                        }}
                                                    onChange={e => {
                                                        const caret = e.target.selectionStart
                                                        const element = e.target

                                                        window.requestAnimationFrame(() => {
                                                                element.selectionStart = caret
                                                                element.selectionEnd = caret
                                                            })

                                                        const newValue = e.target?.value ? e.target.value : recipe.boil[i].qty
                                                            setRecipe(prevState => {
                                                                const boil = [...prevState.boil];
                                                                boil[i] = {
                                                                    ...boil[i],
                                                                    qty: newValue
                                                                };
                                                                console.log(boil)
                                                        return {
                                                            ...prevState,
                                                            boil
                                                        };
                                                        
                                                    })
                                        }}/></Button>
                                                
                                            </ButtonGroup>
                                            </Grid>
                                           
                                            <Grid
                                            item xs={12} sm={8} md={6} lg={8} xl={9}
                                            style={{
                                                // margin: "0.5rem 0.5rem 0.5rem 0",
                                                marginBottom: "0rem",
                                                padding: "0",

                                            }}
                                        >
                                                <FormControl
                                            key={`BoilControl${i}`}
                                            variant="outlined"
                                            className={props.classes.formControl}
                                            style={{
                                                margin: "0 1.5rem 0rem 0",
                                                width: "100%"
                                            }}>
                                            

                                            
                                            <InputLabel id='label_for_boil_choice' >Addition</InputLabel>
                                            <Select
                                                labelId='label_for_boil_choice'
                                                name='coldside'
                                                key={`Select${i}`}
                                                value={boil.documentID}
                                                onChange={e => {
                                                    updateBoilChoice({event: e, index: i, option: "addition"});
                                                }}
                                                style={{
                                                    margin: "0 1.5rem 1rem 0rem ",
                                                    width: "100%"
                                                    // marginBottom: "1rem",
                                                    // padding: "1rem 1rem 1rem 1rem",
                                                }}
                                                label="Boil Additions">
                                                {props.data
                                                        ? (props.data.map((item, index) => {
                                                            if (item.store === 'beer' && item.ingredient_type === 'coldside') 
                                                                return (
                                                                    <MenuItem key={index} value={item.documentID}>{item.name} ({item.type})</MenuItem>
                                                                )
                                                        }))
                                                        : <MenuItem>No items found</MenuItem>}
                                            </Select>
                                            
                                        </FormControl>
                                        
                                                
                                            </Grid>
                                            
                                            
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    {/* Choose when added on hot side */}
                                                    <FormControl
                                                        variant="outlined"
                                                        className={props.classes.formControl}
                                                        style={{
                                                        width: "100%"
                                                    }}>
                                                        <InputLabel id='label_for_boil_type'>Type</InputLabel>
                                                        <Select
                                                            labelId='label_for_boil_type'
                                                            name='boiltype'
                                                            key={`boiltype${i}`}
                                                            value={boil.boiltype}
                                                            onChange={e => {
                                                                updateBoilChoice({event: e, index: i, option: "type"});
                                                            }}
                                                            label="Boil Type"
                                                            style={{
                                                                marginBottom: "1rem",
                                                                marginRight: "1rem",
                                                                width: "100%"
                                                                // marginBottom: "1rem",
                                                                // padding: "1rem 1rem 1rem 1rem",
                                                            }}>
                                                            <MenuItem key={1} value={"boil"}>Boil</MenuItem>
                                                            <MenuItem key={2} value={"flamout"}>Flamout</MenuItem>
                                                            <MenuItem key={3} value={"hop stand"}>Hop Stand</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={8} sm={6} md={4} lg={4} xl={4} 
                                                    style={{
                                                        // margin: "0.5rem 0.5rem 0.5rem 0",
                                                        marginTop: "-1rem",
                                                        marginBottom: "0.5rem",
                                                        // marginLeft: "1.5rem",
                                                        padding: "0"
                                                    }}>
                                                    {/* Choose number of minutes */}
                                                    <ButtonGroup
                                                    disableFocusRipple
                                                    disableRipple
                                                    key={`btngroup${i}`}
                                                    className={props.classes.buttonGroup}
                                                    aria-label="small outlined button group">
                                                    
                                                    <Button
                                                        style={{
                                                            cursor: "text",
                                                            height: "5.2rem",
                                                            // marginLeft: "1rem",
                                                        }}
                                                        tabIndex={-1}>
                                                        <TextField
                                                            InputProps={{endAdornment: <InputAdornment position="end">mins</InputAdornment>}}
                                                            value={boil.time}
                                                            style={{
                                                                width: "inherit",
                                                                border: "none"
                                                            }}
                                                            onChange={e => {
                                                                const caret = e.target.selectionStart
                                                                const element = e.target
        
                                                                window.requestAnimationFrame(() => {
                                                                        element.selectionStart = caret
                                                                        element.selectionEnd = caret
                                                                    })
        
                                                                const newValue = e.target?.value ? e.target.value : recipe.boil[i].mins
                                                                    setRecipe(prevState => {
                                                                        const boil = [...prevState.boil];
                                                                        boil[i] = {
                                                                            ...boil[i],
                                                                            mins: newValue
                                                                        };
                                                                        console.log(boil)
                                                                return {
                                                                    ...prevState,
                                                                    boil
                                                                };
                                                            })}}   
                                                                />
                                                        </Button>
                                                
                                            </ButtonGroup>
                                                </Grid>
                                                <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
                                                    <IconButton style={{ margin: "0rem" }} aria-label="delete" onClick={e => {
                                                            deleteItem(i, "boil")
                                                        }}>
                                                        <DeleteIcon fontSize="large"/>
                                                    </IconButton>
                                                </Grid>
                                                
                                            

                                            
                                            
                                        </Grid>
                                   
                                    
                                    <Grid
                                        item
                                        style={{
                                            margin: "0 0.5rem 0.5rem 0",
                                            padding: "0"
                                        }}
                                    >
                                        {/* <Typography
                                            id={`coffeebatchdesc ${i}`}
                                            className={props.classes.carddescription}
                                            variant="body1"
                                            component="p">
                                            {boil.description}
                                        </Typography> */}
                                    </Grid>
                                    <Divider
                                        style={{
                                        margin: "1rem 0 2rem 0"
                                    }}/>
                                </Box>
                            ))}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={e => {
                                    setRecipe(prevState => {
                                        const boil = [...prevState.boil];
                                        boil.push({
                                            diastatic: 0,
                                            documentID: "",
                                            ebc: 0,
                                            ingredient_type: "",
                                            measure_unit: "kg",
                                            name: '',
                                            stock_lvl: 0,
                                            store: "beer",
                                            type: "",
                                            qty: 0,
                                            mins: 0,
                                            boiltype: ""
                                        });
                                        return {
                                            ...prevState,
                                            boil
                                        };
                                    })
                                }}>
                                +
                            </Button>
                        </Grid>
                        <Typography
                            id='brewdescription'
                            className={props.classes.carddescription}
                            variant="body1"
                            component="p">
                            {recipe.brew === "Drip Tower" && (
                                "Cold drip tower produces the cleanist & smoothest flavour of the 3 brewing options. The coffee is slowly filtered through a ceramic tile and paper filter before aerating and finally dripping into the glass beaker."
                            )}
                            {recipe.brew === "BiB" && (
                                "The Brew in Bag (BiB) method produces a strong flavoured almost syrupy brew. This method involves steeping the coffee grinds in cold water a refigerating for a minimum 12 hour period. At the end of steeping period the coffee is poured through a paper filter."
                            )}
                            {recipe.brew === "Hot Brew" && (
                                "Pour over produces the strongest flavour of the 3 options. Brewed with hot water to unlock the strong bold aromatic flavours of the coffee; then chilled fast to lock in the flavours"
                            )}
                        </Typography>
                    </Paper>
                </Grid>



{/* This section is the third column for the fermentation options */}

            

                <Grid item xs={12} sm={4}>
                <Paper className={props.classes.paperFullHeight}>
                        <div className={props.classes.imageheader}><img src={Fermenter} alt="Fermentation"/></div>
                        <Typography variant="h3" component="h3" gutterBottom>Fermenter</Typography>
                        <InputLabel id='label_for_yeast_choice' >Yeast</InputLabel>
                <Select
                    labelId='label_yeast_choice'
                    name='coldside'
                    key='yeast'
                    value={recipe.ferment.yeast}
                    onChange={e => {
                        updateFermentChoice({event: e, index: 0, option: "yeast"});
                    }}
                    style={{
                        margin: "0 1.5rem 1rem 0rem ",
                        width: "100%"
                        // marginBottom: "1rem",
                        // padding: "1rem 1rem 1rem 1rem",
                    }}
                    label="Yeast">
                    {props.data
                            ? (props.data.map((item, index) => {
                                if (item.store === 'beer' && item.ingredient_type === 'yeast') 
                                    return (
                                        <MenuItem key={index} value={item.documentID}>{item.name} ({item.type})</MenuItem>
                                    )
                            }))
                            : <MenuItem>No items found</MenuItem>}
                </Select>
                        
                        {recipe
                            .ferment
                            .additions
                            .map((additions, i) => (
                                <Box
                                    key={`Additions ${i}`}
                                    style={{
                                    margin: "1rem 0",
                                    padding: "0"
                                }}>
                                    {/* <InputLabel id='label_for_bean_qty'>Coffee Selection {i + 1}</InputLabel> */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container
                                        style={{ padding: "0" }}>
                                        <Grid item xs={6} sm={6} md={6} lg={4} xl={3}
                                            style={{
                                                // margin: "0.5rem 0.5rem 0.5rem 0",
                                                marginTop: "-1rem",
                                                marginBottom: "0.5rem",
                                                padding: "0"
                                            }}
                                        >
                                            <ButtonGroup
                                                disableFocusRipple
                                                disableRipple
                                                key={`btngroup${i}`}
                                                className={props.classes.buttonGroup}
                                                aria-label="small outlined button group">
                                                
                                                <Button
                                                    style={{
                                                        cursor: "text", height: "5.2rem"
                                                    }}
                                                    tabIndex={-1}>
                                                    <TextField
                                                        InputProps={{endAdornment: <InputAdornment position="end">g</InputAdornment>}}
                                                        value={additions.qty}
                                                        style={{
                                                            width: "inherit",
                                                            border: "none"
                                                        }}
                                                    onChange={e => {
                                                        const caret = e.target.selectionStart
                                                        const element = e.target

                                                        window.requestAnimationFrame(() => {
                                                                element.selectionStart = caret
                                                                element.selectionEnd = caret
                                                            })
                                                        
                                                        if (e.target.value.length > 0) {
                                                            // console.log(e.target.value.length)
                                                        
                                                            const newValue = e.target?.value ? e.target.value : recipe.additions[i].qty
                                                                setRecipe(prevState => {
                                                                    var ferment = [prevState.ferment]
                                                                    const additions = [...prevState.ferment.additions];
                                                                    additions[i] = {
                                                                        ...additions[i],
                                                                        qty: newValue
                                                                    };
                                                                    ferment = {
                                                                        ...ferment,
                                                                        additions
                                                                    }
                                                                    
                                                            return {
                                                                ...prevState,
                                                                ferment
                                                            };
                                                        
                                                            })
                                                        }
                                        }}/></Button>
                                                
                                            </ButtonGroup>
                                            </Grid>
                                           
                                            <Grid
                                            item xs={12} sm={8} md={6} lg={8} xl={9}
                                            style={{
                                                // margin: "0.5rem 0.5rem 0.5rem 0",
                                                marginBottom: "0rem",
                                                padding: "0",

                                            }}
                                        >
                                                <FormControl
                                            key={`BoilForm${i}`}
                                            variant="outlined"
                                            className={props.classes.formControl}
                                            style={{
                                                margin: "0 1.5rem 0rem 0",
                                                width: "100%"
                                            }}>
                                            

                                            
                                            <InputLabel id='label_for_boil_choice' >Addition</InputLabel>
                                            <Select
                                                labelId='label_for_boil_choice'
                                                name='coldside'
                                                key={`Select${i}`}
                                                value={additions.documentID}
                                                onChange={e => {
                                                    updateFermentChoice({event: e, index: i, option: "addition"});
                                                }}
                                                style={{
                                                    margin: "0 1.5rem 1rem 0rem ",
                                                    width: "100%"
                                                    // marginBottom: "1rem",
                                                    // padding: "1rem 1rem 1rem 1rem",
                                                }}
                                                label="Additions">
                                                {props.data
                                                        ? (props.data.map((item, index) => {
                                                            if (item.store === 'beer' && item.ingredient_type === 'coldside') 
                                                                return (
                                                                    <MenuItem key={index} value={item.documentID}>{item.name} ({item.type})</MenuItem>
                                                                )
                                                        }))
                                                        : <MenuItem>No items found</MenuItem>}
                                            </Select>
                                            
                                        </FormControl>
                                        
                                                
                                            </Grid>
                                            
                                           
                                                <Grid item xs={8} sm={6} md={4} lg={4} xl={4} 
                                                    style={{
                                                        // margin: "0.5rem 0.5rem 0.5rem 0",
                                                        marginTop: "-1rem",
                                                        marginBottom: "0.5rem",
                                                        // marginLeft: "1.5rem",
                                                        padding: "0"
                                                    }}>
                                                    {/* Choose number of minutes */}
                                                    <ButtonGroup
                                                    disableFocusRipple
                                                    disableRipple
                                                    key={`btngroup${i}`}
                                                    className={props.classes.buttonGroup}
                                                    aria-label="small outlined button group">
                                                    
                                                    <Button
                                                        style={{
                                                            cursor: "text",
                                                            height: "5.2rem",
                                                            // marginLeft: "1rem",
                                                        }}
                                                        tabIndex={-1}>
                                                        <TextField
                                                            InputProps={{endAdornment: <InputAdornment position="end">days</InputAdornment>}}
                                                            value={additions.time}
                                                            style={{
                                                                width: "inherit",
                                                                border: "none"
                                                            }}
                                                            onChange={e => {
                                                                updateFermentChoice({event: e, index: i, option: "mins"});
                                                            }}/>
                                                        </Button>
                                                
                                            </ButtonGroup>
                                                </Grid>
                                                <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
                                                    <IconButton style={{ margin: "0rem" }} aria-label="delete" onClick={e => {
                                                            deleteItem(i, "ferment")
                                                        }}>
                                                        <DeleteIcon fontSize="large"/>
                                                    </IconButton>
                                                </Grid>
                                                
                                            

                                            
                                            
                                        </Grid>
                                   
                                    
                                    <Grid
                                        item
                                        style={{
                                            margin: "0 0.5rem 0.5rem 0",
                                            padding: "0"
                                        }}
                                    >
                                        {/* <Typography
                                            id={`coffeebatchdesc ${i}`}
                                            className={props.classes.carddescription}
                                            variant="body1"
                                            component="p">
                                            {boil.description}
                                        </Typography> */}
                                    </Grid>
                                    <Divider
                                        style={{
                                        margin: "1rem 0 2rem 0"
                                    }}/>
                                </Box>
                            ))}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={e => {
                                    setRecipe(prevState => {
                                        const additions = [...prevState.ferment.additions];
                                        var ferment = prevState.ferment
                                        additions.push({
                                            diastatic: 0,
                                            documentID: "",
                                            ebc: 0,
                                            ingredient_type: "",
                                            measure_unit: "kg",
                                            name: '',
                                            stock_lvl: 0,
                                            store: "beer",
                                            type: "",
                                            qty: 0,
                                            mins: 0,
                                            boiltype: ""
                                        });
                                        ferment.additions = additions
                                        return {
                                            ...prevState,
                                            ferment
                                        };
                                    })
                                }}>
                                +
                            </Button>
                        </Grid>
                        <Typography
                            id='brewdescription'
                            className={props.classes.carddescription}
                            variant="body1"
                            component="p">
                            {/* Add some text here */}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3} style={{ marginLeft: "auto", marginRight: "auto" }}>
                <Grid item xs={12}>
                    <ImageUploader parentFunc={setProductImage}/>
                </Grid>
            </Grid>
        </form>
    );
}