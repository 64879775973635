import React from 'react';
import './styles.scss';
import SignIn from './../../components/SignIn';

const Login = props => (
  <section className='loginpage'>
    <SignIn />
  </section>
)

export default Login;