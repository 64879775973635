import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

const ImageUploader = ({ parentFunc, imgFiles }) => {

  const iniFiles = imgFiles ?? []

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles?.[0]

    if (!file) {
      return
    }

    // for storing in history state
    parentFunc([{
      blobUrl: URL.createObjectURL(file),
      name: `${file.name}`
    }])
  }, [])
  
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop
  });

  return (
    <section className="container" style={{ display: "block",
        flexDirection: "column"}}>
      <div {...getRootProps({ className: "dropzone" })} style={{ flex: 1,
            display: "block",
            // flexDirection: "column",
            alignItems: "center",
            // padding: "20px",
            marginLeft: "auto",
            aspectRatio: "2421/1062",
            marginRight: "auto",
            maxWidth: "100%",
            maxHeight: "250px",
            borderWidth: "2px",
            borderRadius: "2px",
            borderColor: "#eeeeee",
            borderStyle: "dashed",
            backgroundColor: "#fafafa",
            color: "inherit",
            outline: "none",
            transition: "border .24s ease-in-out" }}>
        <input {...getInputProps()} />
        {acceptedFiles.length === 0 && iniFiles.length === 0 && 
            <div className="flex align-center justify-center" style={{ cursor: "pointer", textAlign: "center", padding: "20px" }}>
                <p>
                    Drag image here or click to select.
                </p>
                <p>
                    Minimum image dimensions 2421px X 1062px @300DPI
                </p>
                <em>(You can only select/drop 1 file here)</em>
            </div>
         }
        {acceptedFiles.length > 0 &&
            acceptedFiles.map((file, i) => (
                <aside key={`image ${i}`} style={thumbsContainer}>
                    <img src={URL.createObjectURL(file)} style={img} />
                </aside>
            ))
        }
        {acceptedFiles.length === 0 && iniFiles.length > 0 &&
            iniFiles.map((file, i) => (
                <aside key={`image ${i}`} style={thumbsContainer}>
                    <img src={file.blobUrl} style={img} />
                </aside>
            ))
        }
      </div>
    </section>
  );
}

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  maxWidth: "100%",
};

const img = {
    display: "block",
    maxWidth: "100%",
    maxHeight: "250px",
    marginLeft: "auto",
    marginRight: "auto",
};

export default ImageUploader;